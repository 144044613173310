import { Applications } from '../../@types/configuration';
import { Page } from '../../@types/page';

export enum ExecutionStatus {
  OK = 'OK',
  ERROR = 'ERROR',
  FATAL = 'FATAL',
  SKIPPED_SUPPRESSED = 'SKIPPED_SUPPRESSED',
  SOFT_LAUNCH = 'SOFT_LAUNCH',
  WARN = 'WARN',
  PROCESSING = 'PROCESSING',
}

export enum RetryProcess {
  OK = 'OK',
  MESSAGE_NOT_FOUND = 'MESSAGE_NOT_FOUND',
  PROCESSOR_NOT_FOUND = 'PROCESSOR_NOT_FOUND',
  EXECUTION_NOT_FOUND = 'EXECUTION_NOT_FOUND',
  FAILED = 'FAILED',
}

export interface ProcessorLog {
  executionId?: string;
  sharedId?: string;
  messageId?: string;
  processorName?: string;
  status?: ExecutionStatus;
  executionTime?: number;
  startDt?: string;
  endDt?: string;
  source?: string;
  input?: string;
  output?: string;
  hasEmailPreview?: boolean;
  application?: Applications;
  resending?: boolean;
  userId?: string;
  aboNumber?: string;
  request?: ExecutionRequest;
  hasCustomChannelMessage?: boolean;
  customChannelId?: string;
}

export interface ProcessorStepLog {
  executionId: string;
  stepName: string;
  stepNumber: number;
  status: ExecutionStatus;
  executionTime: number;
  startDt: string;
  endDt: string;
  message: string;
  output: string;
  retryAttempts: number;
}

export interface TransactionalEmail {
  executionId: string;
  externalMessageId: string;
  messagePayload: string;
  receivedDt: string;
  sender: string;
  receiver: string;
  htmlSnapshot: string;
  sharedId?: string;
  subject?: string;
  processorName?: string;
}

export interface ExecutionRequest {
  searchText?: string;
  processor?: string[];
  country?: string[];
  status?: ExecutionStatus[];
  startDate?: string;
  endDate?: string;
  reprocessedEvents?: boolean;
  userId?: string;
  page?: Page;
  timeoutSeconds?: number;
  emailPreview?: boolean;
}

export interface ExecutionResponse {
  executions: ProcessorLog[];
  db: string;
  total: number;
  page?: Page;
}

export interface ExecutionDetails {
  execution: ProcessorLog;
  steps: ProcessorStepLog[];
  hasEmailPreview: boolean;
  otherExecution?: ProcessorLog;
}
