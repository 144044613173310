import { mdiDeleteOutline, mdiPencilOutline } from '@mdi/js';
import { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { ExternalData } from '../../../@types/external-api';
import IconButton from '../../../components/ui/icon-btn';
import PaginatedTableComponent, { Column } from '../../../components/ui/paginated-table';
import { AllowListRule } from '../../../resources/allow-list/allow-list-model';

type Props = {
  allowListRules: ExternalData<AllowListRule[]>;
  onEditClick: (rule: AllowListRule) => void;
  onDeleteClick: (rule: AllowListRule) => void;
};

export default function AllowListPaginatedListComponent({ allowListRules, onEditClick, onDeleteClick }: Props) {
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [columns] = useState<Column[]>([
    {
      id: 'entryType',
      label: 'Entry Type',
    },
    {
      id: 'ruleType',
      label: 'Rule Type',
    },
    {
      id: 'ruleValue',
      label: 'Rule Value',
    },
    {
      id: 'active',
      label: 'Active',
      format: (value: boolean) => (value ? 'Yes' : 'No'),
    },
    {
      id: 'edit',
      maxWidth: 1.5,
      getIcon: (row: AllowListRule) => (
        <IconButton
          mdiIconPath={mdiPencilOutline}
          onClick={() => onEditClick(row)}
          color="var(--detail-gray)"
          title="Edit rule"
          size="18px"
        />
      ),
    },
    {
      id: 'delete',
      maxWidth: 1.5,
      getIcon: (row: AllowListRule) => (
        <IconButton
          mdiIconPath={mdiDeleteOutline}
          onClick={() => onDeleteClick(row)}
          color="var(--detail-gray)"
          title="Delete rule"
          size="18px"
        />
      ),
    },
  ] as Column[]);

  useEffect(() => {
    setSelectedPage(0);
  }, [allowListRules]);

  if (allowListRules.loading) {
    return (
      <Row className="loader">
        <Col className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="success" />
        </Col>
      </Row>
    );
  }

  return (
    <PaginatedTableComponent
      columns={columns}
      rows={allowListRules.data}
      rowIdPropName="id"
      selectedPage={selectedPage}
      setSelectedPage={setSelectedPage}
      totalCount={allowListRules.data?.length ?? Number.MAX_SAFE_INTEGER}
      sx={{ maxWidth: 'calc(100vw - 104px - 2*24px - 32px - 38px - 19px)' }}
    />
  );
}
