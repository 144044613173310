import { AxiosError, AxiosRequestConfig } from 'axios';
import authService from '../resources/auth/auth.service';
import { BaseService } from './base.service';

export abstract class AuthorizedService extends BaseService {
  protected get<T>(endpoint?: string | null, config?: AxiosRequestConfig, controller?: AbortController): Promise<T> {
    return this.wrapUnauthorizedRequest<T>(super.get<T>(endpoint, this.getAuthorizedConfig(config), controller));
  }

  protected post<T, R>(
    endpoint?: string | null,
    request?: R,
    config?: AxiosRequestConfig,
    controller?: AbortController,
  ): Promise<T> {
    return this.wrapUnauthorizedRequest<T>(
      super.post<T, R>(endpoint, request, this.getAuthorizedConfig(config), controller),
    );
  }

  protected put<T, R>(
    endpoint?: string | null,
    request?: R,
    config?: AxiosRequestConfig,
    controller?: AbortController,
  ): Promise<T> {
    return this.wrapUnauthorizedRequest<T>(
      super.put<T, R>(endpoint, request, this.getAuthorizedConfig(config), controller),
    );
  }

  protected delete<T>(endpoint?: string | null, config?: AxiosRequestConfig, controller?: AbortController): Promise<T> {
    return this.wrapUnauthorizedRequest<T>(super.delete<T>(endpoint, this.getAuthorizedConfig(config), controller));
  }

  protected wrapUnauthorizedRequest<T>(request: Promise<T>): Promise<T> {
    return request.catch(error => {
      this.handleUnauthorized<T>(error);

      throw error;
    });
  }

  protected getAuthorizedConfig(config?: AxiosRequestConfig): AxiosRequestConfig {
    return { ...authService.requestConfig, ...config };
  }

  private handleUnauthorized<T>(error: AxiosError<T>): void {
    if (error.isAxiosError && error.response?.status === 401) {
      authService.oktaAuthState = null;
    }
  }
}
