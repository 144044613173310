import { mdiCheckCircle, mdiContentCopy } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip, { Props as TooltipProps } from '../tooltip';
import './index.scss';

interface Props {
  text: string;
  successText?: string;
  tooltip: TooltipProps;
  onCopy?: VoidFunction;
  className?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const SUCCESS_TIMEOUT_DELAY = 2000;
// eslint-disable-next-line @typescript-eslint/naming-convention
const DEFAULT_SUCCESS_TEXT = 'Copied successfully';

const SuccessContent = (props: { text: string }): JSX.Element => {
  return (
    <span className="copy-to-clip-board-success">
      <Icon path={mdiCheckCircle} className="me-1" size="16px" color={'var(--warning-success)'} />
      {props.text}
    </span>
  );
};

const CopyToClipboard: React.FC<Props> = (props: Props) => {
  const { text, successText = DEFAULT_SUCCESS_TEXT, onCopy, tooltip, className = '' } = props;
  const [isSuccessState, setSuccessState] = useState(false);

  useEffect(() => {
    if (isSuccessState) {
      const timeout = setTimeout(() => setSuccessState(false), SUCCESS_TIMEOUT_DELAY);
      return () => clearTimeout(timeout);
    }
  }, [isSuccessState]);

  const handleOnCopy = (): void => {
    setSuccessState(true);
    onCopy?.();
  };

  return (
    <span className={`copy-to-clipboard ${className}`}>
      {isSuccessState ? (
        <SuccessContent text={successText} />
      ) : (
        <ReactCopyToClipboard text={text} onCopy={handleOnCopy}>
          <span>
            <Tooltip placement={tooltip.placement} title={tooltip.title}>
              <Icon path={mdiContentCopy} size="16px" color={'var(--detail-gray)'} />
            </Tooltip>
          </span>
        </ReactCopyToClipboard>
      )}
    </span>
  );
};

export default CopyToClipboard;
