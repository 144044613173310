import { mdiContentCopy } from '@mdi/js';
import { TableCell } from '@mui/material';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Column } from '..';
import IconButton from '../../icon-btn';
import './index.scss';

type Props = {
  column: Column;
  row: any;
  formatCell: (column: Column, row: any) => string | HTMLElement;
  onClick?: (column: Column, row: any) => void;
};

export default function TableCellOverlayComponent({ column, row, formatCell, onClick }: Props) {
  const [show, setShow] = useState<boolean>(false);
  const target = useRef(null);

  const cellContent = useMemo(() => formatCell(column, row), [column, row, formatCell]);

  const displayOverlay = useMemo(
    () => column.maxWidth && typeof cellContent === 'string' && cellContent?.length - 4 > column.maxWidth,
    [column, cellContent],
  );

  const sx = useMemo(() => {
    const rowSx = column.getSx ? column.getSx(row) : undefined;

    return { ...column.sx, ...rowSx };
  }, [column, row]);

  const href: string = useMemo(() => {
    const cellValue = row[column.id];

    return column.href
      ? row[column.href]
      : typeof cellValue === 'string' && cellValue.startsWith('http')
      ? cellValue
      : undefined;
  }, [column, row]);

  const maxWidth = useMemo(() => {
    const iconButtonWidth = displayOverlay ? 16 + 12 : 0;
    let width: string | undefined;

    if (column.maxWidth) {
      width = `calc(${column.maxWidth}em + ${iconButtonWidth}px)`;
    }

    return width;
  }, [column, displayOverlay]);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(column, row);
    }
  }, [onClick, column, row]);

  const copyToClipboard = useCallback(() => {
    if (typeof cellContent === 'string') {
      navigator.clipboard.writeText(cellContent);
      setShow(true);

      setTimeout(() => {
        setShow(false);
      }, 2000);
    }
  }, [cellContent]);

  return (
    <TableCell
      ref={target}
      className={displayOverlay ? 'table-cell-overlay' : undefined}
      align={column.align}
      onClick={handleClick}
      sx={{
        fontFamily: 'Lato, sans-serif',
        textOverflow: typeof cellContent === 'string' && cellContent !== '' ? 'ellipsis' : 'unset',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth,
        cursor: onClick ? 'pointer' : 'auto',
        ...sx,
      }}>
      {displayOverlay ? (
        <>
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip>
                <>{cellContent}</>
              </Tooltip>
            }>
            {href ? (
              <a target="_blank" href={href} rel="noreferrer">
                {cellContent}
              </a>
            ) : (
              <span>{cellContent}</span>
            )}
          </OverlayTrigger>
          <IconButton
            mdiIconPath={mdiContentCopy}
            styleType="link"
            onClick={copyToClipboard}
            title="Copy to clipboard"
            color="var(--detail-gray)"
            size="16px"
          />
          <Overlay target={target} show={show} placement="bottom">
            <Tooltip>Copied!</Tooltip>
          </Overlay>
        </>
      ) : href ? (
        <a target="_blank" href={href} rel="noreferrer">
          {cellContent}
        </a>
      ) : (
        <>
          {column.getIcon ? column.getIcon(row) : undefined}
          {cellContent}
        </>
      )}
    </TableCell>
  );
}
