import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useFeatures from '../../../../resources/features/features-hook';
import { ClaimPermissions, FeaturesMap, Permission } from '../../../../resources/features/features-types';
import { prettyFormatList } from '../../../../utils/pretty-formatters-utils';
import ExternalDataRenderer from '../../../hocs/external-data-renderer';
import DropdownBtn, { Props as DropdownBtnProps } from '../../../ui/dropdown-btn';
import SearchInput from '../../../ui/search-input';
import DialogLayout from '../../dialog-layout';
import './index.scss';
import PermissionFeatures, { Props as PermissionFeaturesProps } from './permission-features';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
}

export type PermissionFeature = Omit<PermissionFeaturesProps['permissionFeat'], 'onToggleActive'> & {
  permission: Permission;
};

const ensureContains = <T,>(set: T[], item: T): T[] => {
  if (!set.includes(item)) {
    return [item, ...set];
  }

  return set;
};

const makePermissionsFeatures = (
  activePermissions: Permission[],
  featuresMap: FeaturesMap,
): Array<PermissionFeature> => {
  return Object.entries(featuresMap)
    .map(([permission, features]) => ({
      permission: permission as Permission,
      readonly: permission === Permission.ManageOwnPermissions,
      label: permission,
      active: activePermissions.includes(permission as any),
      features,
    }))
    .sort(a => (a.readonly ? -1 : 1));
};

export const CustomClaimPermissionsItem = { label: 'Custom Permissions', id: 'custom' };

export const makeClaimsPermissionItems = (claimsPermissions: ClaimPermissions[]): DropdownBtnProps['items'] => {
  const collator = new Intl.Collator('en');

  return [
    CustomClaimPermissionsItem,
    ...claimsPermissions
      .map(claimPermission => ({
        label: claimPermission.name,
        id: claimPermission.name,
      }))
      .sort((a, b) => collator.compare(a.label, b.label)),
  ];
};

export const filterBySearchText = (permissionFeatures: Array<PermissionFeature>, searchText: string) => {
  return permissionFeatures.filter(permissionFeat =>
    permissionFeat.permission.toLowerCase().includes(searchText.toLowerCase()),
  );
};

export const hasSomeClaimApplied = (
  appliedPermissions: Permission[],
  claimPermissions: ClaimPermissions[],
): boolean => {
  const activePermissions = appliedPermissions.filter(permission => permission !== Permission.ManageOwnPermissions);

  return !!claimPermissions.find(claimPermission =>
    _.isEqual(
      activePermissions,
      claimPermission.permissions.filter(permission => permission !== Permission.ManageOwnPermissions),
    ),
  );
};

const PermissionsModal: React.FC<Props> = props => {
  const { isOpen, onClose } = props;
  const {
    permissions: appliedPermissions,
    featuresMap,
    updatePermissionsStatus,
    claimsPermissions,
    fetchFeaturesMap,
    fetchClaimsPermissions,
    updatePermissions,
  } = useFeatures();
  const [permissions, setPermissions] = useState<Permission[]>(appliedPermissions);
  const [searchText, setSearchText] = useState('');
  const permissionsFeatures = useMemo(
    () => (featuresMap.data ? makePermissionsFeatures(permissions, featuresMap.data) : undefined),
    [featuresMap.data, permissions],
  );
  const claimPermissionItems = useMemo(
    () => makeClaimsPermissionItems(claimsPermissions?.data ?? []),
    [claimsPermissions?.data],
  );
  const [selectedClaimPermissionsItem, setSelectedClaimPermissionItem] = useState(
    claimPermissionItems.length ? claimPermissionItems[0] : undefined,
  );

  useEffect(() => {
    fetchFeaturesMap();
  }, [fetchFeaturesMap]);

  useEffect(() => {
    fetchClaimsPermissions();
  }, [fetchClaimsPermissions]);

  useEffect(() => {
    setPermissions(appliedPermissions);
  }, [appliedPermissions]);

  useEffect(() => {
    const claimPermission = (claimsPermissions.data ?? []).find(
      claimPermissions => claimPermissions.name === selectedClaimPermissionsItem?.id,
    );

    if (claimPermission) {
      setPermissions(ensureContains(claimPermission.permissions, Permission.ManageOwnPermissions));
    }
  }, [claimsPermissions, selectedClaimPermissionsItem]);

  useEffect(() => {
    if (!hasSomeClaimApplied(permissions, claimsPermissions.data ?? [])) {
      setSelectedClaimPermissionItem(CustomClaimPermissionsItem);
    }
  }, [claimsPermissions, permissions]);

  useEffect(() => {
    if (updatePermissionsStatus.success) {
      onClose();
    }
  }, [onClose, updatePermissionsStatus.success]);

  const handleClaimPermissionsChange: DropdownBtnProps['onClick'] = useCallback(item => {
    setSelectedClaimPermissionItem(item || undefined);
  }, []);

  const handleToggleActive = (permission: Permission, active: boolean) => {
    if (active) setPermissions(prev => ensureContains(prev, permission));
    else setPermissions(prev => prev.filter(perm => perm !== permission));
  };

  const handleApply = () => {
    void updatePermissions(permissions);
  };

  return (
    <DialogLayout
      open={isOpen}
      title="Permissions Settings"
      subtitle={`Set the permissions according to the features below. Use "User Settings” for specific setup.`}
      confirmActionText="Apply"
      confirmActionHandler={handleApply}
      scapeActionText="Cancel"
      scapeActionHandler={onClose}
      isLoading={updatePermissionsStatus.loading}>
      <section className="permissions-modal-container">
        <header className="permissions-modal-container-header">
          <DropdownBtn
            id="claims-selector"
            styleType="filter"
            value={selectedClaimPermissionsItem}
            items={claimPermissionItems}
            label="Claims permissions"
            placeholder="Select a claim set of permissions"
            onClick={handleClaimPermissionsChange}
          />
          <SearchInput
            id="permissions-searcher"
            label="Search"
            placeholder="Search permissions..."
            value={searchText}
            onChange={txt => setSearchText(txt)}
          />
        </header>
        <ExternalDataRenderer
          externalData={featuresMap}
          makeDataElement={() => (
            <section className="permissions-modal-container-content">
              <header>
                <h5>Permission</h5>
                <h5>Features</h5>
              </header>
              <section className="permissions-modal-container-content-list fancy-scrollbar">
                {filterBySearchText(permissionsFeatures ?? [], searchText).map(permissionFeat => (
                  <PermissionFeatures
                    key={permissionFeat.label}
                    permissionFeat={{
                      ...permissionFeat,
                      onToggleActive: active => handleToggleActive(permissionFeat.permission, active),
                    }}
                  />
                ))}
              </section>
            </section>
          )}
        />
        <footer className="permissions-modal-container-footer">
          <p>
            <span className="label">{permissions.length} permissions enabled: </span>
            <span className="permissions">{prettyFormatList(permissions)}</span>
          </p>
        </footer>
      </section>
    </DialogLayout>
  );
};

export default PermissionsModal;
