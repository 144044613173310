import { Button, LoadingComponent, Typography } from '@amway/react-components';
import Icon from '@mdi/react';
import { SxProps, Theme } from '@mui/material';
import { AxiosError } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import environment, { timezoneName } from '../../../../config/env';
import { Features } from '../../../../config/features';
import { WithFeaturesProxy } from '../../../../helpers/with-features-proxy';
import useExecutions from '../../../../resources/executions/executions-hook';
import { ProcessorStepLog } from '../../../../resources/executions/executions-types';
import {
  ExecutionDetails,
  ExecutionStatus,
  ProcessorLog,
  RetryProcess,
} from '../../../../resources/history-list/history-list-types';
import { MessageDetailsRequest } from '../../../../resources/messages/messages-types';
import useNotification from '../../../../resources/notification/notification-hook';
import { NotificationProps } from '../../../../resources/notification/notification-types';
import { getNotificationProps } from '../../../../resources/notification/notification-utils';
import useProcessors from '../../../../resources/processors/processors-hook';
import executorService from '../../../../service/executor.service';
import { formatExecutionTime } from '../../../../utils/date-utils';
import { dateToCorrectTimezone, getStatusColor, getStatusIconPath } from '../../../../utils/execution-utils';
import ExternalDataRenderer from '../../../hocs/external-data-renderer';
import PaginatedTableComponent, { Column } from '../../../ui/paginated-table';
import ExecutionDetailsInfoComponent from '../details-info';
import ResendEmailModalComponent, { EmailResendFormFields } from '../resend-email-modal';
import './index.scss';

interface ExposedProps {
  execution: ProcessorLog;
  statusColor: string;
  details?: ExecutionDetails | null;
}

interface Props extends ExposedProps {
  canReprocess: boolean;
  mktHasResendEmail: boolean;
  mktHasReprocessExecution: boolean;
}

const ExecutionHistoryDetailsComponent: React.FC<Props> = props => {
  const { execution, statusColor, details, canReprocess, mktHasReprocessExecution, mktHasResendEmail } = props;
  const { pushNotification } = useNotification();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reprocessing, setReprocessing] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const { executionCompletedSteps } = useExecutions();
  const { processorSteps } = useProcessors();

  const [columns] = useState<Column[]>(
    (
      [
        {
          id: 'stepNumber',
          label: 'Step',
          sx: { textAlign: 'right' },
          format: (value: number) => value.toString().padStart(2, '0'),
          getIcon: (row: any) => {
            const status: ExecutionStatus | undefined = row.status;

            if (status && status !== ExecutionStatus.OK) {
              const statusIconPath = getStatusIconPath(status);
              const statusColor = getStatusColor(status);

              return (
                <Icon
                  path={statusIconPath!}
                  title="Status"
                  size="16px"
                  horizontal
                  vertical
                  rotate={180}
                  color={statusColor}
                  style={{ verticalAlign: 'text-bottom', marginRight: '8px' }}
                />
              );
            }
          },
        },
        {
          id: 'stepName',
          label: 'Name',
        },
        {
          id: 'startDt',
          label: timezoneName === '' ? 'Start Date (GMT)' : `Start Date (${timezoneName})`,
          format: (value: string) => dateToCorrectTimezone(value),
        },
        {
          id: 'endDt',
          label: timezoneName === '' ? 'End Date (GMT)' : `End Date (${timezoneName})`,
          format: (value: string) => dateToCorrectTimezone(value),
        },
        {
          id: 'executionTime',
          label: 'Execution Time',
          format: (value: number) => formatExecutionTime(value),
        },
        {
          id: 'output',
          label: 'Output',
          maxWidth: 15,
        },
      ] as Column[]
    ).map(column => {
      column.getSx = (row: any) => {
        const status: ExecutionStatus | undefined = row.status;

        return {
          color: status && status !== ExecutionStatus.OK ? getStatusColor(status) : undefined,
        } as SxProps<Theme>;
      };

      return column;
    }),
  );

  const updateNotification = useCallback(
    (status: RetryProcess, execution: ProcessorLog) => {
      const notification: NotificationProps = getNotificationProps(status, [execution]);

      pushNotification(notification);
      setReprocessing(false);
    },
    [pushNotification],
  );

  const handleRetry = useCallback(
    (overwriteEmail?: string, overwritePhone?: string, overwriteLanguage?: string) => {
      const executionId = execution.executionId ?? details?.execution.executionId;

      if (executionId) {
        const startDt = execution.startDt ?? details?.execution.startDt;
        const endDt = execution.endDt ?? details?.execution.endDt;

        executorService
          .retryExecution(executionId, startDt, endDt, overwriteEmail, overwritePhone, overwriteLanguage)
          .then(status => updateNotification(status, { executionId }))
          .catch((err: AxiosError) => {
            const retryProcess: RetryProcess = err.response?.data?.error ?? RetryProcess.FAILED;
            updateNotification(retryProcess, { executionId });
          });
      } else {
        setReprocessing(false);
      }
    },
    [execution, details, updateNotification],
  );

  const handleResend = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleReprocess = useCallback(() => {
    setReprocessing(true);
    handleRetry();
  }, [handleRetry]);

  const handleModalConfirmation = useCallback(
    ({ overwriteEmail, overwritePhone, overwriteLanguage }: EmailResendFormFields) => {
      handleRetry(overwriteEmail, overwritePhone, overwriteLanguage);
    },
    [handleRetry],
  );

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleModalCancel = useCallback(() => {
    closeModal();
    setReprocessing(false);
  }, [closeModal]);

  const completedSteps = useMemo(() => {
    if (executionCompletedSteps.data && executionCompletedSteps?.data?.length > 0) {
      return executionCompletedSteps.data;
    }
    return [] as ProcessorStepLog[];
  }, [executionCompletedSteps.data]);

  const createCustomChannelDetailsFromExecution = (execution: ProcessorLog): MessageDetailsRequest => {
    return {
      id: execution.customChannelId,
      startDt: execution.request?.startDate,
      endDt: execution.request?.endDate,
    };
  };

  return details ? (
    <>
      <ExecutionDetailsInfoComponent
        details={details}
        statusColor={statusColor}
        hasCustomChannelMessage={execution.hasCustomChannelMessage}
        customChannelDetails={createCustomChannelDetailsFromExecution(execution)}
      />
      <PaginatedTableComponent
        columns={columns}
        rows={details?.steps}
        rowIdPropName="stepNumber"
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        sx={{ maxWidth: 'calc(100vw - 104px - 2*24px - 32px - 38px - 19px)' }}
      />
      {canReprocess && (
        <>
          <Row className="execution-history-details reprocess">
            <Col className="d-flex justify-content-center">
              {mktHasResendEmail && (
                <Button loading={showModal} onClick={handleResend}>
                  RESEND {environment.EMAIL_LABEL.toUpperCase()}
                </Button>
              )}
              {mktHasReprocessExecution && (
                <Button loading={reprocessing} onClick={handleReprocess}>
                  REPROCESS EXECUTION
                </Button>
              )}
            </Col>
          </Row>
        </>
      )}
      <ResendEmailModalComponent
        execution={details?.execution ?? execution}
        show={showModal}
        close={closeModal}
        onHide={handleModalCancel}
        onCancel={handleModalCancel}
        onConfirm={handleModalConfirmation}
      />
    </>
  ) : executionCompletedSteps.data ? (
    <Row>
      <Typography>Execution Steps:</Typography>
      <ol>
        <ExternalDataRenderer
          externalData={processorSteps}
          makeDataElement={processorSteps =>
            processorSteps.map(step => {
              const completedStep = completedSteps.some(completed => completed.stepName === step);
              return (
                <li className="completed-steps-item">
                  <Typography
                    className="completed-animation"
                    weight="bold"
                    color={completedStep ? 'primary' : 'detail-gray'}>
                    {step}
                  </Typography>
                  {!completedStep && <LoadingComponent />}
                </li>
              );
            })
          }
        />
      </ol>
    </Row>
  ) : (
    <Row className="execution-history-details">
      <Col className="d-flex justify-content-center">
        <Spinner animation="border" variant="success" />
      </Col>
    </Row>
  );
};

export default WithFeaturesProxy<ExposedProps>(
  Features.ReprocessEmail,
  Features.MktFeature_ReprocessExecution,
  Features.MktFeature_ResendEmail,
)((props, canReprocess, mktHasReprocessExecution, mktHasResendEmail) => {
  return (
    <ExecutionHistoryDetailsComponent
      {...props}
      canReprocess={canReprocess}
      mktHasReprocessExecution={mktHasReprocessExecution}
      mktHasResendEmail={mktHasResendEmail}
    />
  );
});
