import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { BaseSyntheticEvent, useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import './index.scss';

type Props = {
  id: string;
  label?: string;
  placeholder: string;
  value: string;
  isInvalid?: boolean;
  feedbackMessage?: string | null;
  disabled?: boolean;
  onChange: (value: string) => void;
};

export default function SearchInputComponent({
  id,
  label,
  placeholder,
  value,
  isInvalid,
  feedbackMessage,
  disabled = false,
  onChange,
}: Props) {
  const handleChange = useCallback(
    (event: BaseSyntheticEvent<InputEvent, any, HTMLInputElement>) => {
      const { value }: { value: string } = event.target;

      onChange(value);
    },
    [onChange],
  );

  return (
    <Form.Group className="search-input">
      {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
      <div className="d-flex">
        <Form.Control id={id} placeholder={placeholder} value={value} onChange={handleChange} isInvalid={isInvalid} />
        {!isInvalid && (
          <Button type="submit" disabled={disabled || isInvalid}>
            <Icon
              path={mdiMagnify}
              title="Search"
              size="27px"
              horizontal
              vertical
              rotate={180}
              color="var(--detail-border)"
            />
          </Button>
        )}
      </div>
      <Form.Control.Feedback type="invalid">{feedbackMessage}</Form.Control.Feedback>
    </Form.Group>
  );
}
