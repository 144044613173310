import papa from 'papaparse';

export const toCSV = (data: string[][]): string => {
  return papa.unparse(data, {
    quotes: false,
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ',',
    header: true,
    newline: '\r\n',
    skipEmptyLines: false,
  });
};
