import colorSchemaMap from '../config/color-schema';
import environment from '../config/env';

export const applyColorSchema = (): void => {
  // If we've got a not registered market, we fallback to the NA schema
  const colorSchema = colorSchemaMap[environment.MARKET] ?? colorSchemaMap.NA;

  document.querySelector('body')?.style.setProperty('--primary', colorSchema.primary);
  document.querySelector('body')?.style.setProperty('--secondary', colorSchema.secondary);
  document.querySelector('body')?.style.setProperty('--tertiary', colorSchema.tertiary);
};
