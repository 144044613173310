import { Button, Typography } from '@amway/react-components';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog as DialogMaterial } from '@mui/material';
import React from 'react';
// import Button from '../../ui/button';
import LogoLoading from '../../ui/logo-loading';
import './index.scss';

interface Props {
  open: boolean;
  title: React.ReactNode;
  subtitle?: string;
  confirmActionText?: React.ReactNode;
  confirmActionHandler?: VoidFunction;
  scapeActionText: string;
  scapeActionHandler: VoidFunction;
  className?: string;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  variant?: 'standard' | 'outlined';
  isLoading?: boolean;
}

const DialogLayout: React.FC<Props> = props => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    open,
    title,
    subtitle = '',
    confirmActionText,
    confirmActionHandler,
    scapeActionText,
    scapeActionHandler,
    className = '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    fullWidth = false,
    maxWidth = false,
    variant = 'standard',
    isLoading,
    children,
  } = props;

  return (
    <DialogMaterial onClose={scapeActionHandler} open={open} fullWidth={fullWidth} maxWidth={maxWidth}>
      <div className="dialog-container">
        {isLoading ? (
          <LogoLoading />
        ) : (
          <>
            <header className="dialog-header">
              <div className="header-container">
                <Typography variant="heading" color="primary">
                  {title}
                </Typography>
                {subtitle && (
                  <Typography color="text-gray" weight="bold">
                    {subtitle}
                  </Typography>
                )}
              </div>
              <CloseIcon fontSize="large" onClick={scapeActionHandler} />
            </header>
            <main className={`dialog-content-container fancy-scrollbar ${className}`}>{children}</main>
            <footer className={`buttons-container ${variant === 'standard' ? 'primary' : 'secondary'}`}>
              <Button
                onClick={scapeActionHandler}
                backgroundColor="tertiary"
                fontColor="bg-black"
                variant={variant === 'standard' ? 'primary' : 'link'}>
                {scapeActionText}
              </Button>
              {confirmActionText && (
                <Button disabled={!confirmActionHandler} onClick={confirmActionHandler}>
                  {confirmActionText}
                </Button>
              )}
            </footer>
          </>
        )}
      </div>
    </DialogMaterial>
  );
};

export default DialogLayout;
