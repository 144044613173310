import { Typography } from '@amway/react-components';
import { mdiKeyboardBackspace } from '@mdi/js';
import { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import IconButton from '../../../components/ui/icon-btn';
import useAllowList from '../../../resources/allow-list/allow-list-hook';
import { AllowListRule } from '../../../resources/allow-list/allow-list-model';
import AllowListFormComponent from '../allow-list-form';

type Props = {
  onBackClick: VoidFunction;
};

export default function AllowListAddComponent({ onBackClick }: Props) {
  const { addRuleStatus, addRule } = useAllowList();
  const [rule] = useState<AllowListRule>({});

  useEffect(() => {
    if (addRuleStatus.success) {
      onBackClick();
    }
  }, [addRuleStatus.success, onBackClick]);

  const handleSubmit = useCallback(
    (rule: AllowListRule) => {
      addRule(rule);
    },
    [addRule],
  );

  return (
    <Card className="allow-list-add">
      <Card.Body>
        <Row>
          <Col>
            <IconButton mdiIconPath={mdiKeyboardBackspace} onClick={onBackClick} title="Back" size="27px">
              Back to Allow List
            </IconButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography variant="heading">New Allow List Rule</Typography>
            <Typography weight="bold" color="text-gray" className="mt-3">
              Fill the fields below.
            </Typography>
          </Col>
        </Row>
        <Row>
          <AllowListFormComponent rule={rule} onSubmit={handleSubmit} submitLabel="CREATE" />
        </Row>
      </Card.Body>
    </Card>
  );
}
