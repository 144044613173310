import React, { useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Applications } from '../../../@types/configuration';
import ExecutionHistoryDetailsCardComponent from '../../../components/shared/execution/history-details-card';
import useExecutions from '../../../resources/executions/executions-hook';

function ExecutionHistoryDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { executionDetails, fetchExecutionDetails } = useExecutions();
  const wannaBeProcessorLog = useMemo(
    () => ({
      executionId: searchParams.get('executionId') ?? undefined,
      sharedId: searchParams.get('sharedId') ?? undefined,
      application: (searchParams.get('application') as Applications) ?? undefined,
      processorName: searchParams.get('processorName') ?? undefined,
      startDt: searchParams.get('startDt') ?? undefined,
      endDt: searchParams.get('endDt') ?? undefined,
    }),
    [searchParams],
  );

  const referrer = useMemo(() => searchParams.get('referrer') ?? undefined, [searchParams]);

  useEffect(() => {
    fetchExecutionDetails(wannaBeProcessorLog);
  }, [fetchExecutionDetails, wannaBeProcessorLog]);

  const handleGoBack = () => {
    if (location.key) {
      navigate(-1);
    } else {
      navigate('/execution-history');
    }
  };

  return (
    <Container>
      <ExecutionHistoryDetailsCardComponent
        execution={wannaBeProcessorLog}
        details={executionDetails.data}
        onBackClick={handleGoBack}
        referrer={referrer}
      />
    </Container>
  );
}

export default ExecutionHistoryDetails;
