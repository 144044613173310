import { createContext } from 'react';
import { Features } from '../../config/features';
import { ExternalAction, ExternalData } from './../../@types/external-api';
import { ClaimPermissions, FeaturesMap, Permission } from './features-types';

export interface FeaturesCtxType {
  loading: boolean;
  updatePermissionsStatus: ExternalAction;
  permissions: Permission[];
  featuresMap: ExternalData<FeaturesMap>;
  claimsPermissions: ExternalData<ClaimPermissions[]>;
  hasFeature: (resourcePermissions: Features) => boolean;
  // This feature should be enabled only on QA/DEV environments
  updatePermissions: (permissions: Permission[]) => void;
  fetchClaimsPermissions: () => void;
  fetchFeaturesMap: () => void;
}

const PermissionsContext = createContext<FeaturesCtxType>(null as any);

export default PermissionsContext;
