import { Button } from '@amway/react-components';
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Features } from '../../../../../config/features';
import { WithFeaturesProxy } from '../../../../../helpers/with-features-proxy';
import {
  CustomChannelChannel,
  CustomChannelMessageStatus,
  CustomChannelMessageType,
} from '../../../../../resources/custom-channel/custom-channel-history-list-types';
import { MessagesRequest } from '../../../../../resources/messages/messages-types';
import { addDays, subtractDays, toISOString } from '../../../../../utils/date-utils';
import DateRangeFieldsComponent from '../../../../ui/date-range-fields';
import MultiSelectDropdown from '../../../../ui/multi-select-dropdown';
import SearchInputComponent from '../../../../ui/search-input';
import './index.scss';

export interface Filters {
  search: string;
  startDt: string;
  endDt: string;
  channels?: CustomChannelChannel[];
  statuses?: CustomChannelMessageStatus[];
  messageTypes?: CustomChannelMessageType[];
}

interface ExposedProps {
  filters?: Filters;
  onFiltersChange?: (newFilters: Filters) => void;
  onSubmit: (request: MessagesRequest) => void;
  submitOnLoad?: boolean;
  availableStatus?: CustomChannelMessageStatus[];
  availableMsgType?: CustomChannelMessageType[];
  availableChannel?: CustomChannelChannel[];
  daysAgo?: number;
  daysLater?: number;
  daysRange?: number;
  displayExcludeReprocessedEvents?: boolean;
}

interface Props extends ExposedProps {
  mktHasSharedId: boolean;
  mktHasUserId: boolean;
  mktHasMultipleCountry: boolean;
}

function CustomChannelHistoryFormComponent({
  onSubmit,
  submitOnLoad,
  filters,
  onFiltersChange,
  availableStatus = Object.values(CustomChannelMessageStatus),
  availableMsgType = Object.values(CustomChannelMessageType),
  availableChannel = Object.values(CustomChannelChannel),
  daysRange = 7,
  daysAgo = 2,
  daysLater = 1,
  mktHasSharedId,
}: Props) {
  const availableStatuses = useMemo(
    () => availableStatus.map(statusName => ({ name: statusName, value: statusName })),
    [availableStatus],
  );
  const availableMsgTypes = useMemo(
    () => availableMsgType.map(msgTypeName => ({ name: msgTypeName, value: msgTypeName })),
    [availableMsgType],
  );
  const availableChannels = useMemo(
    () => availableChannel.map(channelName => ({ name: channelName, value: channelName })),
    [availableChannel],
  );
  const [startDt, setStartDt] = useState<string>(
    filters?.startDt ? filters?.startDt : toISOString(subtractDays(new Date(), daysAgo)),
  );
  const [maxDate, setMaxDate] = useState<string>(toISOString(addDays(new Date(), daysLater)));
  const [endDt, setEndDt] = useState<string>(filters?.endDt ? filters?.endDt : maxDate);
  const [search, setSearch] = useState<string>(filters?.search ?? '');
  const [selectedStatus, setSelectedStatus] = useState<string[] | undefined>(filters?.statuses);
  const [selectedMsgType, setSelectedMsgType] = useState<string[] | undefined>(filters?.messageTypes);
  const [selectedChannel, setSelectedChannel] = useState<string[] | undefined>(filters?.channels);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [exceedsDaysRange, setExceedsDaysRange] = useState<boolean>(false);
  const [searchInvalidMessage, setSearchInvalidMessage] = useState<string | null | undefined>();
  const [hasSubmittedOnLoad, setHasSubmittedOnLoad] = useState<boolean>(false);
  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (filters) {
      setStartDt(filters.startDt);
      setEndDt(filters.endDt);
      setSearch(filters.search);
      setSelectedStatus(filters.statuses);
      setSelectedMsgType(filters.messageTypes);
      setSelectedChannel(filters.channels);
    }
  }, [filters]);

  useEffect(() => {
    if (onFiltersChange) {
      onFiltersChange({
        startDt,
        endDt,
        search,
        statuses: selectedStatus as CustomChannelMessageStatus[],
        messageTypes: selectedMsgType as CustomChannelMessageType[],
        channels: selectedChannel as CustomChannelChannel[],
      });
    }
  }, [onFiltersChange, endDt, search, selectedStatus, startDt, selectedMsgType, selectedChannel]);

  const formValues = useMemo(
    () => ({
      search,
      statuses: selectedStatus as CustomChannelMessageStatus[],
      messageTypes: selectedMsgType as CustomChannelMessageType[],
      channels: selectedChannel as CustomChannelChannel[],
      startDt: startDt ? startDt + 'T00:00:00-00:00' : undefined,
      endDt: endDt ? endDt + 'T23:59:59-00:00' : undefined,
    }),
    [search, selectedStatus, selectedMsgType, selectedChannel, startDt, endDt],
  );

  const submit = useCallback(() => {
    if (!invalid) {
      onSubmit(formValues);
    }
  }, [invalid, onSubmit, formValues]);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      submit();
    },
    [submit],
  );

  useEffect(() => {
    if (search.includes('%') || search.includes('"') || search.includes('#')) {
      setInvalid(true);
      setSearchInvalidMessage('%, ", # are non-valid characters');
    } else {
      setInvalid(false);
      setSearchInvalidMessage(null);
    }
  }, [search]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setMaxDate(toISOString(addDays(new Date(), daysLater)));
    }, 60000);

    return () => {
      clearInterval(intervalRef.current!);
    };
  }, [daysLater]);

  useEffect(() => {
    if (submitOnLoad && !hasSubmittedOnLoad) {
      submit();
      setHasSubmittedOnLoad(true);
    }
  }, [hasSubmittedOnLoad, submitOnLoad, submit]);

  const handleStatusesChanges = useCallback((newStatuses?: string[]) => {
    if (newStatuses && newStatuses.length > 0) setSelectedStatus(newStatuses);
    else setSelectedStatus(undefined);
  }, []);

  const handleMessageTypesChanges = useCallback((newMessageTypes?: string[]) => {
    if (newMessageTypes && newMessageTypes.length > 0) setSelectedMsgType(newMessageTypes);
    else setSelectedMsgType(undefined);
  }, []);

  const handleChannelsChanges = useCallback((newChannelTypes?: string[]) => {
    if (newChannelTypes && newChannelTypes.length > 0) setSelectedChannel(newChannelTypes);
    else setSelectedChannel(undefined);
  }, []);

  return (
    <Form className="custom-channel-message-history-form" onSubmit={handleSubmit}>
      <Row>
        <DateRangeFieldsComponent
          sm={12}
          md={6}
          lg={3}
          xl={3}
          xxl={2}
          styleType="filter"
          startDate={startDt}
          setStartDate={setStartDt}
          endDate={endDt}
          setEndDate={setEndDt}
          maxDate={maxDate}
          daysRange={daysRange}
          exceedsDaysRange={exceedsDaysRange}
          setExceedsDaysRange={setExceedsDaysRange}
        />
        <Col>
          <MultiSelectDropdown
            id="channel-dropdown"
            label="Channel"
            placeholder="Channel"
            variant="secondary"
            all
            items={availableChannels}
            value={selectedChannel}
            onChange={handleChannelsChanges}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4} lg={4} xl={3} xxl={2}>
          <MultiSelectDropdown
            id="status-dropdown"
            label="Status"
            placeholder="Status"
            variant="secondary"
            all
            items={availableStatuses}
            value={selectedStatus}
            onChange={handleStatusesChanges}
          />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3} xxl={2}>
          <MultiSelectDropdown
            id="message-type-dropdown"
            label="Message Type"
            placeholder="Message Type"
            variant="secondary"
            all
            items={availableMsgTypes}
            value={selectedMsgType}
            onChange={handleMessageTypesChanges}
          />
        </Col>
        <Col className="search-input">
          <SearchInputComponent
            id="search-execution-input"
            label="Search"
            placeholder={
              mktHasSharedId
                ? 'Search Execution ID, Message ID, Shared ID, Input...'
                : 'Search Custom Channel ID, Execution ID, UUID, ABO #, Recipient #...'
            }
            isInvalid={invalid}
            feedbackMessage={searchInvalidMessage}
            value={search}
            onChange={setSearch}
          />
        </Col>
        <Col
          sm={12}
          md={12}
          lg={4}
          xl={2}
          xxl={2}
          className="submit d-flex mt-1 mb-4 justify-content-center align-content-center align-items-end">
          <Button disabled={invalid || exceedsDaysRange}>SEARCH</Button>
        </Col>
      </Row>
    </Form>
  );
}

export default WithFeaturesProxy<ExposedProps>(
  Features.MktFeature_HasSharedId,
  Features.MktFeature_ShowUserIdOnExecutionDetails,
  Features.MktFeature_HasMultipleCountry,
)((props, mktHasSharedId, mktHasUserId, mktHasMultipleCountry) => {
  return (
    <CustomChannelHistoryFormComponent
      {...props}
      mktHasSharedId={mktHasSharedId}
      mktHasUserId={mktHasUserId}
      mktHasMultipleCountry={mktHasMultipleCountry}
    />
  );
});
