import { Languages } from '../../../@types/languages';
import DropdownBtn, { Item } from '../../ui/dropdown-btn';

export const availableLanguages: Item[] = [
  {
    id: Languages.EN,
    label: 'English',
  },
  {
    id: Languages.ES,
    label: 'Spanish',
  },
  {
    id: Languages.FR,
    label: 'French',
  },
];

type Props = {
  value?: Item;
  onClick: (item: Item) => void;
};

export default function LanguageDropdownComponent({ value, onClick }: Props) {
  return (
    <DropdownBtn
      none
      id="language-dropdown"
      label="Language"
      placeholder="Select language"
      value={value}
      items={availableLanguages}
      onClick={onClick as unknown as (item?: Item | null) => void}
    />
  );
}
