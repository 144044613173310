import { Typography } from '@amway/react-components';
import { mdiKeyboardBackspace } from '@mdi/js';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { timezoneName } from '../../../../../config/env';
import { CustomChannelMessageStatus } from '../../../../../resources/custom-channel/custom-channel-history-list-types';
import { MessageLog, MessageLogDetails } from '../../../../../resources/messages/messages-types';
import {
  customChannelTimestampToCorrectTimezone,
  getCustomChannelStatusColor,
} from '../../../../../utils/execution-utils';
import TealiumDataLayer from '../../../../hocs/tealium-data-layer';
import IconButton from '../../../../ui/icon-btn';
import CustomChannelMessageHistoryDetailsComponent from '../history-details';
import './index.scss';

type Props = {
  message: MessageLog;
  details?: MessageLogDetails | null;
  onBackClick?: VoidFunction;
};

function CustomChannelMessageHistoryDetailsCardComponent(
  { message, details, onBackClick }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const statusColor = useMemo(
    () => getCustomChannelStatusColor(details?.status as CustomChannelMessageStatus),
    [details],
  );

  const startDate = useMemo(() => {
    const startDt = details?.startDate;

    return startDt ? customChannelTimestampToCorrectTimezone(startDt) : '';
  }, [details]);

  const endDate = useMemo(() => {
    const endDt = details?.updateDate;

    return endDt ? customChannelTimestampToCorrectTimezone(endDt) : '';
  }, [details]);

  const timezoneAbbr = timezoneName === '' ? 'GMT' : `${timezoneName}`;

  const cardTitle = 'Custom Channel ID: ';

  return (
    <TealiumDataLayer
      page_name="Custom Channel Message Details "
      page_section="custom-channel-message-details"
      page_category="Audition"
      page_subCategory="Exception Historic Data">
      <Card className="custom-channel-message-history-details" ref={ref}>
        <Card.Body>
          {onBackClick && (
            <Row>
              <Col>
                <IconButton mdiIconPath={mdiKeyboardBackspace} onClick={onBackClick} title="Back" size="27px">
                  Custom Channel Message History
                </IconButton>
              </Col>
            </Row>
          )}
          <Row className="mb-5 mt-4">
            <Col>
              <Typography variant="heading">
                {cardTitle}
                <span className="execution-id" style={{ color: statusColor }}>
                  {message.id}
                </span>
              </Typography>
              <Typography weight="bold" color="text-gray">
                <Row className="cc-other-ids">
                  <div>
                    <strong>Execution ID:</strong> {details?.executionId}
                  </div>
                  <div>
                    <strong>KaKaoTalk UUID:</strong> {details?.externalId}
                  </div>
                </Row>
              </Typography>
              <Typography weight="bold" color="text-gray" className="processor mt-3">
                <Row>
                  <Col>
                    <strong>Campaign Name:</strong> {details?.campaignName}
                  </Col>
                  <Col>
                    <strong>Start date:</strong> {startDate} ({timezoneAbbr})
                  </Col>
                  <Col>
                    <strong>End date:</strong> {endDate} ({timezoneAbbr})
                  </Col>
                </Row>
              </Typography>
            </Col>
          </Row>
          <CustomChannelMessageHistoryDetailsComponent message={message} statusColor={statusColor} details={details} />
        </Card.Body>
      </Card>
    </TealiumDataLayer>
  );
}

export default forwardRef(CustomChannelMessageHistoryDetailsCardComponent);
