import { useEffect } from 'react';
import { Markets } from '../../../@types/configuration';
import { SiteCountries, SiteCurrenciesCode, SiteLanguages, TealiumView } from '../../../@types/tealium';
import environment from '../../../config/env';
import useAuth from '../../../resources/auth/auth-hook';

// Same type of TealiumView without the constant types and types that depend on user or market
type TealiumDataLayerProps = Omit<
  TealiumView,
  | 'site_country'
  | 'site_currencyCode'
  | 'site_language'
  | 'site_prefix'
  | 'site_region'
  | 'site_subRegion'
  | 'site_type'
  | 'site_type_short'
  | 'site_webProperty'
  | 'site_webProperty_mod'
  | 'visitor_aiu'
  | 'visitor_userProfile'
>;

const TealiumDataLayer: React.FC<TealiumDataLayerProps> = props => {
  const {
    children,
    event_name,
    search_category,
    search_keyword,
    search_type,
    search_results,
    page_category,
    page_name,
    page_section,
    page_subCategory,
  } = props;

  const { user } = useAuth();

  const defineSiteCountry = (): SiteCountries => {
    const titleMap: Record<Markets, SiteCountries> = {
      [Markets.NA]: 'us',
      [Markets.INDIA]: 'in',
      [Markets.THAILAND]: 'th',
      [Markets.KOREA]: 'kr',
    };

    return titleMap[environment.MARKET] ?? 'us';
  };

  const defineSiteCurrency = (): SiteCurrenciesCode => {
    const titleMap: Record<Markets, SiteCurrenciesCode> = {
      [Markets.NA]: 'usd',
      [Markets.INDIA]: 'irn',
      [Markets.THAILAND]: 'thb',
      [Markets.KOREA]: 'krw',
    };

    return titleMap[environment.MARKET] ?? 'usd';
  };

  const defineSiteLanguage = (): SiteLanguages => {
    const titleMap: Record<Markets, SiteLanguages> = {
      [Markets.NA]: 'en',
      [Markets.INDIA]: 'in',
      [Markets.THAILAND]: 'th',
      [Markets.KOREA]: 'kr',
    };

    return titleMap[environment.MARKET] ?? 'en';
  };

  // This is a retryer to send data to Tealium because utag can take a while to be loaded on the application
  const withUtag = async () => {
    if (window.utag) return window.utag;
    let i = 0;
    while (i < 50) {
      await new Promise(resolve => setTimeout(resolve, 200));
      if (window.utag) return window.utag;
      i = i + 1;
    }
    return { link: () => {}, view: () => {} };
  };

  useEffect(() => {
    withUtag().then(utag =>
      utag.view({
        page_category,
        page_name,
        page_section,
        page_subCategory,
        site_country: defineSiteCountry(),
        site_currencyCode: defineSiteCurrency(),
        site_language: defineSiteLanguage(),
        site_prefix: 'amway',
        site_region: 'corporate',
        site_subRegion: 'global',
        site_type: 'responsive',
        site_type_short: 'web',
        site_webProperty: 'admin ACDP-RT',
        site_webProperty_mod: `admin-${environment.ENV.toLowerCase()}.acdp-rt-na.amway.net | admin ACDP-RT`,
        visitor_aiu: user?.id ?? '',
        visitor_userProfile: user?.userGroup ?? '',
      }),
    );

    // Clean up the Tealium tag on component unmount
    return () => {};
  }, [
    event_name,
    page_category,
    page_name,
    page_section,
    page_subCategory,
    search_category,
    search_keyword,
    search_results,
    search_type,
    user?.id,
    user?.userGroup,
  ]);

  return <>{children}</>;
};

export default TealiumDataLayer;
