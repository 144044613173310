import { Typography } from '@amway/react-components';
import { mdiDownloadOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useCallback, useMemo, useState } from 'react';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Page } from '../../@types/page';
import ReportsSortedBarChartComponent from '../../components/charts/reports-sorted-bar-chart';
import TealiumDataLayer from '../../components/hocs/tealium-data-layer';
import PaginatedTableComponent, { Column } from '../../components/ui/paginated-table';
import TableWarningComponent from '../../components/ui/paginated-table/table-warning';
import { CBRReportsResponse, GenerateReportsRequest, ReportsSortedBarChartData } from '../../interface/reports';
import AdminService from '../../service/admin.service';
import { addDays, subtractDays } from '../../utils/date-utils';
import { downloadCSV } from '../../utils/file-download-utils';
import './index.scss';
import ReportsFormComponent from './reports-form';
import { formatDateTime } from './utils/formatDateTime';
import { toCSV } from './utils/jsonToCsv';

const ReportsComponent: React.FC = () => {
  const [executionReports, setExecutionReports] = useState<ReportsSortedBarChartData[]>();
  const [cbrReports, setCbrReports] = useState<CBRReportsResponse>();
  const [startDate, setStartDate] = useState<Date>(subtractDays(new Date(), 2));
  const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 1));
  const [loading, setLoading] = useState<boolean>(false);
  const [cbrFilter, setCBRFilter] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<number>(0);

  const cbrHeaders = ['Processor Name', 'ABO Number', 'Months Non-Compliant', 'CBR Duration', 'Start Date'];
  const cbrRows = useMemo(() => {
    return (cbrReports?.list?.sort((a, b) => (new Date(a.startDate) > new Date(b.startDate) ? -1 : 1)) ?? []).map(
      cbr => [
        cbr.processorName ? String(cbr.processorName) : '-',
        cbr.aboNumber ? String(cbr.aboNumber) : '-',
        cbr.monthsNonCompliant ? String(cbr.monthsNonCompliant) : '-',
        cbr.cbrDuration ? String(cbr.cbrDuration) : '-',
        cbr.startDate ? formatDateTime(new Date(cbr?.startDate)) : '-',
      ],
    );
  }, [cbrReports]);

  const [columns] = useState<Column[]>(
    (
      [
        {
          id: 'processorName',
          label: 'Processor Name',
        },
        {
          id: 'aboNumber',
          label: 'ABO Number',
        },
        {
          id: 'monthsNonCompliant',
          label: 'Months Non-Compliant',
        },
        {
          id: 'cbrDuration',
          label: 'CBR Duration',
        },
        {
          id: 'startDate',
          label: 'Start Date',
          format: (value: string) => formatDateTime(new Date(value)),
        },
      ] as Column[]
    ).filter(column => column),
  );

  const loadCBRPage = useCallback(
    (page: Page) => {
      setExecutionReports([]);
      setCbrReports({ list: null, total: 0, page: { offset: 0, limit: 10 } });
      return AdminService.getCBRReports({
        startDateTime: formatDateTime(new Date(startDate), '00:00:00.000'),
        endDateTime: formatDateTime(new Date(endDate), '23:59:59.999'),
        page: page.offset,
        rowsPerPage: page.limit,
      })
        .then(setCbrReports)
        .finally(() => setLoading(false));
    },
    [endDate, startDate],
  );

  const generateReports = useCallback(
    (filters: GenerateReportsRequest, cBRFilter: boolean) => {
      setCBRFilter(cBRFilter);
      setStartDate(filters.startDate);
      setEndDate(filters.endDate);
      setLoading(true);
      if (cBRFilter) {
        setExecutionReports([]);
        AdminService.getCBRReports({
          startDateTime: formatDateTime(filters.startDate, '00:00:00.000'),
          endDateTime: formatDateTime(filters.endDate, '23:59:59.999'),
          page: selectedPage,
          rowsPerPage: 10,
        })
          .then(setCbrReports)
          .finally(() => setLoading(false));
      } else {
        AdminService.getExecutionReports(filters)
          .then(data =>
            setExecutionReports(
              data.map(metric => (metric.label === null ? { label: 'Not Specified', total: metric.total } : metric)),
            ),
          )
          .finally(() => setLoading(false));
      }
    },
    [selectedPage],
  );

  const handleDownload = () => {
    if (cbrFilter) {
      downloadCSV(
        `cbr-report-${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}.csv`,
        toCSV([cbrHeaders, ...cbrRows]),
      );
    } else {
      downloadCSV(
        `executions-report-${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}.csv`,
        toCSV([
          (executionReports ?? []).map(ex => ex.label),
          ...[(executionReports ?? []).map(ex => String(ex.total))],
        ]),
      );
    }
  };

  const renderExecutionReports = () => {
    if ((executionReports ?? []).length > 0) {
      return (
        <Row>
          <Col>
            <ReportsSortedBarChartComponent data={executionReports ?? []} id="executions-report-chart" />
          </Col>
        </Row>
      );
    } else if (cbrFilter) {
      return (
        <Row>
          <Col>
            <PaginatedTableComponent
              columns={columns}
              rows={
                cbrReports?.list !== null
                  ? cbrReports?.list?.sort((a, b) => (new Date(a.startDate) > new Date(b.startDate) ? -1 : 1))
                  : null
              }
              rowIdPropName="executionId"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              loadPage={loadCBRPage}
              totalCount={cbrReports?.total ?? cbrReports?.list?.length ?? Number.MAX_SAFE_INTEGER}
              noResultsHeader="No Executions Found"
              noResultsDescription="Check the fields in the filter and try again!"
            />
          </Col>
        </Row>
      );
    } else {
      return (
        <TableWarningComponent
          iconVariant="no-results-found"
          header="No results found"
          description="No results found for the selected time range. Please try again with different parameters."
        />
      );
    }
  };

  return (
    <TealiumDataLayer
      page_name="Reports"
      page_section="reports"
      page_category="Historic Data"
      page_subCategory="Reports on Historic Data">
      <Container className="reports">
        <Card>
          <Card.Body>
            <Row>
              <Col md={10}>
                <Typography variant="heading">Reports</Typography>
                <Typography weight="bold" color="text-gray">
                  Generate reports from different time ranges with the parameters below
                </Typography>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <ReportsFormComponent loading={loading} onSubmit={generateReports} daysRange={100} />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card style={{ display: executionReports || loading ? 'flex' : 'none' }}>
          <Card.Body>
            <Row className="mb-5">
              <Col md={10}>
                <Typography variant="heading">Executions Report</Typography>
                <Row className="justify-content-between">
                  <Col>
                    <Typography weight="bold" color="text-gray">
                      {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                    </Typography>
                  </Col>
                  <Col className="download-report">
                    <button className="download-report-btn" onClick={handleDownload}>
                      download report
                      <Icon path={mdiDownloadOutline} size="15px" color="var(--warning-blue)" />
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
            {loading ? (
              <Row className="loader">
                <Col className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="success" />
                </Col>
              </Row>
            ) : (
              renderExecutionReports()
            )}
          </Card.Body>
        </Card>
      </Container>
    </TealiumDataLayer>
  );
};

export default ReportsComponent;
