import { Markets } from '../@types/configuration';
import environment from '../config/env';
import {
  BatchReprocessExecutionRequest,
  BatchResendExecutionRequest,
  ResendExecutionRequest,
} from '../interface/processor';
import authService from '../resources/auth/auth.service';
import { RetryProcess } from '../resources/history-list/history-list-types';
import { toISODateString } from '../utils/date-utils';
import { AuthorizedService } from './authorized.service';

class ExecutorService extends AuthorizedService {
  public retryExecution(
    executionId: string,
    startDate?: string,
    endDate?: string,
    overwriteEmail?: string,
    overwritePhone?: string,
    overwriteLanguage?: string,
  ): Promise<RetryProcess> {
    const startDt = startDate ? toISODateString(startDate) + 'T00:00:00.001' : undefined;
    const endDt = endDate ? toISODateString(endDate) + 'T23:59:59.999' : undefined;

    return environment.MARKET === Markets.INDIA
      ? this.reprocess(executionId, startDt, endDt)
      : this.resend(executionId, startDt, endDt, overwriteEmail, overwritePhone, overwriteLanguage);
  }

  public batchRetryExecutions(
    executionIds: string[],
    startDate?: string,
    endDate?: string,
    overwriteEmail?: string,
    overwriteLanguage?: string,
  ): Promise<RetryProcess> {
    const startDt = startDate ? toISODateString(startDate) + 'T00:00:00.001' : undefined;
    const endDt = endDate ? toISODateString(endDate) + 'T23:59:59.999' : undefined;

    return environment.MARKET === Markets.INDIA
      ? this.batchReprocess(executionIds, startDt, endDt)
      : this.batchResend(executionIds, startDt, endDt, overwriteEmail, overwriteLanguage);
  }

  private reprocess(executionId: string, startDate?: string, endDate?: string): Promise<RetryProcess> {
    return super.post<RetryProcess, undefined>('api/retry/execution', undefined, {
      params: { executionId, startDate, endDate },
    });
  }

  private batchReprocess(executionIds: string[], startDate?: string, endDate?: string): Promise<RetryProcess> {
    return super.post<RetryProcess, BatchReprocessExecutionRequest>('api/retry/batch-execution', {
      executionIds,
      startDate,
      endDate,
    });
  }

  private resend(
    executionId: string,
    startDate?: string,
    endDate?: string,
    overwriteEmail?: string,
    overwritePhone?: string,
    overwriteLanguage?: string,
  ): Promise<RetryProcess> {
    return authService
      .getLoggedUser()
      .then(user => user?.nativeid as string)
      .then(userId =>
        super.post<RetryProcess, ResendExecutionRequest>(`api/${environment.MARKET.toLowerCase()}/resend/execution`, {
          executionId,
          startDate,
          endDate,
          overwriteEmail,
          overwritePhone,
          overwriteLanguage,
          userId,
        }),
      );
  }

  private batchResend(
    executionIds: string[],
    startDate?: string,
    endDate?: string,
    overwriteEmail?: string,
    overwriteLanguage?: string,
  ): Promise<RetryProcess> {
    return authService
      .getLoggedUser()
      .then(user => user?.nativeid as string)
      .then(userId =>
        super.post<RetryProcess, BatchResendExecutionRequest>(
          `api/${environment.MARKET.toLowerCase()}/resend/batch-execution`,
          {
            executionIds,
            startDate,
            endDate,
            overwriteEmail,
            overwriteLanguage,
            userId,
          },
        ),
      );
  }
}

export default new ExecutorService(environment.API_URL);
