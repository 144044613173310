import { Typography } from '@amway/react-components';
import { mdiPrinterOutline } from '@mdi/js';
import { useCallback, useMemo, useRef } from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import environment from '../../../../config/env';
import { TransactionalEmail } from '../../../../resources/history-list/history-list-types';
import IconButton from '../../../ui/icon-btn';
import './index.scss';

type Props = {
  show: boolean;
  onHide: VoidFunction;
  transactionalEmail?: TransactionalEmail | null;
};

export default function ExecutionEmailPreviewModalComponent({ show, onHide, transactionalEmail }: Props) {
  const htmlSnapshotRef = useRef<HTMLDivElement | null>(null);
  const htmlSnapshot = useMemo(() => transactionalEmail?.htmlSnapshot, [transactionalEmail]);
  const documentTitle = useMemo<string>(
    () =>
      `${transactionalEmail?.processorName}_${transactionalEmail?.executionId.substring(
        transactionalEmail?.executionId.length - 4,
      )}`,
    [transactionalEmail],
  );

  const handleAfterPrint = useCallback(() => {}, []);

  const handleBeforePrint = useCallback(() => {}, []);

  const handleOnBeforeGetContent = useCallback(() => {}, []);

  const reactToPrintContent = useCallback(() => {
    return htmlSnapshotRef?.current;
  }, [htmlSnapshotRef]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle,
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  return (
    <Modal className="execution-email-preview-modal" show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Col className="d-flex flex-column align-items-start">
          <Typography variant="heading">{environment.EMAIL_LABEL} Preview</Typography>
          <Typography variant="captionMedium" weight="italic">
            Subject: {transactionalEmail?.subject}
          </Typography>
          <Typography variant="captionMedium" weight="italic">
            Recipient: {transactionalEmail?.receiver}
          </Typography>
          <IconButton
            mdiIconPath={mdiPrinterOutline}
            styleType="link"
            onClick={handlePrint}
            disabled={!htmlSnapshot}
            color="white"
            size="16px">
            PRINT {environment.EMAIL_LABEL.toUpperCase()}
          </IconButton>
        </Col>
      </Modal.Header>
      <Modal.Body style={{ overflow: htmlSnapshot ? 'auto' : 'hidden' }}>
        {htmlSnapshot ? (
          <div ref={htmlSnapshotRef} dangerouslySetInnerHTML={{ __html: htmlSnapshot }} />
        ) : (
          <Row className="loader">
            <Col className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
}
