import { useCallback, useEffect, useState } from 'react';
import { ExternalAction, ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalCallSuccessData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import AllowListContext, { AllowListContextType } from './allow-list-context';
import { AllowListEntryType, AllowListRule, AllowListRuleType } from './allow-list-model';
import allowListService from './allow-list.service';

const AllowListProvider: React.FC = ({ children }) => {
  const [allowListRules, setAllowListRules] = useState<ExternalData<AllowListRule[]>>(makeExternalDataInitialData());
  const [entryTypes, setEntryTypes] = useState<ExternalData<AllowListEntryType[]>>(makeExternalDataInitialData());
  const [ruleTypes, setRuleTypes] = useState<ExternalData<AllowListRuleType[]>>(makeExternalDataInitialData());
  const [addRuleStatus, setAddRuleStatus] = useState<ExternalAction>({});
  const [updateRuleStatus, setUpdateRuleStatus] = useState<ExternalAction>({});
  const [removeRuleStatus, setRemoveRuleStatus] = useState<ExternalAction>({});

  // We gotta find a way to better handle this cleanup logic
  useEffect(() => {
    addRuleStatus.success && setAddRuleStatus({});
  }, [addRuleStatus.success]);

  // We gotta find a way to better handle this cleanup logic
  useEffect(() => {
    updateRuleStatus.success && setUpdateRuleStatus({});
  }, [updateRuleStatus.success]);

  // We gotta find a way to better handle this cleanup logic
  useEffect(() => {
    removeRuleStatus.success && setRemoveRuleStatus({});
  }, [removeRuleStatus.success]);

  const fetchAllowListRules = useCallback(async () => {
    try {
      setAllowListRules(makeExternalDataInitialData());
      const data = await allowListService.listRule();
      setAllowListRules(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setAllowListRules(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchEntryTypes = useCallback(async () => {
    try {
      setEntryTypes(makeExternalDataInitialData());
      const data = await allowListService.listEntryTypes();
      setEntryTypes(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setEntryTypes(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchRuleTypes = useCallback(async () => {
    try {
      setRuleTypes(makeExternalDataInitialData());
      const data = await allowListService.listRuleTypes();
      setRuleTypes(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setRuleTypes(makeExternalCallErrorData(err));
    }
  }, []);

  const addRule: AllowListContextType['addRule'] = useCallback(async rule => {
    try {
      setAddRuleStatus(makeExternalDataInitialData());
      await allowListService.addRule(rule);
      setAddRuleStatus(makeExternalCallSuccessData);
    } catch (err: any) {
      setAddRuleStatus(makeExternalCallErrorData(err));
    }
  }, []);

  const updateRule: AllowListContextType['updateRule'] = useCallback(async (id, rule) => {
    try {
      setUpdateRuleStatus(makeExternalDataInitialData());
      await allowListService.updateRule(id, rule);
      setUpdateRuleStatus(makeExternalCallSuccessData);
    } catch (err: any) {
      setUpdateRuleStatus(makeExternalCallErrorData(err));
    }
  }, []);

  const removeRule: AllowListContextType['removeRule'] = useCallback(async id => {
    try {
      setRemoveRuleStatus(makeExternalDataInitialData());
      await allowListService.removeRule(id);
      setRemoveRuleStatus(makeExternalCallSuccessData);
    } catch (err: any) {
      setRemoveRuleStatus(makeExternalCallErrorData(err));
    }
  }, []);

  return (
    <AllowListContext.Provider
      value={{
        allowListRules,
        entryTypes,
        ruleTypes,
        addRuleStatus,
        updateRuleStatus,
        removeRuleStatus,
        fetchAllowListRules,
        fetchEntryTypes,
        fetchRuleTypes,
        addRule,
        updateRule,
        removeRule,
      }}>
      {children}
    </AllowListContext.Provider>
  );
};

export default AllowListProvider;
