export default function NoResultsFoundIcon() {
  return (
    <svg width="122" height="120" viewBox="0 0 122 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.6"
        d="M38.0784 110.01C17.2692 110.01 0.400146 108.396 0.400146 106.406C0.400146 104.416 17.2692 102.802 38.0784 102.802C58.8871 102.802 75.7564 104.416 75.7564 106.406C75.7564 108.396 58.8871 110.01 38.0784 110.01Z"
        fill="#F0F8F4"
      />
      <g opacity="0.6">
        <path
          d="M109.159 119.711C102.19 119.711 96.541 118.657 96.541 117.356C96.541 116.056 102.19 115.001 109.159 115.001C116.129 115.001 121.778 116.056 121.778 117.356C121.778 118.657 116.129 119.711 109.159 119.711Z"
          fill="#F0F8F4"
        />
      </g>
      <path
        d="M74.6778 15.4715V98.3559C74.6778 100.313 73.8999 102.192 72.516 103.576C71.1311 104.96 69.2539 105.738 67.2954 105.738H7.60429C5.64653 105.738 3.76887 104.96 2.38447 103.576C1.00008 102.192 0.222412 100.313 0.222412 98.3559V7.73666C0.222412 5.77922 1.00008 3.90103 2.38447 2.51716C3.76887 1.13224 5.64653 0.355316 7.60429 0.355316H59.0235C59.5793 0.354271 60.1143 0.571182 60.5148 0.958084L74.1043 14.1169C74.2847 14.2931 74.4296 14.5038 74.5287 14.7363C74.6267 14.9689 74.6778 15.2192 74.6778 15.4715Z"
        fill="#F0F8F4"
      />
      <path
        d="M74.6779 15.4394H67.3904C65.312 15.4415 63.3149 14.6354 61.8184 13.1931C60.3229 11.7509 59.4459 9.78298 59.3718 7.70665L59.1143 0.355536C59.6159 0.355536 60.0977 0.549514 60.4575 0.898871L74.1283 14.1348C74.3025 14.3048 74.4412 14.5071 74.535 14.7313C74.6299 14.9556 74.6789 15.1965 74.6779 15.4394Z"
        fill="var(--secondary)"
      />
      <path
        d="M62.905 26.4077H7.7312C6.96939 26.4077 6.35181 27.025 6.35181 27.7874C6.35181 28.5486 6.96939 29.166 7.7312 29.166H62.905C63.6673 29.166 64.2847 28.5486 64.2847 27.7874C64.2847 27.025 63.6673 26.4077 62.905 26.4077Z"
        fill="var(--secondary)"
      />
      <path
        d="M53.9959 34.6848H7.85509C7.02487 34.6848 6.35181 35.3575 6.35181 36.1876C6.35181 37.0188 7.02487 37.6914 7.85509 37.6914H53.9959C54.827 37.6914 55.4997 37.0188 55.4997 36.1876C55.4997 35.3575 54.827 34.6848 53.9959 34.6848Z"
        fill="var(--secondary)"
      />
      <path
        d="M53.9959 43.2083H7.85509C7.02487 43.2083 6.35181 43.882 6.35181 44.7121C6.35181 45.5422 7.02487 46.2148 7.85509 46.2148H53.9959C54.827 46.2148 55.4997 45.5422 55.4997 44.7121C55.4997 43.882 54.827 43.2083 53.9959 43.2083Z"
        fill="var(--secondary)"
      />
      <path
        d="M36.2979 51.7873H7.8553C7.02487 51.7873 6.35181 52.461 6.35181 53.2911C6.35181 54.1212 7.02487 54.7949 7.8553 54.7949H36.2979C37.1278 54.7949 37.8011 54.1212 37.8011 53.2911C37.8011 52.461 37.1278 51.7873 36.2979 51.7873Z"
        fill="var(--secondary)"
      />
      <path
        d="M36.2979 60.3138H7.8553C7.02487 60.3138 6.35181 60.9864 6.35181 61.8176C6.35181 62.6477 7.02487 63.3203 7.8553 63.3203H36.2979C37.1278 63.3203 37.8011 62.6477 37.8011 61.8176C37.8011 60.9864 37.1278 60.3138 36.2979 60.3138Z"
        fill="var(--secondary)"
      />
      <path
        d="M36.2979 68.8372H7.8553C7.02487 68.8372 6.35181 69.5098 6.35181 70.3399C6.35181 71.1711 7.02487 71.8438 7.8553 71.8438H36.2979C37.1278 71.8438 37.8011 71.1711 37.8011 70.3399C37.8011 69.5098 37.1278 68.8372 36.2979 68.8372Z"
        fill="var(--secondary)"
      />
      <path
        d="M62.905 76.7221H7.7312C6.96939 76.7221 6.35181 77.3395 6.35181 78.1008C6.35181 78.8631 6.96939 79.4805 7.7312 79.4805H62.905C63.6673 79.4805 64.2847 78.8631 64.2847 78.1008C64.2847 77.3395 63.6673 76.7221 62.905 76.7221Z"
        fill="var(--secondary)"
      />
      <path
        d="M53.9959 84.9993H7.85509C7.02487 84.9993 6.35181 85.6719 6.35181 86.5021C6.35181 87.3322 7.02487 88.0059 7.85509 88.0059H53.9959C54.827 88.0059 55.4997 87.3322 55.4997 86.5021C55.4997 85.6719 54.827 84.9993 53.9959 84.9993Z"
        fill="var(--secondary)"
      />
      <path
        d="M65.2681 93.5227H8.21081C7.18411 93.5227 6.35181 94.1964 6.35181 95.0265C6.35181 95.8567 7.18411 96.5293 8.21081 96.5293H65.2681C66.2953 96.5293 67.1275 95.8567 67.1275 95.0265C67.1275 94.1964 66.2953 93.5227 65.2681 93.5227Z"
        fill="var(--secondary)"
      />
      <path d="M84.8035 85.9399L81.627 89.1164L89.1188 96.6094L92.2964 93.4328L84.8035 85.9399Z" fill="#F0F8F4" />
      <path
        d="M62.1151 97.043C79.8092 97.043 94.1548 82.6984 94.1548 65.0032C94.1548 47.308 79.8092 32.9624 62.1151 32.9624C44.4198 32.9624 30.0745 47.308 30.0745 65.0032C30.0745 82.6984 44.4198 97.043 62.1151 97.043Z"
        fill="var(--secondary)"
      />
      <path
        d="M62.1138 92.1777C77.1216 92.1777 89.2876 80.0117 89.2876 65.005C89.2876 49.9972 77.1216 37.8323 62.1138 37.8323C47.107 37.8323 34.9412 49.9972 34.9412 65.005C34.9412 80.0117 47.107 92.1777 62.1138 92.1777Z"
        fill="#F0F8F4"
      />
      <path
        d="M89.2657 93.2686L89.2646 93.2697C87.571 94.9621 87.571 97.7081 89.2646 99.4017L106.097 116.234C107.791 117.927 110.536 117.927 112.228 116.234L112.231 116.233C113.923 114.54 113.923 111.794 112.231 110.101L95.3978 93.2686C93.7042 91.575 90.9583 91.575 89.2657 93.2686Z"
        fill="var(--secondary)"
      />
      <line
        x1="51.0337"
        y1="52.1699"
        x2="75.1395"
        y2="76.2757"
        stroke="var(--secondary)"
        stroke-width="2.58917"
        stroke-linecap="round"
      />
      <line
        x1="49.2029"
        y1="76.2454"
        x2="73.3086"
        y2="52.1396"
        stroke="var(--secondary)"
        stroke-width="2.58917"
        stroke-linecap="round"
      />
    </svg>
  );
}
