import { AxiosError } from 'axios';
import { ExternalAction, ExternalCall, ExternalData } from '../@types/external-api';

export const makeExternalDataInitialData = (): ExternalCall => {
  return { loading: true };
};

export const makeExternalCallSuccessData = (): ExternalAction => ({
  success: true,
  loading: false,
});

export const makeExternalCallErrorData = (error: Error | AxiosError): ExternalCall => {
  return {
    loading: false,
    error: { message: error.message, statusCode: (error as any).response?.status },
  };
};

export const makeExternalDataInitialKeepData = <T>(
  prev: ExternalData<T>,
  abort?: ExternalData<T>['abort'],
): ExternalData<T> => ({
  ...prev,
  abort,
  loading: true,
});

export const makeExternalDataSuccessData = <T>(data: T): ExternalData<T> => ({
  data,
  loading: false,
});
