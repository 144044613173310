import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { Processor } from './processors-model';

export interface ProcessorsCtxType {
  processorsWithEmail: String[];
  processors: ExternalData<Processor[]>;
  processorSteps: ExternalData<String[]>;
  fetchProcessors: VoidFunction;
  fetchProcessorSteps: (processorId: number) => Promise<void>;
}

const ProcessorsContext = createContext<ProcessorsCtxType>(null as any);

export default ProcessorsContext;
