export enum Permission {
  // Permissions that has a set of features based on the market
  NaFeatureFlag = 'NaFeatureFlag',
  IndiaFeatureFlag = 'IndiaFeatureFlag',
  ThailandFeatureFlag = 'ThailandFeatureFlag',
  // Permissions to allow the set of features based on the user role
  ManageOwnPermissions = 'ManageOwnPermissions',
  BasicAccess = 'BasicAccess',
  ExceptionHistory = 'ExceptionHistory',
  ReprocessEmail = 'ReprocessEmail',
  ExecutionDetails = 'ExecutionDetails',
  CompareEmails = 'CompareEmails',
  TestingTool = 'TestingTool',
  Tester = 'Tester',
}

export type FeaturesMap = Record<string, string[]>;

export interface Authorization {
  permissions: Permission[];
  features: string[];
}

export interface ClaimPermissions {
  name: string;
  permissions: Permission[];
}
