import { Typography } from '@amway/react-components';
import { Col, Container, Row } from 'react-bootstrap';
import './index.scss';

export interface MetricsTile {
  id: string;
  title: string;
  formattedData?: string | null;
  variant?: 'warning' | 'normal';
}

type Props = MetricsTile;

export default function MetricsTileComponent({ id, title, formattedData, variant = 'normal' }: Props) {
  return (
    <Container id={id} className={`metrics-tile`}>
      <Row className="title">
        <Col>
          <Typography>{title}</Typography>
        </Col>
      </Row>
      <Row className="formatted-data">
        <Col>
          <Typography variant="heading" color={variant === 'warning' ? 'warning-error' : undefined}>
            {formattedData ?? '-'}
          </Typography>
        </Col>
      </Row>
    </Container>
  );
}
