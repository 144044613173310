import { useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomChannelMessageHistoryDetailsCardComponent from '../../../components/shared/execution/custom-channel/history-details-card';
import useMessages from '../../../resources/messages/messages-hook';

function CustomChannelMessageHistoryDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { messageLogDetails, fetchMessagesDetails } = useMessages();
  const wannaBeMessageLog = useMemo(
    () => ({
      id: searchParams.get('id') ?? undefined,
      startDt: searchParams.get('startDt') ?? undefined,
      endDt: searchParams.get('endDt') ?? undefined,
    }),
    [searchParams],
  );

  useEffect(() => {
    fetchMessagesDetails(wannaBeMessageLog);
  }, [fetchMessagesDetails, wannaBeMessageLog]);

  const handleGoBack = () => {
    if (location.key) {
      navigate(-1);
    } else {
      navigate('/custom-channel-message-history');
    }
  };

  return (
    <Container>
      <CustomChannelMessageHistoryDetailsCardComponent
        message={wannaBeMessageLog}
        details={messageLogDetails}
        onBackClick={handleGoBack}
      />
    </Container>
  );
}

export default CustomChannelMessageHistoryDetails;
