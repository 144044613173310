export enum Environments {
  DEV = 'DEV',
  QA = 'QA',
  PROD = 'PROD',
}

export enum Applications {
  INDIA = 'INDIA',
  NA = 'NA',
  COMMSHUB = 'COMMSHUB',
}

export enum Markets {
  NA = 'NA',
  INDIA = 'INDIA',
  THAILAND = 'THAILAND',
  KOREA = 'KOREA',
}
