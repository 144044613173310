import { createContext } from 'react';
import { ExecutionDetails, ProcessorLog } from './history-list-types';

export interface HistoryListContextType {
  selectedExecution?: ProcessorLog | null;
  details?: ExecutionDetails | null;
  handleBackClick: VoidFunction;
  handleDetailsClick: (execution: ProcessorLog) => void;
  togglePreviewEmailModal: (execution?: ProcessorLog) => void;
  handleRetry: (execution: ProcessorLog) => void;
  handleBatchRetry: (executions: ProcessorLog[]) => void;
}

const HistoryListContext = createContext<HistoryListContextType>(null!);

export default HistoryListContext;
