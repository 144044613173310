export default function SearchErrorIcon() {
  return (
    <svg width="120" height="121" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60.0002 2.85583C44.7504 2.85583 30.1253 8.91377 19.3421 19.697C8.55891 30.4802 2.50098 45.1053 2.50098 60.355C2.50098 75.6048 8.55891 90.2299 19.3421 101.013C30.1253 111.796 44.7504 117.854 60.0002 117.854C75.2499 117.854 89.8751 111.796 100.658 101.013C111.441 90.2299 117.499 75.6048 117.499 60.355C117.499 45.1053 111.441 30.4802 100.658 19.697C89.8751 8.91377 75.2499 2.85583 60.0002 2.85583Z"
        fill="var(--secondary)"
      />
      <path
        d="M60.0002 13.5549C53.8543 13.5549 47.7687 14.7654 42.0906 17.1174C36.4126 19.4693 31.2534 22.9166 26.9076 27.2623C22.5618 31.6081 19.1146 36.7673 16.7626 42.4453C14.4107 48.1234 13.2002 54.2091 13.2002 60.3549C13.2002 66.5008 14.4107 72.5865 16.7626 78.2645C19.1146 83.9426 22.5618 89.1017 26.9076 93.4475C31.2534 97.7933 36.4126 101.241 42.0906 103.592C47.7687 105.944 53.8543 107.155 60.0002 107.155C66.1461 107.155 72.2317 105.944 77.9098 103.592C83.5878 101.241 88.747 97.7933 93.0928 93.4475C97.4386 89.1017 100.886 83.9426 103.238 78.2645C105.59 72.5865 106.8 66.5008 106.8 60.3549C106.8 54.2091 105.59 48.1234 103.238 42.4453C100.886 36.7673 97.4386 31.6081 93.0928 27.2623C88.747 22.9166 83.5878 19.4693 77.9098 17.1174C72.2317 14.7654 66.1461 13.5549 60.0002 13.5549Z"
        fill="#F5F9FF"
      />
      <line
        x1="45.0314"
        y1="47.355"
        x2="76.91"
        y2="79.2336"
        stroke="var(--secondary)"
        stroke-width="7.11543"
        stroke-linecap="round"
      />
      <line
        x1="42.8462"
        y1="79.3236"
        x2="74.7248"
        y2="47.445"
        stroke="var(--secondary)"
        stroke-width="7.11543"
        stroke-linecap="round"
      />
    </svg>
  );
}
