import { apiWrapper } from '../../config/api-wrapper';
import { SystemHealthStatus } from './systems-health-model';

const mapSelfSystemStatusToStandard = (apiData: any): boolean => {
  return apiData.status === 'UP';
};

const mapStandardSystemStatus = (apiData: any): SystemHealthStatus => {
  return apiData ? SystemHealthStatus.OK : SystemHealthStatus.DOWN;
};

const handleSystemStatusPromise = async <T>(systemStatusPromise: Promise<T>): Promise<SystemHealthStatus> => {
  try {
    return mapStandardSystemStatus(await systemStatusPromise);
  } catch {
    return SystemHealthStatus.DOWN;
  }
};

class SystemHealthService {
  public getSelfStatus(): Promise<SystemHealthStatus> {
    return handleSystemStatusPromise(
      apiWrapper.api.get('/actuator/health').then(res => mapSelfSystemStatusToStandard(res.data)),
    );
  }

  public getAmwayProfileStatus(): Promise<SystemHealthStatus> {
    return handleSystemStatusPromise(apiWrapper.api.get('api/test/profile'));
  }

  public getUplinesStatus(): Promise<SystemHealthStatus> {
    return handleSystemStatusPromise(apiWrapper.api.get('api/test/uplines'));
  }

  public getDocgenStatus(): Promise<SystemHealthStatus> {
    return handleSystemStatusPromise(apiWrapper.api.get('api/test/docgen'));
  }

  public getOrderApiStatus(): Promise<SystemHealthStatus> {
    return handleSystemStatusPromise(apiWrapper.api.get('api/health/order-api'));
  }

  public getItemApiStatus(): Promise<SystemHealthStatus> {
    return handleSystemStatusPromise(apiWrapper.api.get('api/health/item-api'));
  }

  public getVolumeApiStatus(): Promise<SystemHealthStatus> {
    return handleSystemStatusPromise(apiWrapper.api.get('api/health/volume-api'));
  }

  public getExponeaStatus(): Promise<SystemHealthStatus> {
    return handleSystemStatusPromise(apiWrapper.adminApi.get('health/exponea-health'));
  }

  public getPublishAPIStatus(): Promise<SystemHealthStatus> {
    return handleSystemStatusPromise(apiWrapper.api.get('admin/actuator/health/liveness'));
  }
}

export default new SystemHealthService();
