import { apiWrapper } from '../../config/api-wrapper';
import { allowListRulesMapper, entryTypesMapper, ruleTypesMapper } from './allow-list-mapper';
import { AllowListEntryType, AllowListRule, AllowListRuleType } from './allow-list-model';

class AllowListService {
  public listEntryTypes(): Promise<AllowListEntryType[]> {
    return apiWrapper.adminApi.get('/allow-list-rule/entry-types').then(res => entryTypesMapper(res.data));
  }

  public listRuleTypes(): Promise<AllowListRuleType[]> {
    return apiWrapper.adminApi.get('/allow-list-rule/rule-types').then(res => ruleTypesMapper(res.data));
  }

  public listRule(): Promise<AllowListRule[]> {
    return apiWrapper.adminApi.get('/allow-list-rule').then(res => allowListRulesMapper(res.data));
  }

  public addRule(rule: AllowListRule): Promise<void> {
    return apiWrapper.adminApi.post('/allow-list-rule', rule);
  }

  public updateRule(ruleId: number, rule: AllowListRule): Promise<void> {
    return apiWrapper.adminApi.put(`/allow-list-rule/${ruleId}`, rule);
  }

  public removeRule(ruleId: number): Promise<void> {
    return apiWrapper.adminApi.delete(`/allow-list-rule/${ruleId}`);
  }
}

export default new AllowListService();
