import { Typography } from '@amway/react-components';
import { mdiPowerOff } from '@mdi/js';
import { useCallback } from 'react';
import { Card, Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import IconButton from '../../components/ui/icon-btn';
import useAuth from '../../resources/auth/auth-hook';
import { resolveLocationFromState } from '../../utils/location-utils';
import './index.scss';

export default function LoginComponent() {
  const { signIn } = useAuth();
  const location = useLocation();

  const signInWithOkta = useCallback(() => {
    signIn({ originalUri: resolveLocationFromState(location) });
  }, [location, signIn]);

  return (
    <div className="login">
      <Image src="/assets/amway-login-logo.svg" width="149" height="167" />
      <Typography className="mt-3" color="text-black" variant="display">
        Customer Data Platform
      </Typography>
      <Card className="mt-5 card">
        <IconButton
          mdiIconPath={mdiPowerOff}
          color="#FFFFFF"
          size="40px"
          className="okta-login-btn"
          onClick={signInWithOkta}>
          Sign in with Okta
        </IconButton>
      </Card>
    </div>
  );
}
