import { Typography } from '@amway/react-components';
import Icon from '@mdi/react';
import InfoIcon from '@mui/icons-material/Info';
import { TextField, Tooltip } from '@mui/material';
import { useMemo, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import environment from '../../../../../config/env';
import { Features } from '../../../../../config/features';
import { WithFeaturesProxy } from '../../../../../helpers/with-features-proxy';
import { CustomChannelMessageStatus } from '../../../../../resources/custom-channel/custom-channel-history-list-types';
import { MessageLogDetails } from '../../../../../resources/messages/messages-types';
import {
  customChannelTimestampToISO,
  getCustomChanelStatusIconPath,
  getCustomChannelStatusBgColor,
} from '../../../../../utils/execution-utils';
import JsonViewer from '../../../../ui/json-viewer';
import './index.scss';

interface ExposedProps {
  details?: MessageLogDetails | null;
  statusColor: string;
}

interface Props extends ExposedProps {
  canCompareEmails: boolean;
  hasEmailPreview: boolean;
  showUserId: boolean;
}

const createRtExecutionDetailsUrl = (details?: MessageLogDetails | null): string => {
  return (
    environment.ADMIN_URL +
    '/execution-history/details?executionId=' +
    details?.executionId +
    '&processorName=' +
    details?.campaignName +
    '&startDt=' +
    encodeURIComponent(customChannelTimestampToISO(details?.startDate!)) +
    '&endDt=' +
    encodeURIComponent(customChannelTimestampToISO(details?.endDate!)) +
    '&referrer=CUSTOM_CHANNEL_HISTORY'
  );
};

const createErrorTooltipText = (details?: MessageLogDetails | null): string => {
  return details?.errorCode + ': ' + details?.errorMsg;
};

const CustomChannelMessageDetailsInfoComponent: React.FC<Props> = props => {
  const { details, statusColor } = props;
  const target = useRef(null);

  const bgStatusColor = useMemo(
    () => getCustomChannelStatusBgColor(details?.status as CustomChannelMessageStatus),
    [details],
  );

  const statusIconPath = useMemo(
    () => getCustomChanelStatusIconPath(details?.status as CustomChannelMessageStatus),
    [details],
  );

  return (
    <>
      <Row className="message-details-info status">
        <Col
          className="d-flex align-items-center"
          style={{ borderLeftColor: statusColor, color: statusColor, backgroundColor: bgStatusColor }}>
          <Icon
            path={statusIconPath!}
            title="Status"
            size="16px"
            horizontal
            vertical
            rotate={180}
            color={statusColor}
          />
          {details?.status}
        </Col>
        {details?.executionId && (
          <Col
            className="d-flex align-items-center rt-link"
            style={{ borderLeftColor: statusColor, color: statusColor, backgroundColor: bgStatusColor }}>
            <a href={createRtExecutionDetailsUrl(details)} target="_blank" rel="noreferrer">
              View RT Execution Details
            </a>
          </Col>
        )}
      </Row>
      <Row className="message-details-info mb-5 mt-3">
        <Col md={6} className="payload">
          <Typography color="text-gray" className="d-flex mb-3">
            Payload:
          </Typography>
          <Col className="payload-output" ref={target}>
            <JsonViewer className="json-viewer" json={details?.payload ? details?.payload : '{}'} />
          </Col>
        </Col>
        <Col md={6} className="message-details">
          <Row>
            <Col className="message-details-header">
              <Typography color="text-gray" className="d-flex mb-3">
                Message Details:
              </Typography>
              {details?.errorMsg && (
                <Tooltip title={createErrorTooltipText(details)}>
                  <InfoIcon
                    style={{ color: 'var(--warning-orange)' }}
                    className="error-icon"
                    fontSize="small"></InfoIcon>
                </Tooltip>
              )}
            </Col>
          </Row>
          <Col className="message-details-col" ref={target}>
            <TextField
              className="message-details-display"
              sx={{
                display: 'flex',
                '& .MuiInputBase-input.Mui-disabled': { WebkitTextFillColor: 'black' },
              }}
              maxRows={11.6}
              minRows={11.6}
              value={details?.msg}
              placeholder="No message details to display"
              multiline
              disabled
            />
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default WithFeaturesProxy<ExposedProps>(
  Features.CompareEmail,
  Features.MktFeature_EmailPreview,
  Features.MktFeature_ShowUserIdOnExecutionDetails,
)((props, canCompare, emailPreview, showUserId) => {
  return (
    <CustomChannelMessageDetailsInfoComponent
      {...props}
      canCompareEmails={canCompare}
      hasEmailPreview={emailPreview}
      showUserId={showUserId}
    />
  );
});
