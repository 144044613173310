import { Typography } from '@amway/react-components';
import { useCallback, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Page } from '../../@types/page';
import TealiumDataLayer from '../../components/hocs/tealium-data-layer';
import ExecutionHistoryDetailsCardComponent from '../../components/shared/execution/history-details-card';
import ExecutionHistoryFormComponent from '../../components/shared/execution/history-form';
import { isPROD } from '../../config/env';
import { Features } from '../../config/features';
import useExecutions from '../../resources/executions/executions-hook';
import { ExecutionRequest } from '../../resources/executions/executions-types';
import useFeatures from '../../resources/features/features-hook';
import useHistoryList from '../../resources/history-list/history-list-hook';
import { ExecutionStatus } from '../../resources/history-list/history-list-types';
import ExceptionHistoryListComponent from './exception-history-list';
import './index.scss';

export default function ExceptionHistoryComponent() {
  const { selectedExecution, details, handleBackClick, handleDetailsClick, handleRetry, handleBatchRetry } =
    useHistoryList();
  const { fetchExceptions, exceptions } = useExecutions();
  const { hasFeature } = useFeatures();
  const [request, setRequest] = useState<ExecutionRequest>();
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [timedout, setTimedout] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleSubmit = useCallback(
    (executionRequest: ExecutionRequest) => {
      setTimedout(false);
      setError(false);

      const hasPage = executionRequest.page !== undefined && executionRequest.page !== null;
      executionRequest.showExceptionsOnly = true;
      executionRequest.marketHasCustomChannel = hasFeature(Features.MktFeature_CustomChannelScreens);

      if (!hasPage) {
        executionRequest.page = {
          limit: request?.page?.limit ?? 10,
          offset: 0,
        };

        setSelectedPage(0);
      }

      if (!executionRequest.status) {
        executionRequest.status = [ExecutionStatus.ERROR, ExecutionStatus.FATAL, ExecutionStatus.PROCESSING];
      }

      setRequest(executionRequest);

      fetchExceptions(executionRequest);
    },
    [fetchExceptions, request?.page?.limit, hasFeature],
  );

  const loadPage = useCallback(
    (page: Page) => {
      return handleSubmit({ ...request, page });
    },
    [request, handleSubmit],
  );

  useEffect(() => {
    handleBackClick();
  }, [handleBackClick]);

  return (
    <TealiumDataLayer
      page_name="Exception History"
      page_section="exception-hist"
      page_category="Historic Data"
      page_subCategory="Exception Historic Data">
      <Container className="exception-history">
        <Card style={{ display: selectedExecution ? 'none' : 'flex' }}>
          <Card.Body>
            <Row className="title">
              <Col>
                <Typography variant="heading">Exception History</Typography>
                <Typography weight="bold" color="text-gray" className="mt-3">
                  Search and reprocess the exceptions on the list below.
                </Typography>
              </Col>
            </Row>
            <ExecutionHistoryFormComponent
              onSubmit={handleSubmit}
              submitOnLoad={true}
              availableStatus={[ExecutionStatus.ERROR, ExecutionStatus.FATAL, ExecutionStatus.PROCESSING]}
              daysRange={isPROD ? undefined : 100}
              daysAgo={1}
              daysLater={0}
              displayExcludeReprocessedEvents={true}
            />
            <ExceptionHistoryListComponent
              loadPage={loadPage}
              timedout={timedout}
              setTimedout={setTimedout}
              error={error}
              setError={setError}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              onDetailsClick={handleDetailsClick}
              onRetryClick={handleRetry}
              onBatchRetryClick={handleBatchRetry}
            />
            {exceptions.data?.db !== undefined && (
              <Row className="mt-3">
                <Col>
                  <Card.Subtitle>
                    Request done on DB: <b>{exceptions.data.db}</b>
                  </Card.Subtitle>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
        {selectedExecution && (
          <ExecutionHistoryDetailsCardComponent
            execution={selectedExecution}
            details={details}
            onBackClick={handleBackClick}
          />
        )}
      </Container>
    </TealiumDataLayer>
  );
}
