import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { Metrics } from './metrics-model';

export interface MetricsCtxType {
  metrics: ExternalData<Metrics>;
  allCountries: ExternalData<String[]>;
  fetchAllCountries: () => Promise<void>;
  fetchMetrics: (startDate: string, endDate: string) => void;
}

const MetricsContext = createContext<MetricsCtxType>(null as any);

export default MetricsContext;
