import { createContext } from 'react';
import { ExternalAction, ExternalData } from '../../@types/external-api';
import { AllowListEntryType, AllowListRule, AllowListRuleType } from './allow-list-model';

export interface AllowListContextType {
  allowListRules: ExternalData<AllowListRule[]>;
  entryTypes: ExternalData<AllowListEntryType[]>;
  ruleTypes: ExternalData<AllowListRuleType[]>;
  addRuleStatus: ExternalAction;
  updateRuleStatus: ExternalAction;
  removeRuleStatus: ExternalAction;
  fetchAllowListRules: VoidFunction;
  fetchEntryTypes: VoidFunction;
  fetchRuleTypes: VoidFunction;
  addRule: (rule: AllowListRule) => void;
  updateRule: (ruleId: number, rule: AllowListRule) => void;
  removeRule: (ruleId: number) => void;
}

const AllowListContext = createContext<AllowListContextType>(null as any);

export default AllowListContext;
