import { Typography } from '@amway/react-components';
import { FormControlLabel } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import environment from '../../../../config/env';
import { ProcessorLog } from '../../../../resources/history-list/history-list-types';
import { toLocaleString } from '../../../../utils/date-utils';
import { shortenUUID } from '../../../../utils/execution-utils';
import DialogLayout from '../../../core/dialog-layout';
import { Item } from '../../../ui/dropdown-btn';
import Switch from '../../../ui/switch';
import LanguageDropdownComponent from '../../language-dropdown';

export interface EmailResendFormFields {
  overwriteEmail?: string;
  overwritePhone?: string;
  overwriteLanguage?: string;
}

type Props = {
  execution?: ProcessorLog | null;
  show: boolean;
  close: VoidFunction;
  onHide: VoidFunction;
  onCancel: VoidFunction;
  onConfirm: (request: EmailResendFormFields) => void;
};

export default function ResendEmailModalComponent({ execution, show, close, onHide, onCancel, onConfirm }: Props) {
  const [email, setEmail] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState<Item>();
  const [phone, setPhone] = useState<string>('');
  const [override, setOverride] = useState<boolean>(false);

  const shortUUID = useMemo(() => shortenUUID(execution?.sharedId ?? execution?.executionId), [execution]);

  const handlePhoneChange = (value: string) => {
    setPhone(value);
  };

  const endDate = useMemo(() => {
    const endDt = execution?.endDt;

    return endDt ? toLocaleString(endDt) : '';
  }, [execution]);

  const handleConfirm = useCallback(() => {
    const request: EmailResendFormFields = {
      overwriteEmail: override ? email : undefined,
      overwritePhone: override ? phone.replace('+', '') : undefined,
      overwriteLanguage: override ? (selectedLanguage?.id as string | undefined) : undefined,
    };

    onConfirm(request);
    close();
  }, [email, phone, selectedLanguage, override, close, onConfirm]);

  const handleToggleOverride = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setOverride(event.target.checked);
  }, []);
  return (
    <DialogLayout
      open={show}
      title={`Resend ${environment.EMAIL_LABEL}`}
      subtitle={`You are resending the ${environment.EMAIL_LABEL.toLowerCase()} with the parameters below:`}
      confirmActionText="Apply"
      confirmActionHandler={handleConfirm}
      scapeActionText="Cancel"
      scapeActionHandler={onCancel}>
      <Card.Body className="resend-email-modal">
        <Row className="description">
          <Col md={12}>
            <Typography color="text-gray" className="execution">
              {execution?.sharedId ? 'Shared' : 'Execution'} ID: <Typography weight="bold">{shortUUID}</Typography>
            </Typography>
          </Col>
          <Col md={12}>
            <Typography color="text-gray" className="execution">
              Processor: <Typography weight="bold">{execution?.processorName}</Typography>
            </Typography>
          </Col>
          <Col md={12}>
            <Typography color="text-gray" className="execution">
              Date: <Typography weight="bold">{endDate} (GMT)</Typography>
            </Typography>
          </Col>
        </Row>
        <Form className="override-form" onSubmit={handleConfirm}>
          <Row className="fields">
            <Col md={12}>
              <FormControlLabel
                value="false"
                control={<Switch checked={override} onChange={handleToggleOverride} />}
                label="Send to a different email or phone"
                labelPlacement="end"
              />
            </Col>
            {override && (
              <>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label htmlFor="email-ctrl">Email</Form.Label>
                    <Form.Control
                      id="email-ctrl"
                      placeholder="Type the email to override"
                      type="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label htmlFor="phone-ctrl">Phone</Form.Label>
                    <PhoneInput
                      id="phone-ctrl"
                      placeholder="Type the phone number"
                      type="phone"
                      value={phone}
                      onChange={handlePhoneChange}
                      defaultCountry="US"
                      inputComponent={Form.Control}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <LanguageDropdownComponent onClick={setSelectedLanguage} />
                </Col>
              </>
            )}
          </Row>
        </Form>
      </Card.Body>
    </DialogLayout>
  );
}
