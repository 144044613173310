import { mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Page } from '../../../@types/page';
import IconButton from '../../../components/ui/icon-btn';
import PaginatedTableComponent, { Column, SelectedRowsDict } from '../../../components/ui/paginated-table';
import { timezoneName } from '../../../config/env';
import { Features } from '../../../config/features';
import { WithFeaturesProxy } from '../../../helpers/with-features-proxy';
import { CustomChannelMessageStatus } from '../../../resources/custom-channel/custom-channel-history-list-types';
import { MessageLog, MessagesResponse } from '../../../resources/messages/messages-types';
import {
  customChannelTimestampToCorrectTimezone,
  getCustomChanelStatusIconPath,
  getCustomChannelStatusColor,
  shortenUUID,
} from '../../../utils/execution-utils';

interface ExposedProps {
  response?: MessagesResponse | null;
  selectedPage: number;
  setSelectedPage: (page: number) => void;
  limit?: number;
  timedout: boolean;
  error: boolean;
  loadPage: (page: Page) => Promise<MessagesResponse | void>;
  onDetailsClick: (execution: MessageLog) => void;
  makeNavLink?: (execution: MessageLog) => string;
}

interface Props extends ExposedProps {
  canSeeDetails: boolean;
  mktHasEmailPreview: boolean;
  mktShowsUserId: boolean;
}

function shortenStatusName(status: CustomChannelMessageStatus): string {
  if (status.length > 14) {
    const statusParts = status.split('_');
    return statusParts[0] + '...' + statusParts[statusParts.length - 1];
  }
  return status;
}

const CustomChannelMessageHistoryListComponent: React.FC<Props> = props => {
  const {
    response,
    selectedPage,
    setSelectedPage,
    timedout = false,
    error = false,
    limit = 10,
    loadPage,
    onDetailsClick,
    canSeeDetails,
    makeNavLink,
  } = props;
  const [selected, setSelected] = useState<SelectedRowsDict>({});
  const [columns] = useState<Column[]>(
    (
      [
        {
          id: 'status',
          label: 'Status',
          getIcon: (row: any) => {
            const status: CustomChannelMessageStatus | undefined = row.status;

            if (status) {
              const statusIconPath = getCustomChanelStatusIconPath(status);
              const statusColor = getCustomChannelStatusColor(status);

              return (
                <Icon
                  path={statusIconPath!}
                  title="Status"
                  size="16px"
                  horizontal
                  vertical
                  rotate={180}
                  color={statusColor}
                  style={{ verticalAlign: 'text-bottom', marginRight: '8px' }}
                />
              );
            }
          },
          format: (value: CustomChannelMessageStatus) => shortenStatusName(value),
        },
        {
          id: 'id',
          label: 'Custom Channel ID',
          format: (value: string) => shortenUUID(value),
        },
        {
          id: 'account_number',
          label: 'ABO Number',
        },
        {
          id: 'campaign_name',
          label: 'Campaign Name',
        },
        {
          id: 'msgType',
          label: 'Message Type',
        },
        {
          id: 'send_date',
          label: timezoneName === '' ? 'Date (GMT)' : `Date (${timezoneName})`,
          format: (value: string) => customChannelTimestampToCorrectTimezone(value),
        },
        canSeeDetails
          ? {
              id: 'details',
              maxWidth: 1.5,
              getIcon: (row: MessageLog) => {
                return makeNavLink && row.status !== 'SENDING_SMS' ? (
                  <Link to={makeNavLink(row)}>
                    <Icon path={mdiOpenInNew} color="var(--primary)" title="Message details" size="18px" />
                  </Link>
                ) : (
                  <IconButton
                    mdiIconPath={mdiOpenInNew}
                    onClick={() => onDetailsClick(row)}
                    disabled={row.status === 'SENDING_SMS'}
                    color="var(--primary)"
                    title="Message details"
                    size="18px"
                  />
                );
              },
            }
          : null,
      ] as Column[]
    ).filter(column => column),
  );

  const rows = useMemo(() => {
    if (response === undefined) {
      return undefined;
    } else if (response === null) {
      return null;
    }

    return Object.values(response.messages);
  }, [response]);

  return (
    <PaginatedTableComponent
      columns={columns}
      rows={rows}
      rowIdPropName="id"
      selectedPage={selectedPage}
      setSelectedPage={setSelectedPage}
      selectable={true}
      selected={selected}
      setSelected={setSelected}
      selectedDisplayMessage="message(s) selected"
      selectedRowsPerPage={limit}
      totalCount={response?.totalNumberOfElements ?? rows?.length ?? Number.MAX_SAFE_INTEGER}
      loadPage={loadPage}
      timedout={timedout}
      error={error}
      noResultsHeader="No Messages Found"
      noResultsDescription="Check the fields in the filter and try again!"
      sx={{ maxWidth: 'calc(100vw - 104px - 2*24px - 32px - 38px - 19px)' }}
      showAllOption={false}
    />
  );
};

export default WithFeaturesProxy<ExposedProps>(
  Features.ReprocessEmail,
  Features.ExecutionHistoryScreen_Details,
  Features.MktFeature_EmailPreview,
  Features.MktFeature_ShowUserIdOnExecutionDetails,
)((props, ...hasFeatures) => {
  const [hasDetailsFeature, mktHasEmailPreview, mktShowsUserId] = hasFeatures;

  return (
    <CustomChannelMessageHistoryListComponent
      {...props}
      canSeeDetails={hasDetailsFeature}
      mktHasEmailPreview={mktHasEmailPreview}
      mktShowsUserId={mktShowsUserId}
    />
  );
});
