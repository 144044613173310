import { Typography } from '@amway/react-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Image, Row, Spinner } from 'react-bootstrap';
import ReactDiffViewer from 'react-diff-viewer';
import { useLocation } from 'react-router-dom';
import TealiumDataLayer from '../../components/hocs/tealium-data-layer';
import { TransactionalEmail } from '../../resources/history-list/history-list-types';
import adminService from '../../service/admin.service';
import CompareEmailsFormComponent, { CompareEmailsFormFields } from './compare-emails-form';
import './index.scss';

export default function CompareEmailsComponent() {
  const { search } = useLocation();
  const [firstTransactionalEmail, setFirstTransactionalEmail] = useState<TransactionalEmail | null>();
  const [secondTransactionalEmail, setSecondTransactionalEmail] = useState<TransactionalEmail | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const getEmails = useCallback(
    ({ firstExecutionId, secondExecutionId, startDate, endDate }: CompareEmailsFormFields) => {
      setLoading(true);
      setFirstTransactionalEmail(null);
      setSecondTransactionalEmail(null);

      if (query.get('firstExecutionId') !== firstExecutionId || query.get('secondExecutionId') !== secondExecutionId) {
        query.set('firstExecutionId', firstExecutionId);
        query.set('secondExecutionId', secondExecutionId);
        query.set('startDt', String(startDate));
        query.set('endDt', String(endDate));
      }

      Promise.all([
        adminService
          .getTransactionalEmail({ executionId: firstExecutionId, startDt: startDate, endDt: endDate })
          .then(setFirstTransactionalEmail),
        adminService
          .getTransactionalEmail({ executionId: secondExecutionId, startDt: startDate, endDt: endDate })
          .then(setSecondTransactionalEmail),
      ]).finally(() => {
        setLoading(false);
      });
    },
    [query],
  );

  useEffect(() => {
    const processorName = query.get('processorName');
    const firstExecutionId = query.get('firstExecutionId');
    const secondExecutionId = query.get('secondExecutionId');
    const startDate = String(query.get('startDt'));
    const endDate = String(query.get('endDt'));

    if (processorName && firstExecutionId && secondExecutionId) {
      getEmails({ processorName, firstExecutionId, secondExecutionId, startDate, endDate });
    }
  }, [query, getEmails]);

  return (
    <TealiumDataLayer
      page_name="Compare Emails"
      page_section="compare-emails"
      page_category="Analytics"
      page_subCategory="Emails Comparer">
      <Container className="compare-emails">
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Typography variant="heading">Compare Emails</Typography>
                <Typography weight="bold" color="text-gray" className="mt-3">
                  Select the processor and the versions below.
                </Typography>
              </Col>
            </Row>
            <CompareEmailsFormComponent
              processorName={query.get('processorName')}
              firstExecutionId={query.get('firstExecutionId')}
              secondExecutionId={query.get('secondExecutionId')}
              startDt={query.get('startDt')}
              endDt={query.get('endDt')}
              onSubmit={getEmails}
            />
          </Card.Body>
        </Card>
        {loading ? (
          <Card>
            <Card.Body>
              <Row className="loader">
                <Col className="d-flex flex-column justify-content-center align-items-center">
                  <h1>Loading Comparison Results</h1>
                  <h4>Wait until the results are ready</h4>
                  <Spinner animation="border" variant="success" />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ) : (
          firstTransactionalEmail &&
          secondTransactionalEmail && (
            <>
              <Card className="comparison-results">
                <Card.Body>
                  <Row>
                    <Col>
                      <Typography variant="heading">Comparison Results</Typography>
                      <Typography weight="bold" color="text-gray" className="mt-3">
                        Compare processor versions below.
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    {[
                      { label: 'First', ...firstTransactionalEmail },
                      { label: 'Second', ...secondTransactionalEmail },
                    ].map(({ executionId, htmlSnapshot, label, subject, receiver }) => (
                      <Col key={executionId} className="email-preview">
                        <Row className="email-execution-id">
                          <Col className="d-flex align-items-center justify-content-center">
                            {label} Execution: {executionId}
                          </Col>
                        </Row>
                        <Row className="subject-recipient">
                          <Col className="d-flex flex-column align-items-start">
                            <span>Subject: {subject}</span>
                            <span>Recipient: {receiver}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col dangerouslySetInnerHTML={{ __html: htmlSnapshot! }} />
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
              <Card className="comparison-diff">
                <Card.Body>
                  <Row>
                    <Col>
                      <Typography variant="heading">Template Diff</Typography>
                      <Typography weight="bold" className="mt-3" color="text-gray">
                        Compare the e-mail HTML template.
                      </Typography>
                    </Col>
                  </Row>
                  {firstTransactionalEmail.htmlSnapshot !== secondTransactionalEmail.htmlSnapshot ? (
                    <Row className="diff">
                      <ReactDiffViewer
                        oldValue={firstTransactionalEmail.htmlSnapshot}
                        newValue={secondTransactionalEmail.htmlSnapshot}
                        leftTitle={`Execution ID: ${firstTransactionalEmail.executionId}`}
                        rightTitle={`Execution ID: ${secondTransactionalEmail.executionId}`}
                        splitView={true}
                      />
                    </Row>
                  ) : (
                    <Row className="no-diffs-found">
                      <Col className="d-flex flex-column align-items-center">
                        <Image src="/assets/no-results-found-icon.svg" width="121.56" height="119.36" />
                        <h1>Templates are identical</h1>
                        <h4>No diffs between the templates were found!</h4>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
            </>
          )
        )}
      </Container>
    </TealiumDataLayer>
  );
}
