import { createContext } from 'react';
import { ExternalData, FetchFunction, MultiFetchFunction } from '../../@types/external-api';
import {
  MessageDetailsRequest,
  MessageLogDetails,
  MessagesExceptionsRequest,
  MessagesExceptionsResponse,
  MessagesRequest,
  MessagesResponse,
} from './messages-types';

export interface MessagesContextType {
  messageLogDetails: MessageLogDetails | undefined;
  messageLogs: ExternalData<MessagesResponse>;
  messageExceptions: ExternalData<MessagesExceptionsResponse>;
  fetchMessages: MultiFetchFunction<MessagesRequest>;
  fetchMessageExceptions: FetchFunction<MessagesExceptionsRequest>;
  fetchMessagesDetails: (req: MessageDetailsRequest) => void;
  resetExecution: () => void;
}

const MessagesContext = createContext<MessagesContextType>(null as any);

export default MessagesContext;
