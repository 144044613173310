import { MouseEvent, MouseEventHandler, useCallback } from 'react';
import { Badge, CloseButton } from 'react-bootstrap';
import './index.css';

export type BadgeColorTheme = 'gray' | 'red' | 'orange' | 'green';

type Props = {
  id: string;
  label: string;
  theme: BadgeColorTheme;
  onClose: () => void;
};

export default function ClosableBadgeComponent({ id, label, theme, onClose }: Props) {
  const handleClose: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onClose();
    },
    [onClose],
  );
  return (
    <Badge id={id} className={`closable-badge ${theme}`}>
      {label}
      <CloseButton onClick={handleClose} />
    </Badge>
  );
}
