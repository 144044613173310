import { Button } from '@amway/react-components';
import { FormControlLabel } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import LanguageDropdownComponent, { availableLanguages } from '../../../components/shared/language-dropdown';
import DropdownBtn, { Item } from '../../../components/ui/dropdown-btn';
import JsonInputComponent, { JSONInputChange } from '../../../components/ui/json-input';
import Switch from '../../../components/ui/switch';
import { Features } from '../../../config/features';
import { WithFeaturesProxy } from '../../../helpers/with-features-proxy';
import useProcessors from '../../../resources/processors/processors-hook';
import './index.scss';

const countries = [
  { label: 'US', id: 'US' },
  { label: 'CA', id: 'CA' },
  { label: 'DO', id: 'DO' },
  { label: 'TH', id: 'TH' },
  { label: 'IN', id: 'IN' },
  { label: 'MX', id: 'MX' },
  { label: 'BR', id: 'BR' },
];

export interface Values {
  processor: Item;
  email: string;
  phone: string;
  language: string;
  message: any;
  country: string;
}

interface ExposedProps {
  executingTest: boolean;
  onSubmit: () => void;
  values: Values;
  onValuesChange: (newValue: Values) => void;
  prettyJsonInitialValue: any;
}

interface Props extends ExposedProps {
  mktHasLanguageSelector: boolean;
  mktHasMultipleCountry: boolean;
}

function TestingToolFormComponent(props: Props) {
  const {
    values,
    executingTest,
    prettyJsonInitialValue,
    onSubmit,
    onValuesChange,
    mktHasLanguageSelector,
    mktHasMultipleCountry,
  } = props;
  const { processors: processorsData, fetchProcessors } = useProcessors();
  const processors: Item[] = useMemo(
    () => (processorsData.data ?? []).map(({ id, name }) => ({ id, label: name })),
    [processorsData.data],
  );
  const selectedProcessor = useMemo(
    () => processors.find(p => p.label === values.processor.label),
    [processors, values.processor.label],
  );
  const selectedCountry = useMemo(() => countries.find(p => p.id === values.country), [values.country]);
  const selectedLanguage = useMemo(() => availableLanguages.find(s => s.id === values.language), [values.language]);
  const stringifiedMessage = useMemo(() => JSON.stringify(values.message), [values.message]);
  const [jsonInputEnabled, setJsonInputEnabled] = useState<boolean>(true);
  const [parsingExceptionMessage, setParsingExceptionMessage] = useState<string | null | undefined>();
  const isValid = useMemo(() => selectedProcessor && values.message, [selectedProcessor, values.message]);

  useEffect(() => {
    fetchProcessors();
  }, [fetchProcessors]);

  const handleProcessorChanges = (item?: Item) => {
    onValuesChange({ ...values, processor: item ? item : { label: '', id: 0 } });
  };

  const handleCountryChanges = (item?: Item) => {
    onValuesChange({ ...values, country: item?.id ? item?.id.toString() : '' });
  };

  const handleLanguageChanges = (item?: Item) => {
    onValuesChange({ ...values, language: item?.id ? item?.id.toString() : '' });
  };

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    onValuesChange({ ...values, email: e.target.value });
  };

  const handlePhoneChange = (value: string) => {
    onValuesChange({ ...values, phone: value });
  };

  const handleJsonInputChange = useCallback(
    (e: JSONInputChange) => {
      if (!e.error) {
        onValuesChange({ ...values, message: e.jsObject });
      }
    },
    [values, onValuesChange],
  );

  const handleTextareaChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = e.target;
      let jsObject: any | null | undefined;

      try {
        if (value) {
          jsObject = JSON.parse(value);
        }
        onValuesChange({ ...values, message: jsObject });
      } catch (err: any) {
        setParsingExceptionMessage(err.message);
      }
    },
    [onValuesChange, values],
  );

  const handleToggleJsonInputEnabled = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setJsonInputEnabled(!event.target.checked);
  }, []);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  return (
    <Form className="testing-tool-form" onSubmit={handleSubmit}>
      <Row className="mb-5">
        <Col md={6}>
          <DropdownBtn
            id="processor-dropdown"
            required
            label="Processor"
            placeholder="Select processor for testing"
            value={selectedProcessor}
            items={processors}
            onClick={handleProcessorChanges as any}
          />
        </Col>
        <Col md={mktHasMultipleCountry ? 3 : 6}>
          {mktHasLanguageSelector && (
            <LanguageDropdownComponent value={selectedLanguage} onClick={handleLanguageChanges} />
          )}
        </Col>
        {mktHasMultipleCountry && (
          <Col md={3}>
            <DropdownBtn
              id="contry-dropdown"
              label="Country"
              placeholder="Select country"
              value={selectedCountry}
              items={countries}
              onClick={handleCountryChanges as any}
            />
          </Col>
        )}
        <Col md={6}>
          <Form.Group>
            <Form.Label htmlFor="email-ctrl">Email</Form.Label>
            <Form.Control
              id="email-ctrl"
              placeholder="Type the test email"
              type="email"
              value={values.email}
              onChange={handleEmailChange}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label htmlFor="phone-ctrl">Phone</Form.Label>
            <PhoneInput
              id="phone-ctrl"
              placeholder="Type the phone number"
              type="phone"
              value={values.phone}
              onChange={handlePhoneChange}
              defaultCountry="US"
              inputComponent={Form.Control}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="message">
        <Col md={12}>
          <Form.Group className="d-flex flex-column">
            <Form.Label className="required">Message</Form.Label>
            <Form.Text muted>
              Attention! If the JSON sample has too many characters, processing may take longer than expected and the
              validation may crash. To prevent this from happening, inform this condition in the field below to change
              the tool. Refresh the page in the case it freezes.
            </Form.Text>
            <FormControlLabel
              value="false"
              control={<Switch checked={!jsonInputEnabled} onChange={handleToggleJsonInputEnabled} />}
              label="JSON sample with many characters"
              labelPlacement="end"
            />
            {jsonInputEnabled ? (
              <JsonInputComponent
                id="json-input-component"
                theme="light_mitsuketa_tribute"
                colors={{ background: 'var(--tertiary)' }}
                width="100%"
                height="550px"
                placeholder={typeof prettyJsonInitialValue === 'string' ? undefined : prettyJsonInitialValue}
                onChange={handleJsonInputChange}
              />
            ) : (
              <Form.Control
                as="textarea"
                value={stringifiedMessage}
                onChange={handleTextareaChange}
                isInvalid={!!parsingExceptionMessage}
              />
            )}
            <Form.Control.Feedback type="invalid">
              {!jsonInputEnabled ? parsingExceptionMessage : ''}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end">
          <Button loading={executingTest} disabled={!isValid}>
            EXECUTE TEST
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default WithFeaturesProxy<ExposedProps>(
  Features.MktFeature_LanguageSelectorOnTestingTool,
  Features.MktFeature_HasMultipleCountry,
)((props, ...hasFeatures) => {
  const [mktHasLanguageSelector, mktHasMultipleCountry] = hasFeatures;

  return (
    <TestingToolFormComponent
      {...props}
      mktHasLanguageSelector={mktHasLanguageSelector}
      mktHasMultipleCountry={mktHasMultipleCountry}
    />
  );
});
