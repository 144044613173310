import { Switch as MuiSwitch } from '@mui/material';
import { ChangeEvent } from 'react';
import './index.scss';

interface Props {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Switch: React.FC<Props> = props => {
  const { checked, onChange } = props;

  return <MuiSwitch color="success" checked={checked} onChange={onChange} />;
};

export default Switch;
