import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import ClosableBadgeComponent, { BadgeColorTheme } from '../closable-badge';
import { Item } from '../dropdown-btn';
import './index.scss';

type Props = {
  id: string;
  required?: boolean;
  disabled?: boolean;
  styleType?: 'default' | 'filter';
  all?: boolean;
  none?: boolean;
  allPreSelected?: boolean;
  badges?: boolean;
  channel?: boolean;
  label: string;
  placeholder: string;
  badgeTheme?: BadgeColorTheme;
  items: Item[];
  onClick: (item?: Item[]) => void;
};

export default function MultiSelectDropdownBtn({
  id,
  required,
  disabled,
  styleType = 'default',
  all,
  none,
  allPreSelected,
  badges,
  channel,
  label,
  placeholder,
  badgeTheme,
  items,
  onClick,
}: Props) {
  const allItemRef = useRef<HTMLAnchorElement>();
  const noneItemRef = useRef<HTMLAnchorElement>();
  const [loadedItemsOnce, setLoadedItemsOnce] = useState<boolean>(false);
  const [selected, setSelected] = useState<Item[]>([]); // TODO: performance optimization: use a Map<string, Item> instead.

  useEffect(() => {
    onClick(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleDelete = useCallback(
    (index: number) => {
      selected.splice(index, 1);
      const updatedSelected = [...selected];
      setSelected(updatedSelected);
    },
    [selected],
  );

  const itemIndex = useCallback(
    (item: Item) => selected.findIndex(selectedItem => selectedItem.id === item.id),
    [selected],
  );

  const handleClick = useCallback(
    (item?: Item) => {
      if (item) {
        const index = itemIndex(item);

        if (index === -1) {
          const updatedSelected = selected.concat([item]);
          setSelected(updatedSelected);
        } else {
          handleDelete(index);
        }
      } else {
        setSelected([]);
      }
    },
    [itemIndex, selected, handleDelete],
  );

  const allSelected = useMemo<boolean>(() => selected.length === items.length, [items, selected]);

  const selectedLabel = useMemo<string>(() => {
    let lbl: string = '';

    if (channel) {
      lbl = selected.map(({ label }) => label).join('/');
    } else if (all && allSelected && items.length > 1) {
      lbl = 'All';
    } else if (selected.length === 1) {
      lbl = selected[0].label;
    } else {
      lbl = selected.length + ' Selected';
    }

    return lbl;
  }, [all, allSelected, selected, channel, items]);

  const handleClickAll = useCallback(() => {
    if (!allSelected) {
      const updatedSelected = [...items];
      setSelected(updatedSelected);
    }
  }, [allSelected, items]);

  const handleClickNone = useCallback(() => {
    handleClick();
  }, [handleClick]);

  useEffect(() => {
    if (items.length === 0) {
      setSelected([]);
    } else if (items.length === 1) {
      setSelected([...items]);
    }
  }, [items]);

  useEffect(() => {
    if (items.length > 1) {
      const updatedSelected = selected.filter(({ id }) => items.findIndex(item => item.id === id) !== -1);

      if (selected.length !== updatedSelected.length || selected.some(({ id }, i) => updatedSelected[i].id !== id)) {
        setSelected(updatedSelected);
      }
    }
  }, [items, selected]);

  useEffect(() => {
    if (all && !allSelected && !loadedItemsOnce && allPreSelected && items.length) {
      handleClickAll();
      setLoadedItemsOnce(true);
    }
  }, [items, all, allPreSelected, allSelected, loadedItemsOnce, selected, handleClickAll]);

  return (
    <Form.Group id={id} className={`multi-select-dropdown ${styleType}`}>
      <Form.Label htmlFor={`${id}-toggle`} className={required ? 'required' : ''}>
        {label}
      </Form.Label>
      <Dropdown
        id={`${id}-button`}
        autoClose="outside"
        className={`${disabled ? 'disabled' : ''} ${selected.length && !badges ? 'selected' : ''}`}>
        <Dropdown.Toggle
          id={`${id}-toggle`}
          as="div"
          className={`btn btn-primary ${disabled ? 'disabled' : ''}`}
          disabled={disabled}>
          {selected.length ? (
            badges && badgeTheme ? (
              <div className="badges-container">
                {selected.map((item, i) => (
                  <ClosableBadgeComponent
                    key={item.id}
                    id={`closable-badge-${i}`}
                    label={item.label}
                    theme={badgeTheme}
                    onClose={() => handleDelete(i)}
                  />
                ))}
              </div>
            ) : (
              selectedLabel
            )
          ) : (
            placeholder
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {all && !allSelected && (
            <Dropdown.Item
              ref={allItemRef}
              eventKey="all"
              id="dropdown-item-all"
              className={`text-truncate ${allSelected ? 'active' : ''}`}
              onClick={() => handleClickAll()}>
              All
            </Dropdown.Item>
          )}
          {none && (
            <Dropdown.Item
              ref={noneItemRef}
              eventKey="none"
              id="dropdown-item-none"
              className="text-truncate"
              onClick={() => handleClickNone()}>
              None
            </Dropdown.Item>
          )}
          {items.map(item => (
            <Dropdown.Item
              key={item.id}
              eventKey={item.id}
              id={`dropdown-item-${item.id}`}
              className={`text-truncate ${itemIndex(item) !== -1 ? 'active' : ''}`}
              onClick={() => handleClick(item)}>
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  );
}
