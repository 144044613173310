import { mdiCursorDefaultClickOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useMemo } from 'react';
import ReactJson from 'react-json-view';
import CopyToClipboard from '../copy-to-clipboard';
import './index.scss';

interface Props {
  json: any;
  className?: string;
  copyTitle?: string;
  copyTransform?: (raw: any) => any;
}

const identity = <T,>(a: T): T => a;

const JsonViewer: React.FC<Props> = props => {
  const { json, className = '', copyTransform = identity, copyTitle = 'Click to copy' } = props;
  const parsedJson = useMemo(() => (typeof json === 'string' ? JSON.parse(json) : json), [json]);
  const toCopyContent = useMemo(() => JSON.stringify(copyTransform(parsedJson)), [copyTransform, parsedJson]);

  return (
    <div className="json-viewer-container-wrapper">
      <section className={`json-viewer-container fancy-scrollbar ${className}`}>
        <ReactJson
          src={parsedJson}
          theme="summerfruit:inverted"
          iconStyle="circle"
          collapsed={2}
          collapseStringsAfterLength={80}
          displayDataTypes={false}
        />
      </section>
      <CopyToClipboard
        text={toCopyContent}
        className="copy-to-clipboard"
        tooltip={{
          title: (
            <>
              {copyTitle}{' '}
              <Icon
                title="copy-to-clipboard"
                className="ms-1"
                path={mdiCursorDefaultClickOutline}
                size="16px"
                color="var(--bg-white)"
              />
            </>
          ),
        }}
      />
    </div>
  );
};

export default JsonViewer;
