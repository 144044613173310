import { mdiAlert, mdiAlertCircle, mdiAlertRhombus, mdiCheckCircle, mdiCircleHalfFull, mdiMinusCircle } from '@mdi/js';
import { timezone } from '../config/env';
import { CustomChannelMessageStatus } from '../resources/custom-channel/custom-channel-history-list-types';
import { ExecutionStatus } from '../resources/history-list/history-list-types';

export function getStatusColor(status?: ExecutionStatus): string {
  switch (status) {
    case ExecutionStatus.OK:
      return 'var(--warning-success)';
    case ExecutionStatus.ERROR:
      return 'var(--warning-orange)';
    case ExecutionStatus.FATAL:
      return 'var(--warning-error)';
    case ExecutionStatus.SKIPPED_SUPPRESSED:
      return 'var(--detail-gray)';
    case ExecutionStatus.SOFT_LAUNCH:
      return 'var(--warning-blue)';
    case ExecutionStatus.WARN:
      return '#f0bb00';
    case ExecutionStatus.PROCESSING:
      return '#4994ee';
    default:
      return 'black';
  }
}

export function getCustomChannelStatusColor(status?: CustomChannelMessageStatus): string {
  switch (status) {
    case CustomChannelMessageStatus.MESSAGE_SENT:
    case CustomChannelMessageStatus.SMS_SENT:
      return 'var(--warning-success)';
    case CustomChannelMessageStatus.SMS_NOT_SENT:
    case CustomChannelMessageStatus.FAILED_GET_MESSAGE_TEMPLATE:
    case CustomChannelMessageStatus.FAILED_REPLACE_VARIABLES_TEMPLATE:
    case CustomChannelMessageStatus.FAILED_LAST_RETRY:
      return 'var(--warning-error)';
    case CustomChannelMessageStatus.UNEXPECTED_RESULT:
      return 'var(--warning-orange)';
    case CustomChannelMessageStatus.SENDING_SMS:
    case CustomChannelMessageStatus.NOT_PROCESSED:
      return '#4994ee';
    case CustomChannelMessageStatus.WARN:
      return '#f0bb00';
    default:
      return 'black';
  }
}

export function getStatusBgColor(status?: ExecutionStatus): string | undefined {
  switch (status) {
    case ExecutionStatus.OK:
      return 'var(--tertiary)';
    case ExecutionStatus.ERROR:
      return 'var(--bg-yellow)';
    case ExecutionStatus.FATAL:
      return 'var(--bg-red)';
    case ExecutionStatus.SKIPPED_SUPPRESSED:
      return 'var(--detail-border)';
    case ExecutionStatus.SOFT_LAUNCH:
      return '#4994EE26';
    case ExecutionStatus.WARN:
      return '#FFFBD6';
    case ExecutionStatus.PROCESSING:
      return '#4994EE26';
  }
}

export function getCustomChannelStatusBgColor(status?: CustomChannelMessageStatus): string | undefined {
  switch (status) {
    case CustomChannelMessageStatus.MESSAGE_SENT:
    case CustomChannelMessageStatus.SMS_SENT:
      return 'var(--tertiary)';
    case CustomChannelMessageStatus.SMS_NOT_SENT:
    case CustomChannelMessageStatus.FAILED_GET_MESSAGE_TEMPLATE:
    case CustomChannelMessageStatus.FAILED_REPLACE_VARIABLES_TEMPLATE:
    case CustomChannelMessageStatus.FAILED_LAST_RETRY:
      return 'var(--bg-red)';
    case CustomChannelMessageStatus.UNEXPECTED_RESULT:
      return 'var(--bg-yellow)';
    case CustomChannelMessageStatus.SENDING_SMS:
    case CustomChannelMessageStatus.NOT_PROCESSED:
      return '#4994EE26';
    case CustomChannelMessageStatus.WARN:
      return '#FFFBD6';
  }
}

export function getStatusIconPath(status?: ExecutionStatus): string | undefined {
  switch (status) {
    case ExecutionStatus.OK:
      return mdiCheckCircle;
    case ExecutionStatus.ERROR:
      return mdiAlertCircle;
    case ExecutionStatus.FATAL:
      return mdiMinusCircle;
    case ExecutionStatus.SKIPPED_SUPPRESSED:
      return mdiAlertRhombus;
    case ExecutionStatus.SOFT_LAUNCH:
      return mdiAlertCircle;
    case ExecutionStatus.WARN:
      return mdiAlert;
    case ExecutionStatus.PROCESSING:
      return mdiCircleHalfFull;
  }
}

export function getCustomChanelStatusIconPath(status?: CustomChannelMessageStatus): string | undefined {
  switch (status) {
    case CustomChannelMessageStatus.MESSAGE_SENT:
    case CustomChannelMessageStatus.SMS_SENT:
      return mdiCheckCircle;
    case CustomChannelMessageStatus.UNEXPECTED_RESULT:
    case CustomChannelMessageStatus.SMS_NOT_SENT:
      return mdiMinusCircle;
    case CustomChannelMessageStatus.NOT_PROCESSED:
    case CustomChannelMessageStatus.FAILED_GET_MESSAGE_TEMPLATE:
    case CustomChannelMessageStatus.FAILED_REPLACE_VARIABLES_TEMPLATE:
    case CustomChannelMessageStatus.FAILED_LAST_RETRY:
      return mdiMinusCircle;
    case CustomChannelMessageStatus.SENDING_SMS:
      return mdiCircleHalfFull;
    case CustomChannelMessageStatus.WARN:
      return mdiAlert;
  }
}

export function shortenUUID(uuid?: string): string {
  return uuid ? uuid.substring(0, 4) + '...' + uuid.substring(uuid.length - 4) : '';
}

export function dateToCorrectTimezone(value: string): string {
  if (timezone === '') {
    return new Date(value).toLocaleString();
  }

  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  return new Date(value + 'Z').toLocaleString('en-US', options);
}

export function customChannelTimestampToCorrectTimezone(value: string): string {
  const epoch = Number(value) * 1000;
  if (timezone === '') {
    return new Date(epoch).toLocaleString();
  }

  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  return new Date(epoch).toLocaleString('en-US', options);
}

export function customChannelTimestampToISO(value: string): string {
  const epoch = Number(value) * 1000;
  return new Date(epoch).toISOString().replace('Z', '');
}
