import { Button } from '@amway/react-components';
import { Col, Form, Row } from 'react-bootstrap';
import DateRangeFieldsComponent from '../../../components/ui/date-range-fields';
import './index.scss';

type Props = {
  startDate: string;
  setStartDate: (date: string) => void;
  endDate: string;
  setEndDate: (date: string) => void;
  maxDate: string;
  submit: () => void;
  loading: boolean;
};

export default function DashboardMetricsFormComponent({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  maxDate,
  submit,
  loading,
}: Props) {
  return (
    <Form className="dashboard-metrics-form" onSubmit={submit}>
      <Row>
        <DateRangeFieldsComponent
          sm={12}
          md={4}
          lg={4}
          xl={3}
          xxl={3}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          maxDate={maxDate}
        />
        <Col className="d-flex justify-content-center align-items-end" sm={12} md={4} lg={4} xl={3} xxl={3}>
          <Button loading={loading}>UPDATE</Button>
        </Col>
      </Row>
    </Form>
  );
}
