import { ReactNode, useCallback, useState } from 'react';
import { Features } from '../../config/features';
import { WithFeaturesProxy } from '../../helpers/with-features-proxy';
import { MessageLog, MessageLogDetails } from '../messages/messages-types';
import CustomChannelHistoryListContext, {
  CustomChannelHistoryListContextType,
} from './custom-channel-history-list-context';

interface ExposedProps {
  children: ReactNode;
}

interface Props extends ExposedProps {
  mktHasEmailPreview: boolean;
  mktHasResendEmail: boolean;
  mktHasReprocessExecution: boolean;
}

function CustomChannelHistoryListProvider({ children }: Props) {
  const [selectedMessage, setSelectedMessage] = useState<MessageLog | null>();
  const [messageDetails, setDetails] = useState<MessageLogDetails | null>();

  const handleMessageBackClick = useCallback(() => {
    setSelectedMessage(null);
    setDetails(null);
  }, []);

  const value: CustomChannelHistoryListContextType = {
    selectedMessage,
    messageDetails,
    handleMessageBackClick,
  };

  return <CustomChannelHistoryListContext.Provider value={value}>{children}</CustomChannelHistoryListContext.Provider>;
}

export default WithFeaturesProxy<ExposedProps>(
  Features.MktFeature_EmailPreview,
  Features.MktFeature_ReprocessExecution,
  Features.MktFeature_ResendEmail,
)((props, mktHasEmailPreview, mktHasReprocessExecution, mktHasResendEmail) => {
  return (
    <CustomChannelHistoryListProvider
      {...props}
      mktHasEmailPreview={mktHasEmailPreview}
      mktHasReprocessExecution={mktHasReprocessExecution}
      mktHasResendEmail={mktHasResendEmail}
    />
  );
});
