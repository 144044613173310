import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SxProps, Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useCallback, useState } from 'react';
import { Column } from '..';
import TableCellOverlayComponent from '../table-cell-overlay';

type Props = {
  columns: Column[];
  row: any;
  formatCell: (column: Column, row: any) => string | HTMLElement;
  collapsibleRowIdPropName: string;
  collapsibleRowsPropName: string;
  collapsibleRows: any[];
  sx?: SxProps<Theme>;
  onClick?: (column: Column, row: any) => void;
};

export default function CollapsibleTableRow({
  columns,
  row,
  formatCell,
  collapsibleRowIdPropName = 'id',
  collapsibleRowsPropName,
  collapsibleRows = [],
  sx,
  onClick,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} sx={{ '& > *': { borderBottom: 'unset' }, ...sx }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ display: collapsibleRows.length ? 'block' : 'none' }}
            disabled={!collapsibleRows.length}
            onClick={toggle}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map(column => (
          <TableCellOverlayComponent
            key={column.id}
            column={column}
            row={row}
            formatCell={formatCell}
            onClick={onClick}
          />
        ))}
      </TableRow>
      {collapsibleRows.map(collapsibleRow => (
        <CollapsibleTableRow
          key={collapsibleRow[collapsibleRowIdPropName]}
          columns={columns}
          row={collapsibleRow}
          formatCell={formatCell}
          collapsibleRowIdPropName={collapsibleRowIdPropName}
          collapsibleRowsPropName={collapsibleRowsPropName}
          collapsibleRows={collapsibleRow[collapsibleRowsPropName]}
          sx={{
            visibility: open ? undefined : 'collapse',
          }}
        />
      ))}
    </>
  );
}
