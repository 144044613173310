import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from './../../config/api-wrapper';
import {
  MessageLog,
  MessagesDetailsRequest,
  MessagesExceptionsRequest,
  MessagesExceptionsResponse,
  MessagesRequest,
  MessagesResponse,
} from './messages-types';

class MessagesService {
  public getMessageHistory(request: MessagesRequest): WithAbortFn<Promise<MessagesResponse>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.customChannelApi
        .post<MessagesResponse>(
          'executions',
          { ...request },
          {
            cancelToken: source.token,
          },
        )
        .then(res => res.data as MessagesResponse)
        .then(response => {
          Object.values(response.messages).forEach(message => {
            message.request = request;
          });

          return response;
        }),
      abort: source.cancel,
    };
  }

  public getMessageExceptions(request: MessagesExceptionsRequest): WithAbortFn<Promise<MessagesExceptionsResponse>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.customChannelApi
        .post<MessagesExceptionsResponse>(
          'exceptions',
          { ...request },
          {
            cancelToken: source.token,
          },
        )
        .then(res => {
          return res.data;
        }),
      abort: source.cancel,
    };
  }

  public getMessageDetails(request: MessagesDetailsRequest): WithAbortFn<Promise<MessageLog>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.customChannelApi
        .post<MessageLog>(
          'details',
          { ...request },
          {
            cancelToken: source.token,
          },
        )
        .then(res => {
          return res.data;
        }),
      abort: source.cancel,
    };
  }
}

export default new MessagesService();
