import { LoginCallback } from '@okta/okta-react';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import GuestLayout from '../components/core/guest-layout';
import LoginComponent from '../screens/login';

const GuestRouter: React.FC = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/" element={<GuestLayout />}>
        <Route path="" element={<Navigate to="/login" state={{ from: location }} replace />} />
        <Route path="login" element={<LoginComponent />} />
        <Route path="login/callback" element={<LoginCallback />} />
        <Route path="*" element={<Navigate to="/login" state={{ from: location }} replace />} />
      </Route>
    </Routes>
  );
};

export default GuestRouter;
