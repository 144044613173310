import React from 'react';
import LogoLoading from '../../components/ui/logo-loading';
import './index.scss';

const LoadingScreen: React.FC = () => {
  return (
    <main className="loading-screen">
      <LogoLoading />
    </main>
  );
};

export default LoadingScreen;
