import { useCallback, useMemo, useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import ProcessorsContext from './processors-context';
import { Processor } from './processors-model';
import processorsService from './processors.service';

const ProcessorsProvider: React.FC = ({ children }) => {
  const [processors, setProcessors] = useState<ExternalData<Processor[]>>(makeExternalDataInitialData());
  const [processorSteps, setProcessorSteps] = useState<ExternalData<String[]>>(makeExternalDataInitialData());
  const processorsWithEmail = useMemo<String[]>(() => {
    if (processors.data) {
      return processors.data.flatMap(proc =>
        proc.outputType === 'EMAIL' || proc.outputType === 'EMAIL_SMS' ? [proc.name] : [],
      );
    }
    return [];
  }, [processors.data]);

  const fetchProcessors = useCallback(async () => {
    try {
      setProcessors(makeExternalDataInitialData());
      const data = await processorsService.list();
      setProcessors(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setProcessors(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchProcessorSteps = useCallback(async (processorId: number) => {
    try {
      setProcessorSteps(makeExternalDataInitialData());
      const data = await processorsService.getProcessorSteps(processorId);
      setProcessorSteps(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setProcessorSteps(makeExternalCallErrorData(err));
    }
  }, []);

  return (
    <ProcessorsContext.Provider
      value={{
        processors,
        processorsWithEmail,
        processorSteps,
        fetchProcessors,
        fetchProcessorSteps,
      }}>
      {children}
    </ProcessorsContext.Provider>
  );
};

export default ProcessorsProvider;
