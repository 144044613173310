import { useCallback, useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import MetricsContext, { MetricsCtxType } from './metrics-context';
import { Metrics } from './metrics-model';
import metricsService from './metrics.service';

const MetricsProvider: React.FC = ({ children }) => {
  const [metrics, setMetrics] = useState<ExternalData<Metrics>>(makeExternalDataInitialData());
  const [allCountries, setAllCountries] = useState<ExternalData<String[]>>(makeExternalDataInitialData());

  const fetchMetrics: MetricsCtxType['fetchMetrics'] = useCallback(async (startDateTime, endDateTime) => {
    try {
      setMetrics(makeExternalDataInitialData());
      const data = await metricsService.show(startDateTime, endDateTime);
      setMetrics(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setMetrics(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchAllCountries = useCallback(async () => {
    try {
      setAllCountries(makeExternalDataInitialData());
      const data = await metricsService.getAllCountries();
      setAllCountries(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setMetrics(makeExternalCallErrorData(err));
    }
  }, []);

  return (
    <MetricsContext.Provider
      value={{
        metrics,
        allCountries,
        fetchMetrics,
        fetchAllCountries,
      }}>
      {children}
    </MetricsContext.Provider>
  );
};

export default MetricsProvider;
