import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import env from './env';

class ApiWrapper {
  private readonly adminAxiosInstance: AxiosInstance;
  private readonly axiosInstance: AxiosInstance;
  private readonly customChannelAxiosInstance: AxiosInstance;

  get api(): AxiosInstance {
    return this.axiosInstance;
  }

  get adminApi(): AxiosInstance {
    return this.adminAxiosInstance;
  }

  get customChannelApi(): AxiosInstance {
    return this.customChannelAxiosInstance;
  }

  // Provisory, we should be using this apiWrapper to make api calls instead
  get requestConfig(): AxiosRequestConfig {
    return {
      headers: {
        Authorization: this.axiosInstance.defaults.headers.common.Authorization,
      },
    };
  }

  constructor(
    adminApiConfig: AxiosRequestConfig,
    apiConfig: AxiosRequestConfig,
    customChannelApiConfig: AxiosRequestConfig,
  ) {
    this.adminAxiosInstance = axios.create(adminApiConfig);
    this.axiosInstance = axios.create(apiConfig);
    this.customChannelAxiosInstance = axios.create(customChannelApiConfig);
  }

  setAuthToken(token: string): void {
    this.adminAxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    this.customChannelAxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  clearAuthToken(): void {
    delete this.axiosInstance.defaults.headers.common.Authorization;
    delete this.adminAxiosInstance.defaults.headers.common.Authorization;
    delete this.customChannelAxiosInstance.defaults.headers.common.Authorization;
  }

  // this is provisory and will get erased with anti corruption thing
  setOktaThing(authToken: string, idToken: string, nonce?: string) {
    this.adminAxiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    this.customChannelAxiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    this.adminAxiosInstance.defaults.headers.common['id_token'] = idToken;
    this.axiosInstance.defaults.headers.common['id_token'] = idToken;
    this.customChannelAxiosInstance.defaults.headers.common['id_token'] = idToken;
    this.adminAxiosInstance.defaults.headers.common.nonce = nonce as string;
    this.axiosInstance.defaults.headers.common.nonce = nonce as string;
    this.customChannelAxiosInstance.defaults.headers.common.nonce = nonce as string;
  }

  // this is provisory and will get erased with anti corruption thing
  clearOktaThing() {
    delete this.adminAxiosInstance.defaults.headers.common.Authorization;
    delete this.axiosInstance.defaults.headers.common.Authorization;
    delete this.customChannelAxiosInstance.defaults.headers.common.Authorization;
    delete this.adminAxiosInstance.defaults.headers.common['id_token'];
    delete this.axiosInstance.defaults.headers.common['id_token'];
    delete this.customChannelAxiosInstance.defaults.headers.common['id_token'];
    delete this.adminAxiosInstance.defaults.headers.common.nonce;
    delete this.axiosInstance.defaults.headers.common.nonce;
    delete this.customChannelAxiosInstance.defaults.headers.common.nonce;
  }
}

export const apiWrapper = new ApiWrapper(
  { baseURL: env.ADMIN_API_URL },
  { baseURL: env.API_URL },
  { baseURL: env.CUSTOM_CHANNEL_API_URL },
);
