import { createContext } from 'react';
import { MessageLog, MessageLogDetails } from '../messages/messages-types';

export interface CustomChannelHistoryListContextType {
  selectedMessage?: MessageLog | null;
  messageDetails?: MessageLogDetails | null;
  handleMessageBackClick: VoidFunction;
}

const CustomChannelHistoryListContext = createContext<CustomChannelHistoryListContextType>(null!);

export default CustomChannelHistoryListContext;
