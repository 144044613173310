import { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import NoExceptionsFoundIcon from '../../../../assets/no-exceptions-found-icon';
import NoResultsFoundIcon from '../../../../assets/no-results-found-icon';
import SearchErrorIcon from '../../../../assets/search-error-icon';
import SearchTimedOutIcon from '../../../../assets/search-timedout-icon';

type IconVariant = 'search-error' | 'search-time-out' | 'no-exceptions-found' | 'no-results-found';

export interface Props {
  iconVariant?: IconVariant;
  header?: string;
  description?: string;
}

const iconVariantMap: Record<IconVariant, ReactNode> = {
  'no-exceptions-found': <NoExceptionsFoundIcon />,
  'no-results-found': <NoResultsFoundIcon />,
  'search-error': <SearchErrorIcon />,
  'search-time-out': <SearchTimedOutIcon />,
};

export default function TableWarning({ header, description, iconVariant }: Props) {
  return (
    <Row className="no-results-found">
      <Col className="d-flex flex-column align-items-center">
        {iconVariantMap[iconVariant ?? 'no-results-found']}
        <h1>{header}</h1>
        <h4>{description}</h4>
      </Col>
    </Row>
  );
}
