import Icon from '@mdi/react';
import { MouseEvent, ReactNode, useCallback, useMemo } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './index.scss';

type Props = {
  id?: string;
  mdiIconPath?: string;
  onClick: VoidFunction;
  styleType?: 'default' | 'link';
  disabled?: boolean;
  color?: string;
  rotate?: number;
  spin?: boolean;
  title?: string;
  tooltip?: string;
  size?: string;
  children?: JSX.Element | ReactNode | null;
  href?: string;
  className?: string;
};

export default function IconButton({
  id,
  mdiIconPath,
  onClick,
  styleType = 'default',
  disabled = false,
  children,
  color = 'black',
  rotate = 180,
  spin = false,
  title,
  tooltip,
  size,
  href,
  className = '',
}: Props) {
  const isAnchor = useMemo<boolean>(() => !!href && styleType === 'link', [href, styleType]);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      if (isAnchor) {
        e.preventDefault();
      }

      onClick();
    },
    [isAnchor, onClick],
  );

  const btn = useMemo(
    () => (
      <Button
        id={id}
        as={isAnchor ? 'a' : 'button'}
        className={`icon-btn ${styleType} ${className} ${children ? 'has-children' : ''} ${spin ? 'spinning' : ''}`}
        onClick={handleClick}
        disabled={disabled}
        href={isAnchor ? href : undefined}>
        {mdiIconPath && (
          <Icon
            path={mdiIconPath}
            title={title}
            size={size}
            horizontal
            vertical
            rotate={rotate}
            spin={spin}
            color={color}
          />
        )}
        {children}
      </Button>
    ),
    [
      id,
      isAnchor,
      styleType,
      className,
      children,
      spin,
      handleClick,
      disabled,
      href,
      mdiIconPath,
      title,
      size,
      rotate,
      color,
    ],
  );

  return !children && tooltip ? (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip>
          <>{tooltip}</>
        </Tooltip>
      }>
      {btn}
    </OverlayTrigger>
  ) : (
    btn
  );
}
