import { apiWrapper } from './../../config/api-wrapper';
import { metricsMapper } from './metrics-mapper';
import { Metrics } from './metrics-model';

class MetricsService {
  public show(startDateTime: string, endDateTime: string): Promise<Metrics> {
    return apiWrapper.adminApi
      .get('/dashboard/data', { params: { startDateTime, endDateTime } })
      .then(res => metricsMapper(res.data));
  }

  public getAllCountries(): Promise<String[]> {
    return apiWrapper.adminApi.get('/dashboard/countries').then(res => res.data);
  }
}

export default new MetricsService();
