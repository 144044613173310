import { useCallback, useState } from 'react';
import { ToastContainer } from 'react-bootstrap';
import { ToastType } from '../../@types/toasts';
import Toast from '../../components/ui/toast';
import ToastsContext from './toasts-context';

const randomId = (): string => {
  return Math.random().toString(16);
};

const ToastProvider: React.FC<{}> = ({ children }) => {
  const [toasts, setToasts] = useState<Array<{ id: string; text: string; type?: ToastType; delay?: number }>>([]);

  const push = useCallback((text: string, type?: ToastType, delay?: number): void => {
    setToasts(prev => [...prev, { id: randomId(), text, type, delay }]);
  }, []);

  const remove = useCallback((id: string): void => {
    setToasts(prev => prev.filter(toast => toast.id !== id));
  }, []);

  return (
    <ToastsContext.Provider value={{ push }}>
      <ToastContainer style={{ zIndex: 99999, position: 'static', top: '0', right: '0' }} className="p-5 mt-5">
        {toasts.map(toast => (
          <Toast
            key={toast.id}
            text={toast.text}
            type={toast.type}
            delay={toast.delay}
            onClose={() => remove(toast.id)}
          />
        ))}
      </ToastContainer>
      {children}
    </ToastsContext.Provider>
  );
};

export default ToastProvider;
