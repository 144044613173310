import { ReactNode, useCallback, useState } from 'react';
import { ToastContainer } from 'react-bootstrap';
import { ToastPosition } from 'react-bootstrap/esm/ToastContainer';
import NotificationComponent from '../../components/core/notification';
import NotificationContext, { NotificationContextType } from './notification-context';
import { NotificationProps } from './notification-types';

type Props = {
  position?: ToastPosition;
  children: ReactNode;
};

export default function NotificationProvider({ position = 'bottom-end', children }: Props) {
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);

  const pushNotification = useCallback(
    (notification: NotificationProps) => {
      const id = `${Math.random()}`.split('.')[1];
      notification.id = `notification-${id}`;
      notification.show = true;
      notifications.push(notification);
      setNotifications([...notifications]);
    },
    [notifications],
  );

  const dismissNotification = useCallback(
    (notification: NotificationProps) => {
      notification.show = false;
      setNotifications(notifications.filter(n => n !== notification));
    },
    [notifications],
  );

  const value: NotificationContextType = {
    pushNotification,
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <ToastContainer className="notification p-3" position={position}>
        {notifications.map(notification => (
          <NotificationComponent
            key={notification.id}
            id={notification.id}
            header={notification.header}
            bg={notification.bg}
            show={notification.show}
            onClose={() => dismissNotification(notification)}
            bodyStyle={notification.bodyStyle}>
            {notification.body}
          </NotificationComponent>
        ))}
      </ToastContainer>
    </NotificationContext.Provider>
  );
}
