import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

import './index.scss';

interface Chip {
  title: string;
  value: string;
}

export interface Props {
  chips: Chip[];
  isReadOnly?: boolean;
  onChange?: (newChips: Chip[]) => void;
}

const Chips: React.FC<Props> = (props: Props) => {
  const { chips, onChange, isReadOnly = true } = props;

  function handleDelete(chipValue: string): void {
    onChange?.(chips.filter(chip => chip.value !== chipValue));
  }

  return (
    <section className="chips">
      {chips.map(({ title, value }) => (
        <span key={value} className={`item ${isReadOnly ? 'read-only' : ''}`}>
          {title}
          {!isReadOnly && (
            <span onClick={() => handleDelete(value)} className="delete-icon-wrapper">
              <Icon path={mdiClose} size="1rem" />
            </span>
          )}
        </span>
      ))}
    </section>
  );
};

export default Chips;
