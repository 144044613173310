export function addDays(date: Date, days: number): Date {
  date.setDate(date.getDate() + days);

  return date;
}

export function subtractDays(date: Date, days: number): Date {
  addDays(date, -days);

  return date;
}

export function addMinutes(date: Date, minutes: number): Date {
  date.setMinutes(date.getMinutes() + minutes);

  return date;
}

export function subtractMinutes(date: Date, minutes: number): Date {
  addMinutes(date, -minutes);

  return date;
}

export function daysDiff(dateA: Date, dateB: Date): number {
  const dayInMillis = 1000 * 60 * 60 * 24;

  return Math.abs(dateB.getTime() - dateA.getTime()) / dayInMillis;
}

export function toISODateString(date: string): string {
  return date.split('T')[0];
}

export function toISOString(date: Date): string {
  return toISODateString(date.toISOString());
}

export function toLocaleString(isoDateString: string): string {
  const date = new Date(isoDateString);
  return date.toLocaleString();
}

export function formatExecutionTime(executionTime?: number): string | undefined {
  const executionTimeInMillis = executionTime ?? -1;
  let formattedExecutionTime: string | undefined;

  if (executionTimeInMillis >= 0) {
    if (executionTimeInMillis >= 1000) {
      const seconds = executionTimeInMillis / 1000;
      formattedExecutionTime = Number(seconds).toFixed(1) + 's';
    } else {
      formattedExecutionTime = Number(executionTimeInMillis).toFixed() + 'ms';
    }
  }

  return formattedExecutionTime;
}
