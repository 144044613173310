export default function SearchTimedOutIcon() {
  return (
    <svg width="106" height="120" viewBox="0 0 106 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M85.2492 17.2129V111.594C85.2492 113.823 84.3633 115.961 82.7875 117.538C81.2105 119.114 79.073 120 76.8429 120H8.87232C6.64302 120 4.50491 119.114 2.9285 117.538C1.35208 115.961 0.466553 113.823 0.466553 111.594V8.40519C0.466553 6.17624 1.35208 4.03754 2.9285 2.46172C4.50491 0.884705 6.64302 1.52588e-05 8.87232 1.52588e-05H67.4235C68.0565 -0.00117493 68.6657 0.245827 69.1217 0.686394L84.5961 15.6704C84.8015 15.8711 84.9666 16.1109 85.0794 16.3757C85.191 16.6406 85.2492 16.9256 85.2492 17.2129Z"
        fill="#F0F8F4"
      />
      <path
        d="M85.2492 17.1761H76.9509C74.5842 17.1785 72.3101 16.2606 70.6061 14.6182C68.9032 12.9759 67.9045 10.7351 67.8202 8.37078L67.5269 3.43323e-05C68.098 3.43323e-05 68.6467 0.220919 69.0564 0.618732L84.6234 15.6906C84.8217 15.8841 84.9797 16.1145 85.0865 16.3698C85.1946 16.6251 85.2504 16.8995 85.2492 17.1761Z"
        fill="var(--secondary)"
      />
      <path
        d="M71.8434 29.6663H9.01676C8.14929 29.6663 7.44604 30.3693 7.44604 31.2374C7.44604 32.1043 8.14929 32.8073 9.01676 32.8073H71.8434C72.7114 32.8073 73.4145 32.1043 73.4145 31.2374C73.4145 30.3693 72.7114 29.6663 71.8434 29.6663Z"
        fill="var(--secondary)"
      />
      <path
        d="M61.6985 39.0904H9.15784C8.21247 39.0904 7.44604 39.8563 7.44604 40.8016C7.44604 41.748 8.21247 42.514 9.15784 42.514H61.6985C62.6449 42.514 63.4109 41.748 63.4109 40.8016C63.4109 39.8563 62.6449 39.0904 61.6985 39.0904Z"
        fill="var(--secondary)"
      />
      <path
        d="M61.6985 48.7959H9.15784C8.21247 48.7959 7.44604 49.563 7.44604 50.5083C7.44604 51.4535 8.21247 52.2195 9.15784 52.2195H61.6985C62.6449 52.2195 63.4109 51.4535 63.4109 50.5083C63.4109 49.563 62.6449 48.7959 61.6985 48.7959Z"
        fill="var(--secondary)"
      />
      <path
        d="M41.5458 58.5667H9.15808C8.21247 58.5667 7.44604 59.3338 7.44604 60.2791C7.44604 61.2243 8.21247 61.9915 9.15808 61.9915H41.5458C42.4908 61.9915 43.2575 61.2243 43.2575 60.2791C43.2575 59.3338 42.4908 58.5667 41.5458 58.5667Z"
        fill="var(--secondary)"
      />
      <path
        d="M41.5458 68.2745H9.15808C8.21247 68.2745 7.44604 69.0405 7.44604 69.9869C7.44604 70.9322 8.21247 71.6981 9.15808 71.6981H41.5458C42.4908 71.6981 43.2575 70.9322 43.2575 69.9869C43.2575 69.0405 42.4908 68.2745 41.5458 68.2745Z"
        fill="var(--secondary)"
      />
      <path
        d="M41.5458 77.9813H9.15808C8.21247 77.9813 7.44604 78.7472 7.44604 79.6925C7.44604 80.6389 8.21247 81.4048 9.15808 81.4048H41.5458C42.4908 81.4048 43.2575 80.6389 43.2575 79.6925C43.2575 78.7472 42.4908 77.9813 41.5458 77.9813Z"
        fill="var(--secondary)"
      />
      <path
        d="M71.8434 86.96H9.01676C8.14929 86.96 7.44604 87.663 7.44604 88.5299C7.44604 89.3979 8.14929 90.1009 9.01676 90.1009H71.8434C72.7114 90.1009 73.4145 89.3979 73.4145 88.5299C73.4145 87.663 72.7114 86.96 71.8434 86.96Z"
        fill="var(--secondary)"
      />
      <path
        d="M61.6985 96.384H9.15784C8.21247 96.384 7.44604 97.15 7.44604 98.0952C7.44604 99.0405 8.21247 99.8076 9.15784 99.8076H61.6985C62.6449 99.8076 63.4109 99.0405 63.4109 98.0952C63.4109 97.15 62.6449 96.384 61.6985 96.384Z"
        fill="var(--secondary)"
      />
      <path
        d="M74.5343 106.09H9.5629C8.3938 106.09 7.44604 106.857 7.44604 107.802C7.44604 108.747 8.3938 109.513 9.5629 109.513H74.5343C75.7039 109.513 76.6516 108.747 76.6516 107.802C76.6516 106.857 75.7039 106.09 74.5343 106.09Z"
        fill="var(--secondary)"
      />
      <path
        d="M93.6726 47.4995L96.4429 50.2703L98.0982 48.615L95.3273 45.8442L93.6726 47.4995Z"
        fill="var(--secondary)"
      />
      <path
        d="M95.1654 46.1179L97.8254 48.7778C98.6271 49.5801 99.9279 49.5801 100.731 48.7778L101.258 48.2498C102.06 47.4481 102.06 46.1468 101.258 45.3451L98.5982 42.6851C97.7959 41.8828 96.4951 41.8828 95.6934 42.6851L95.1654 43.2125C94.3631 44.0148 94.3631 45.3156 95.1654 46.1179Z"
        fill="var(--secondary)"
      />
      <path d="M66.9607 38.9433H74.4131V36.3889H66.9607V38.9433Z" fill="var(--secondary)" />
      <path
        d="M64.8458 36.3293H76.5279C77.4429 36.3293 78.185 35.5872 78.185 34.6723V30.1246C78.185 29.209 77.4429 28.4675 76.5279 28.4675H64.8458C63.9308 28.4675 63.1887 29.209 63.1887 30.1246V34.6723C63.1887 35.5872 63.9308 36.3293 64.8458 36.3293Z"
        fill="var(--secondary)"
      />
      <path
        d="M35.7664 73.2931C35.7664 92.5587 51.3839 108.177 70.6494 108.177C89.9161 108.177 105.534 92.5587 105.534 73.2931C105.534 54.027 89.9161 38.4095 70.6494 38.4095C51.3839 38.4095 35.7664 54.027 35.7664 73.2931Z"
        fill="var(--secondary)"
      />
      <path
        d="M38.96 73.293C38.96 90.7947 53.1475 104.983 70.6492 104.983C88.1515 104.983 102.34 90.7947 102.34 73.293C102.34 55.7913 88.1515 41.6031 70.6492 41.6031C53.1475 41.6031 38.96 55.7913 38.96 73.293Z"
        fill="#F0F8F4"
      />
      <path
        d="M70.6511 104.986C58.6162 104.986 48.1493 98.2796 42.7828 88.3991C42.7834 88.3979 42.7834 88.3979 42.784 88.3967C48.1499 98.2767 58.6162 104.983 70.6494 104.983C73.0651 104.983 75.4165 104.713 77.677 104.201C77.68 104.202 77.6824 104.202 77.6859 104.203C75.4206 104.718 73.0716 104.986 70.6511 104.986ZM39.5654 67.0983H39.5642C42.4442 52.5592 55.2672 41.6003 70.6511 41.6003C73.0716 41.6003 75.4206 41.8687 77.6859 42.3837C77.6835 42.3843 77.6812 42.3849 77.6788 42.3854C75.4183 41.8734 73.0651 41.6032 70.6494 41.6032C55.2684 41.6032 42.4459 52.5627 39.5654 67.0983Z"
        fill="var(--secondary)"
      />
      <path
        d="M70.6501 74.3284C71.2217 74.3284 71.6854 73.8647 71.6854 73.2931V50.0874C71.6854 49.5152 71.2217 49.0515 70.6501 49.0515C70.0784 49.0515 69.6147 49.5152 69.6147 50.0874V73.2931C69.6147 73.8647 70.0784 74.3284 70.6501 74.3284Z"
        fill="var(--secondary)"
      />
      <path
        d="M79.5692 89.6421C79.7456 89.6421 79.925 89.5967 80.089 89.5011C80.5833 89.2132 80.7508 88.5796 80.463 88.0853L71.5452 72.7716C71.2573 72.2772 70.6231 72.1103 70.1294 72.3976C69.635 72.6855 69.4675 73.3196 69.7553 73.814L78.6726 89.1277C78.8649 89.458 79.2123 89.6421 79.5692 89.6421Z"
        fill="var(--secondary)"
      />
      <path
        d="M67.8025 73.2931C67.8025 74.8658 69.0773 76.1407 70.6494 76.1407C72.2222 76.1407 73.4976 74.8658 73.4976 73.2931C73.4976 71.7204 72.2222 70.445 70.6494 70.445C69.0773 70.445 67.8025 71.7204 67.8025 73.2931Z"
        fill="var(--secondary)"
      />
      <path
        d="M70.65 47.2469C70.9361 47.2469 71.1674 47.0151 71.1674 46.729V43.5753C71.1674 43.2892 70.9361 43.0573 70.65 43.0573C70.3639 43.0573 70.1321 43.2892 70.1321 43.5753V46.729C70.1321 47.0151 70.3639 47.2469 70.65 47.2469Z"
        fill="var(--secondary)"
      />
      <path
        d="M83.9314 50.8059C84.1102 50.8059 84.2848 50.7133 84.3804 50.5469L85.9572 47.8156C86.1006 47.5678 86.015 47.251 85.7678 47.1077C85.5207 46.9643 85.2033 47.0499 85.0605 47.2977L83.4831 50.029C83.3403 50.2767 83.4253 50.5935 83.673 50.7363C83.7545 50.7835 83.8435 50.8059 83.9314 50.8059Z"
        fill="var(--secondary)"
      />
      <path
        d="M93.6558 60.529C93.7431 60.529 93.8322 60.5066 93.9142 60.4594L96.6455 58.8826C96.8933 58.7398 96.9777 58.423 96.8349 58.1753C96.6921 57.9275 96.3753 57.8425 96.1276 57.9859L93.3963 59.5627C93.1485 59.7055 93.0635 60.0223 93.2069 60.2701C93.3025 60.4364 93.4765 60.529 93.6558 60.529Z"
        fill="var(--secondary)"
      />
      <path
        d="M97.2142 73.8103H100.368C100.654 73.8103 100.885 73.5791 100.885 73.2929C100.885 73.0068 100.654 72.775 100.368 72.775H97.2142C96.9275 72.775 96.6963 73.0068 96.6963 73.2929C96.6963 73.5791 96.9275 73.8103 97.2142 73.8103Z"
        fill="var(--secondary)"
      />
      <path
        d="M96.386 88.6699C96.5647 88.6699 96.7387 88.5773 96.8349 88.4109C96.9777 88.1632 96.8933 87.8464 96.6455 87.7036L93.9142 86.1262C93.667 85.9834 93.3497 86.0678 93.2069 86.3155C93.0635 86.5639 93.1485 86.8801 93.3963 87.0228L96.1276 88.6003C96.209 88.6475 96.2981 88.6699 96.386 88.6699Z"
        fill="var(--secondary)"
      />
      <path
        d="M85.5095 99.5474C85.5968 99.5474 85.6864 99.5249 85.7678 99.4777C86.015 99.335 86.1006 99.0182 85.9572 98.7704L84.3804 96.0391C84.2376 95.7914 83.9208 95.7064 83.673 95.8492C83.4253 95.9925 83.3403 96.3093 83.4831 96.5571L85.0605 99.2884C85.1561 99.4547 85.3301 99.5474 85.5095 99.5474Z"
        fill="var(--secondary)"
      />
      <path
        d="M70.65 103.529C70.9361 103.529 71.1674 103.297 71.1674 103.011V99.8565C71.1674 99.5704 70.9361 99.3386 70.65 99.3386C70.3639 99.3386 70.1321 99.5704 70.1321 99.8565V103.011C70.1321 103.297 70.3639 103.529 70.65 103.529Z"
        fill="var(--secondary)"
      />
      <path
        d="M55.7913 99.5474C55.9701 99.5474 56.1441 99.4547 56.2403 99.2884L57.8171 96.5571C57.9599 96.3093 57.8749 95.9925 57.6272 95.8492C57.38 95.7058 57.0626 95.7914 56.9198 96.0391L55.343 98.7704C55.1996 99.0182 55.2852 99.335 55.5324 99.4777C55.6138 99.5249 55.7034 99.5474 55.7913 99.5474Z"
        fill="var(--secondary)"
      />
      <path
        d="M44.9135 88.6699C45.002 88.6699 45.0905 88.6475 45.1725 88.6003L47.9044 87.0228C48.1522 86.8801 48.2365 86.5639 48.0938 86.3155C47.9504 86.0684 47.6336 85.9834 47.3865 86.1262L44.6546 87.7036C44.4068 87.8464 44.3218 88.1632 44.4652 88.4109C44.5608 88.5773 44.7348 88.6699 44.9135 88.6699Z"
        fill="var(--secondary)"
      />
      <path
        d="M40.9317 73.8103H44.086C44.3727 73.8103 44.6039 73.5791 44.6039 73.2929C44.6039 73.0068 44.3727 72.775 44.086 72.775H40.9317C40.6461 72.775 40.4143 73.0068 40.4143 73.2929C40.4143 73.5791 40.6461 73.8103 40.9317 73.8103Z"
        fill="var(--secondary)"
      />
      <path
        d="M47.6449 60.529C47.8236 60.529 47.9976 60.4364 48.0938 60.2701C48.2365 60.0223 48.1522 59.7055 47.9044 59.5627L45.1725 57.9859C44.9247 57.8425 44.608 57.9275 44.4652 58.1753C44.3218 58.423 44.4068 58.7398 44.6546 58.8826L47.3865 60.4594C47.4679 60.5066 47.557 60.529 47.6449 60.529Z"
        fill="var(--secondary)"
      />
      <path
        d="M57.3688 50.8059C57.4567 50.8059 57.5457 50.7835 57.6272 50.7363C57.8749 50.5935 57.9599 50.2767 57.8171 50.029L56.2403 47.2977C56.0969 47.0499 55.7789 46.9655 55.5324 47.1077C55.2852 47.251 55.1996 47.5678 55.343 47.8156L56.9198 50.5469C57.0154 50.7133 57.1894 50.8059 57.3688 50.8059Z"
        fill="var(--secondary)"
      />
    </svg>
  );
}
