import { Button } from '@amway/react-components';
import { FormControlLabel } from '@mui/material';
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DropdownBtn, { Item } from '../../../components/ui/dropdown-btn';
import Switch from '../../../components/ui/switch';
import useAllowList from '../../../resources/allow-list/allow-list-hook';
import { AllowListEntryType, AllowListRule, AllowListRuleType } from '../../../resources/allow-list/allow-list-model';

type Props = {
  rule: AllowListRule;
  submitLabel: string;
  onSubmit: (rule: AllowListRule) => void;
};

export default function AllowListFormComponent({ rule, submitLabel, onSubmit }: Props) {
  const { ruleTypes: ruleTypesData, entryTypes: entryTypesData, fetchEntryTypes, fetchRuleTypes } = useAllowList();
  const entryTypes = useMemo(() => (entryTypesData.data ?? []).map(e => ({ id: e, label: e })), [entryTypesData.data]);
  const ruleTypes = useMemo(() => (ruleTypesData.data ?? []).map(r => ({ id: r, label: r })), [ruleTypesData.data]);
  const [selectedEntryType, setSelectedEntryType] = useState<Item | undefined | null>(
    rule.entryType ? { label: rule.entryType, id: rule.entryType } : undefined,
  );
  const [selectedRuleType, setSelectedRuleType] = useState<Item | undefined | null>(
    rule.ruleType ? { label: rule.ruleType, id: rule.ruleType } : undefined,
  );
  const [ruleValue, setRuleValue] = useState<string>(rule.ruleValue ?? '');
  const [active, setActive] = useState<boolean>(rule.active ?? false);

  useEffect(() => {
    fetchEntryTypes();
  }, [fetchEntryTypes]);

  useEffect(() => {
    fetchRuleTypes();
  }, [fetchRuleTypes]);

  const handleToggleActive = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked);
  }, []);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit({
        entryType: selectedEntryType?.id as AllowListEntryType,
        ruleType: selectedRuleType?.id as AllowListRuleType,
        ruleValue: ruleValue,
        active: active,
      });
    },
    [onSubmit, selectedEntryType, selectedRuleType, ruleValue, active],
  );

  return (
    <Form className="allow-list-form" onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <DropdownBtn
            id="entry-type-dropdown"
            required={true}
            disabled={entryTypes.length === 0}
            label="Entry Type"
            placeholder="Select the entry type"
            items={entryTypes}
            value={selectedEntryType as Item}
            onClick={setSelectedEntryType as unknown as (item?: Item | null) => void}
          />
        </Col>
        <Col md={6}>
          <DropdownBtn
            id="rule-type-dropdown"
            required={true}
            disabled={ruleTypes.length === 0}
            label="Rule Type"
            placeholder="Select the rule type"
            items={ruleTypes}
            value={selectedRuleType as Item}
            onClick={setSelectedRuleType as unknown as (item?: Item | null) => void}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group>
            <Form.Label htmlFor="rule-value-ctrl">
              Rule Value <sup style={{ color: 'var(--warning-error)' }}>*</sup>
            </Form.Label>
            <Form.Control
              id="rule-value-ctrl"
              required
              placeholder="Type the rule value"
              value={ruleValue}
              onChange={e => setRuleValue(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={2} className="d-flex align-items-end">
          <FormControlLabel
            value={active}
            control={<Switch checked={active} onChange={handleToggleActive} />}
            label="Active"
            labelPlacement="end"
          />
        </Col>
        <Col md={2} className="d-flex align-items-end">
          <Button>{submitLabel}</Button>
        </Col>
      </Row>
    </Form>
  );
}
