import { BaseSyntheticEvent, useCallback, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import './index.scss';

type Props = {
  id: string;
  label: string;
  styleType?: 'default' | 'filter';
  value?: string;
  minDate?: string;
  maxDate?: string;
  isInvalid?: boolean;
  feedbackMessage?: string | null;
  onChange: (event: string) => void;
};

export default function DatePickerComponent({
  id,
  label,
  styleType = 'default',
  value,
  minDate,
  maxDate,
  isInvalid,
  feedbackMessage,
  onChange,
}: Props) {
  const ctrlRef = useRef<HTMLInputElement>();
  const handleChange = useCallback(
    (event: BaseSyntheticEvent<InputEvent, any, HTMLInputElement>) => {
      const { value }: { value: string } = event.target;

      if (value?.length) {
        onChange(value);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (ctrlRef?.current && !ctrlRef.current.value && value?.length) {
      ctrlRef.current.value = value;
    }
  }, [ctrlRef, value]);

  return (
    <Form.Group className={'datepicker ' + styleType}>
      <Form.Label htmlFor={id}>{label}</Form.Label>
      <Form.Control
        ref={ctrlRef as any}
        id={id}
        type="date"
        onChange={handleChange}
        min={minDate}
        max={maxDate}
        isInvalid={isInvalid}
      />
      <Form.Control.Feedback type="invalid">
        <div>{feedbackMessage}</div>
      </Form.Control.Feedback>
    </Form.Group>
  );
}
