import { AxiosRequestConfig } from 'axios';
import environment from '../config/env';
import {
  CBRReportsResponse,
  GenerateCBRReportsRequest,
  GenerateReportsRequest,
  ReportsSortedBarChartData,
} from '../interface/reports';
import { User } from '../interface/user';
import {
  ExecutionDetails,
  ExecutionRequest,
  ExecutionResponse,
  ProcessorLog,
  TransactionalEmail,
} from '../resources/history-list/history-list-types';
import { toISODateString } from '../utils/date-utils';
import { AuthorizedService } from './authorized.service';
import { Applications, Environments, Markets } from '../@types/configuration';

// DEPRACATED: we have a new version of getting this external datas /resources/executions.service.ts
class AdminService extends AuthorizedService {
  public getUsers(usernameOrEmail: string): Promise<User[]> {
    return super.get<User[]>(`user/search`, { params: { usernameOrEmail } });
  }

  public getExecutionHistory(request: ExecutionRequest, controller?: AbortController): Promise<ExecutionResponse> {
    return super
      .post<ExecutionResponse, ExecutionRequest>(
        'execution-history',
        request,
        {
          params: { application: environment.MARKET },
        },
        controller,
      )
      .then(response => {
        response.executions.forEach(execution => {
          execution.request = request;
        });

        return response;
      });
  }

  public getExceptionHistory(request: ExecutionRequest): Promise<ExecutionResponse> {
    return super.post<ExecutionResponse, ExecutionRequest>('exception-history', request).then(response => {
      response.executions.forEach(execution => {
        execution.request = request;
      });

      return response;
    });
  }

  public getExecutionDetails({
    sharedId,
    executionId,
    processorName,
    application,
    startDt,
    endDt,
  }: ProcessorLog): Promise<ExecutionDetails> {
    const config = this.getRequestConfig({ processorName, application, startDt, endDt });

    return super.get<ExecutionDetails>(
      'execution-history/' + (sharedId ? `shared/${sharedId}/` : `${executionId}`),
      config,
    );
  }

  public getTransactionalEmail({
    sharedId,
    executionId,
    processorName,
    application,
    startDt,
    endDt,
  }: ProcessorLog): Promise<TransactionalEmail> {
    const config = this.getRequestConfig({ processorName, application, startDt, endDt });

    return super
      .get<TransactionalEmail>('email-history/' + (sharedId ? `shared/${sharedId}/` : `${executionId}`), config)
      .then(transactionalEmail => {
        const { receiver } = transactionalEmail;

        if (receiver) {
          transactionalEmail.receiver = receiver
            .split(',')
            .map(emailAddress => emailAddress.trim())
            .join(', ');
        }

        if (!transactionalEmail.processorName) {
          transactionalEmail.processorName = processorName;
        }

        return transactionalEmail;
      });
  }

  public setExecutionDetails(
    execution: ProcessorLog,
    setDetails: (details: ExecutionDetails) => void,
    setExecution?: (execution: ProcessorLog) => void,
    hasError?: (details: ExecutionDetails) => boolean,
    error?: Error,
  ): Promise<void> {
    const handleResponse = (details: ExecutionDetails) => {
      if (
        setExecution &&
        (execution.startDt !== details.execution.startDt || execution.endDt !== details.execution.endDt)
      ) {
        execution.startDt = details.execution.startDt;
        execution.endDt = details.execution.endDt;
        execution.status = details.execution.status;
        setExecution({ ...execution });
      }

      if (hasError && error && hasError(details)) {
        throw error;
      } else {
        const { sharedId, application } = details.execution;

        details.execution.sharedId = sharedId ?? execution.sharedId;
        details.execution.application = application ?? execution.application;

        setDetails(details);
      }
    };

    return this.getExecutionDetails(execution)
      .then(handleResponse)
      .catch(err => {
        if (execution.executionId) {
          return this.getExecutionDetails({ ...execution, sharedId: undefined }).then(handleResponse);
        } else {
          throw err;
        }
      });
  }

  private getRequestConfig({ processorName, application, startDt, endDt }: ProcessorLog): AxiosRequestConfig<any> {
    const startDate = startDt ? toISODateString(startDt) + 'T00:00:00.001' : undefined;
    const endDate = endDt ? toISODateString(endDt) + 'T23:59:59.999' : undefined;

    var cfg = {
      params:
        processorName !== 'Outbound Comms processor'
          ? { processorName, application, startDate, endDate }
          : { application, startDate, endDate },
    };

    if ( environment.MARKET == Markets.NA ) {
      cfg.params.application = cfg.params.application || Applications.NA;
    }
    else if ( environment.MARKET == Markets.INDIA ) {
      cfg.params.application = cfg.params.application || Applications.INDIA;
    }

    return cfg;
  }

  public getExecutionReports(config: GenerateReportsRequest): Promise<ReportsSortedBarChartData[]> {
    return super.post('dashboard/complete', config);
  }

  public getCBRReports(config: GenerateCBRReportsRequest): Promise<CBRReportsResponse> {
    return super.get('dashboard/cbr', { params: config });
  }
}

export default new AdminService(environment.ADMIN_API_URL);
