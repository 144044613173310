import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import MetricsTileComponent from '../../../components/ui/metrics-tile';
import { Metrics } from '../../../resources/metrics/metrics-model';
import { formatExecutionTime } from '../../../utils/date-utils';

type Props = {
  metrics?: Metrics | null;
};

export default function DashboardMetricsComponent({ metrics }: Props) {
  const formattedExecutionTime = useMemo(() => formatExecutionTime(metrics?.executionTime), [metrics]);

  const formattedErrors = useMemo(() => {
    let errors: string | undefined;

    if (metrics) {
      const totalErrors = metrics.numberOfErrors ?? 0;
      const totalExecutions = metrics.numberOfExecutions ?? 1;
      const errorPercentage = totalExecutions > 0 ? (totalErrors / totalExecutions) * 100 : 0;

      return `${metrics?.numberOfErrors?.toLocaleString()} / ${errorPercentage.toFixed()}%`;
    }

    return errors;
  }, [metrics]);

  return (
    <Row>
      <Col>
        <MetricsTileComponent
          id="number-of-executions"
          title="Number of executions"
          formattedData={metrics?.numberOfExecutions?.toLocaleString()}
        />
      </Col>
      <Col>
        <MetricsTileComponent
          id="transactions-per-second"
          title="Tps"
          formattedData={metrics?.transactionsPerSecond?.toLocaleString()}
        />
      </Col>
      <Col>
        <MetricsTileComponent id="execution-time" title="Execution time" formattedData={formattedExecutionTime} />
      </Col>
      <Col>
        <MetricsTileComponent
          id="number-of-errors"
          title="Number of Errors"
          formattedData={formattedErrors}
          variant="warning"
        />
      </Col>
    </Row>
  );
}
