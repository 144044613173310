import { createContext } from 'react';
import { ExternalData, FetchFunction, MultiFetchFunction } from '../../@types/external-api';
import {
  CreateProcessorExecutionRequest,
  CreateProcessorExecutionResponse,
  ExecutionDetails,
  ExecutionDetailsRequest,
  ExecutionRequest,
  ExecutionsResponse,
  ProcessorStepLog,
} from './executions-types';

export interface ExecutionsCtxType {
  executionDetails: ExternalData<ExecutionDetails>;
  executions: ExternalData<ExecutionsResponse>;
  exceptions: ExternalData<ExecutionsResponse>;
  executionCompletedSteps: ExternalData<ProcessorStepLog[]>;
  createExecutionResponse: ExternalData<CreateProcessorExecutionResponse>;
  fetchExecutionCompletedSteps: (executionId: string) => Promise<void>;
  createExecution: (req: CreateProcessorExecutionRequest) => void;
  fetchExecutions: MultiFetchFunction<ExecutionRequest>;
  fetchExceptions: FetchFunction<ExecutionRequest>;
  fetchExecutionDetails: (req: ExecutionDetailsRequest) => void;
  resetExecution: () => void;
}

const ExecutionsContext = createContext<ExecutionsCtxType>(null as any);

export default ExecutionsContext;
