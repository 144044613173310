import { PropsWithChildren, ReactElement } from 'react';
import { Features } from '../config/features';
import useFeatures from '../resources/features/features-hook';

interface ContextualizedFunctionComponent<P = {}> {
  (props: PropsWithChildren<P>, ...hasPermission: boolean[]): ReactElement<any, any> | null;
}

export const WithFeaturesProxy =
  <P extends unknown>(...resourceFeatures: Features[]) =>
  (applyContext: ContextualizedFunctionComponent<P>): React.FC<P> => {
    return props => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { hasFeature } = useFeatures();

      return applyContext(props, ...resourceFeatures.map(resourceFeature => hasFeature(resourceFeature)));
    };
  };
