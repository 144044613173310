import { IconButton, Typography } from '@amway/react-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../@types/page';
import TealiumDataLayer from '../../components/hocs/tealium-data-layer';
import CustomChannelExecutionHistoryDetailsCardComponent from '../../components/shared/execution/custom-channel/history-details-card';
import CustomChannelHistoryFormComponent, {
  Filters,
} from '../../components/shared/execution/custom-channel/history-form';
import { isPROD } from '../../config/env';
import useQueryParams, { QueryParams } from '../../hooks/use-query-params';
import useCustomChannelHistoryList from '../../resources/custom-channel/custom-channel-history-list-hook';
import {
  CustomChannelChannel,
  CustomChannelMessageStatus,
  CustomChannelMessageType,
} from '../../resources/custom-channel/custom-channel-history-list-types';
import useMessages from '../../resources/messages/messages-hook';
import { MessageLog } from '../../resources/messages/messages-types';
import { addDays, subtractDays, toISOString } from '../../utils/date-utils';
import { makeQueryParams } from '../../utils/url-utils';
import CustomChannelMessageHistoryListComponent from './custom-channel-history-list';
import './index.scss';

const makeMessageDetailsNavigationSearchParams = (message: MessageLog) => {
  return makeQueryParams({
    id: message.id,
    startDt: message.request?.startDt,
    endDt: message.request?.endDt,
  });
};

const daysAgo = 2;
const daysLater = 1;

const nonEmptyArrayOrUndefined = (entry?: string[] | string): string[] | undefined => {
  if (typeof entry === 'string') return [entry];
  if (Array.isArray(entry) && entry.length > 0) return entry;
};

const queryParamsToState = (
  filterParams: QueryParams,
): {
  filters: Filters;
  pagination: {
    offset: number;
    limit: number;
  };
} => {
  if (Object.keys(filterParams).length === 0) {
    return {
      filters: {
        startDt: toISOString(subtractDays(new Date(), daysAgo)),
        endDt: toISOString(addDays(new Date(), daysLater)),
        search: '',
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
    };
  }

  return {
    filters: {
      startDt: (filterParams.startDate as string) ?? toISOString(subtractDays(new Date(), daysAgo)),
      endDt: (filterParams.endDate as string) ?? toISOString(addDays(new Date(), daysLater)),
      channels: nonEmptyArrayOrUndefined(filterParams.channels) as CustomChannelChannel[],
      statuses: nonEmptyArrayOrUndefined(filterParams.statuses) as CustomChannelMessageStatus[],
      search: (filterParams.search as string) ?? '',
      messageTypes: nonEmptyArrayOrUndefined(filterParams.messageTypes) as CustomChannelMessageType[],
    },
    pagination: {
      limit: filterParams.limit ? parseInt(filterParams.limit as string) : 10,
      offset: filterParams.offset ? parseInt(filterParams.offset as string) : 0,
    },
  };
};

const stateToQueryParams = (state: {
  filters: Filters;
  pagination: {
    offset: number;
    limit: number;
  };
}): QueryParams => {
  return {
    startDate: state.filters.startDt,
    endDate: state.filters.endDt,
    search: state.filters.search ?? '',
    statuses: state.filters.statuses ?? [],
    channels: state.filters.channels ?? [],
    messageTypes: state.filters.messageTypes ?? [],
    limit: state.pagination.limit.toString(),
    offset: state.pagination.offset.toString(),
  };
};

export default function CustomChannelHistoryComponent() {
  const { messageLogs, fetchMessages } = useMessages();
  const navigate = useNavigate();
  const { selectedMessage, messageDetails, handleMessageBackClick } = useCustomChannelHistoryList();
  const [filterParams, setFilterParams] = useQueryParams();
  const state = useMemo(() => queryParamsToState(filterParams), [filterParams]);
  const [filters, setFilters] = useState<Filters>(state.filters);

  const abortMessage = useRef<VoidFunction>();

  useEffect(() => {
    setFilters(state.filters);
  }, [state.filters]);

  const fetchNewMessages = useCallback(() => {
    abortMessage.current?.();

    const abortFunctions = fetchMessages({
      ...state.filters,
      startDt: state.filters.startDt ? state.filters.startDt + 'T00:00:00-00:00' : undefined,
      endDt: state.filters.endDt ? state.filters.endDt + 'T23:59:59-00:00' : undefined,
      page: {
        limit: state.pagination.limit,
        offset: state.pagination.offset,
      },
    });

    abortMessage.current = abortFunctions[0];
  }, [fetchMessages, state]);

  useEffect(() => {
    fetchNewMessages();
  }, [fetchNewMessages]);

  const handleSubmit = useCallback(() => {
    setFilterParams(
      stateToQueryParams({
        filters: filters,
        pagination: {
          limit: state.pagination.limit,
          // if any filters are change the page should go back to the first one to display results
          offset: JSON.stringify(state.filters) !== JSON.stringify(filters) ? 0 : state.pagination.offset,
        },
      }),
    );
  }, [filters, setFilterParams, state]);

  const loadPage = useCallback(
    (page: Page) => {
      return setFilterParams(
        stateToQueryParams({
          filters: filters,
          pagination: page,
        }),
      );
    },
    [setFilterParams, filters],
  );

  const handlePageChanges = useCallback(
    (page: number) => {
      setFilterParams(
        stateToQueryParams({
          filters: filters,
          pagination: { limit: state.pagination.limit, offset: page * state.pagination.limit },
        }),
      );
    },
    [filters, setFilterParams, state.pagination.limit],
  );

  const makeNavToDetailsLink = useCallback((message: MessageLog): string => {
    return '/custom-channel-message-history/details' + makeMessageDetailsNavigationSearchParams(message);
  }, []);

  const handleDetailsClick = (message: MessageLog) => {
    navigate({
      pathname: `/custom-channel-message-history/details`,
      search: makeMessageDetailsNavigationSearchParams(message),
    });
  };

  const handleFiltersChange = useCallback((newFilters: Filters) => {
    setFilters(newFilters);
  }, []);
  return (
    <TealiumDataLayer
      page_name="Custom Channel History"
      page_section="custom-channel-history"
      page_category="Historic Data"
      page_subCategory="Custom Channel Historic Data">
      <Container className="custom-channel-message-history">
        <Card style={{ display: selectedMessage ? 'none' : 'flex' }}>
          <Card.Body>
            <Row className="title">
              <Col>
                <Typography variant="heading">Custom Channel History</Typography>
                <Typography weight="bold" color="text-gray" className="mt-3">
                  Search and view the details of the API executions below.
                </Typography>
              </Col>
              <Col>
                <Col md={2} className="ms-auto">
                  <IconButton onClick={fetchNewMessages} styleType="link">
                    REFRESH
                  </IconButton>
                </Col>
              </Col>
            </Row>
            <CustomChannelHistoryFormComponent
              onSubmit={handleSubmit}
              filters={filters}
              onFiltersChange={handleFiltersChange}
              submitOnLoad
              daysRange={isPROD ? undefined : 100}
            />
            <CustomChannelMessageHistoryListComponent
              response={messageLogs.loading ? null : messageLogs.data}
              loadPage={loadPage as any}
              limit={state.pagination.limit}
              timedout={messageLogs.error?.statusCode === 408}
              makeNavLink={makeNavToDetailsLink}
              error={!!messageLogs.error && messageLogs.error?.statusCode !== 408}
              selectedPage={Math.round(state.pagination.offset / state.pagination.limit)}
              setSelectedPage={handlePageChanges}
              onDetailsClick={handleDetailsClick}
            />
          </Card.Body>
        </Card>
        {selectedMessage && (
          <CustomChannelExecutionHistoryDetailsCardComponent
            message={selectedMessage}
            details={messageDetails}
            onBackClick={handleMessageBackClick}
          />
        )}
      </Container>
    </TealiumDataLayer>
  );
}
