import { CSSProperties } from 'react';
import { shortenUUID } from '../../utils/execution-utils';
import { ProcessorLog, RetryProcess } from '../history-list/history-list-types';
import { NotificationProps } from './notification-types';

export function getNotificationProps(status: RetryProcess, executions: ProcessorLog[] = []): NotificationProps {
  let header: string;
  let bg: string;
  let body: string;
  let bodyStyle: CSSProperties | undefined;

  switch (status) {
    case RetryProcess.OK:
      header = 'Success!';
      bg = 'success';
      body = 'Reprocessed execution(s): ';
      break;
    case RetryProcess.MESSAGE_NOT_FOUND:
    case RetryProcess.PROCESSOR_NOT_FOUND:
    case RetryProcess.EXECUTION_NOT_FOUND:
    case RetryProcess.FAILED:
      header = 'Error!';
      bg = 'danger';
      body = status.replaceAll('_', ' ').toLowerCase() + ': ';
      bodyStyle = { textTransform: 'capitalize' };
      break;
  }

  body += executions.map(({ executionId }) => shortenUUID(executionId)).join(', ');

  return {
    header,
    bg,
    body,
    bodyStyle,
  };
}
