import Chips from '../../../../ui/chips';
import Switch from '../../../../ui/switch';
import './index.scss';

export interface Props {
  permissionFeat: {
    label: string;
    active: boolean;
    features: string[];
    onToggleActive: (active: boolean) => void;
    readonly: boolean;
  };
}

const PermissionFeatures: React.FC<Props> = props => {
  const {
    permissionFeat: { label, active, features, readonly, onToggleActive },
  } = props;

  return (
    <article data-disabled={!active} className={`permission-features-container`}>
      <section>
        <Switch checked={active} onChange={!readonly ? e => onToggleActive(e.target.checked) : () => {}} />
      </section>
      <section>
        <p>{label}</p>
      </section>
      <Chips
        chips={features.map(feat => ({
          title: feat,
          value: feat,
        }))}
      />
    </article>
  );
};

export default PermissionFeatures;
