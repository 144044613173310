import { Typography } from '@amway/react-components';
import { useCallback, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import TealiumDataLayer from '../../components/hocs/tealium-data-layer';
import IconButton from '../../components/ui/icon-btn';
import useAllowList from '../../resources/allow-list/allow-list-hook';
import { AllowListRule } from '../../resources/allow-list/allow-list-model';
import AllowListAddComponent from './allow-list-add';
import AllowListEditComponent from './allow-list-edit';
import AllowListPaginatedListComponent from './allow-list-paginated-list';
import './index.scss';

export default function AllowListComponent() {
  const { allowListRules, removeRuleStatus, fetchAllowListRules, removeRule } = useAllowList();
  const [rule, setRule] = useState<AllowListRule | undefined | null>();

  useEffect(() => {
    if (removeRuleStatus.success) {
      fetchAllowListRules();
    }
  }, [fetchAllowListRules, removeRuleStatus.success]);

  useEffect(() => {
    fetchAllowListRules();
  }, [fetchAllowListRules]);

  const handleAddClick = useCallback(() => {
    setRule(null);
  }, []);

  const handleEditClick = useCallback((rule: AllowListRule) => {
    setRule(rule);
  }, []);

  const handleDeleteClick = useCallback(
    (rule: AllowListRule) => {
      removeRule(rule.id!);
    },
    [removeRule],
  );

  const handleBackClick = useCallback(() => {
    setRule(undefined);
    fetchAllowListRules();
  }, [fetchAllowListRules]);

  return (
    <TealiumDataLayer
      page_name="Allow List"
      page_section="allow-list"
      page_category="Configuration"
      page_subCategory="Approved Email Pattern List">
      <Container className="allow-list">
        {rule === undefined ? (
          <Card>
            <Card.Body>
              <Row>
                <Col md={10}>
                  <Typography variant="heading">Allow List</Typography>
                  <Typography weight="bold" color="text-gray" className="mt-3">
                    View and update allow list rules below.
                  </Typography>
                </Col>
                <Col md={2} className="d-flex justify-content-end">
                  <IconButton onClick={handleAddClick} styleType="link">
                    NEW RULE
                  </IconButton>
                </Col>
                <Col></Col>
              </Row>
              <AllowListPaginatedListComponent
                allowListRules={allowListRules}
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick}
              />
            </Card.Body>
          </Card>
        ) : rule === null ? (
          <AllowListAddComponent onBackClick={handleBackClick} />
        ) : (
          <AllowListEditComponent rule={rule} onBackClick={handleBackClick} />
        )}
      </Container>
    </TealiumDataLayer>
  );
}
