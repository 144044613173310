import { createContext } from 'react';
import { NotificationProps } from './notification-types';

export interface NotificationContextType {
  pushNotification: (notification: NotificationProps) => void;
}

const NotificationContext = createContext<NotificationContextType>(null!);

export default NotificationContext;
