import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBarComponent from '../navbar';
import './index.scss';

const GuestLayout: React.FC = () => {
  return (
    <>
      <NavBarComponent />
      <div className="guest-aside-main">
        <main>
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default GuestLayout;
