import { Image } from 'react-bootstrap';
import Loading from '../loading';
import './index.scss';

const LogoLoading: React.FC = () => {
  return (
    <div className="logo-loading-component-container">
      <Image src="/assets/amway-login-logo.svg" width="49" height="55" />
      <Loading />
    </div>
  );
};

export default LogoLoading;
