import { ReactNode, useCallback, useState } from 'react';
import { Features } from '../../config/features';
import { makeExternalDataInitialData, makeExternalDataSuccessData } from '../../helpers/external-data';
import { WithFeaturesProxy } from '../../helpers/with-features-proxy';
import SystemsHealthContext, { SystemsHealthCtxType } from './systems-health-context';
import systemsHealthService from './systems-health.service';

interface ExposedProps {
  children: ReactNode;
}

interface Props extends ExposedProps {
  hasAmwayProfile: boolean;
  hasExponea: boolean;
  hasOrderLookupAPI: boolean;
  hasProductInfoAPI: boolean;
  hasVolumeAPI: boolean;
  hasUplines: boolean;
  hasDocgen: boolean;
  hasPublisherAPI: boolean;
}

const SystemsHealthProvider = ({
  children,
  hasAmwayProfile,
  hasExponea,
  hasOrderLookupAPI,
  hasProductInfoAPI,
  hasVolumeAPI,
  hasUplines,
  hasDocgen,
  hasPublisherAPI,
}: Props) => {
  const [systemsHealth, setSystemsHealth] = useState<SystemsHealthCtxType['systemsHealth']>({
    self: makeExternalDataInitialData(),
    amwayProfile: makeExternalDataInitialData(),
    uplines: makeExternalDataInitialData(),
    docgen: makeExternalDataInitialData(),
    orderApi: makeExternalDataInitialData(),
    itemApi: makeExternalDataInitialData(),
    volumeApi: makeExternalDataInitialData(),
    exponea: makeExternalDataInitialData(),
    publisherApi: makeExternalDataInitialData(),
  });

  const fetchSystemsHealth = useCallback(async () => {
    try {
      setSystemsHealth({
        self: makeExternalDataInitialData(),
        amwayProfile: makeExternalDataInitialData(),
        uplines: makeExternalDataInitialData(),
        docgen: makeExternalDataInitialData(),
        orderApi: makeExternalDataInitialData(),
        itemApi: makeExternalDataInitialData(),
        volumeApi: makeExternalDataInitialData(),
        exponea: makeExternalDataInitialData(),
        publisherApi: makeExternalDataInitialData(),
      });

      systemsHealthService
        .getSelfStatus()
        .then(s => setSystemsHealth(prev => ({ ...prev, self: makeExternalDataSuccessData(s) })));
      hasAmwayProfile &&
        systemsHealthService
          .getAmwayProfileStatus()
          .then(s => setSystemsHealth(prev => ({ ...prev, amwayProfile: makeExternalDataSuccessData(s) })));
      hasExponea &&
        systemsHealthService
          .getExponeaStatus()
          .then(s => setSystemsHealth(prev => ({ ...prev, exponea: makeExternalDataSuccessData(s) })));
      hasOrderLookupAPI &&
        systemsHealthService
          .getOrderApiStatus()
          .then(s => setSystemsHealth(prev => ({ ...prev, orderApi: makeExternalDataSuccessData(s) })));
      hasProductInfoAPI &&
        systemsHealthService
          .getItemApiStatus()
          .then(s => setSystemsHealth(prev => ({ ...prev, itemApi: makeExternalDataSuccessData(s) })));
      hasVolumeAPI &&
        systemsHealthService
          .getVolumeApiStatus()
          .then(s => setSystemsHealth(prev => ({ ...prev, volumeApi: makeExternalDataSuccessData(s) })));
      hasDocgen &&
        systemsHealthService
          .getDocgenStatus()
          .then(s => setSystemsHealth(prev => ({ ...prev, docgen: makeExternalDataSuccessData(s) })));
      hasUplines &&
        systemsHealthService
          .getUplinesStatus()
          .then(s => setSystemsHealth(prev => ({ ...prev, uplines: makeExternalDataSuccessData(s) })));
      hasPublisherAPI &&
        systemsHealthService
          .getPublishAPIStatus()
          .then(s => setSystemsHealth(prev => ({ ...prev, publisherApi: makeExternalDataSuccessData(s) })));
    } catch (err) {
      console.log('unexpected error');
      console.error(err);
    }
  }, [
    hasAmwayProfile,
    hasDocgen,
    hasExponea,
    hasOrderLookupAPI,
    hasProductInfoAPI,
    hasPublisherAPI,
    hasUplines,
    hasVolumeAPI,
  ]);

  return (
    <SystemsHealthContext.Provider value={{ systemsHealth, fetchSystemsHealth }}>
      {children}
    </SystemsHealthContext.Provider>
  );
};

export default WithFeaturesProxy<ExposedProps>(
  Features.MktFeature_Health_AmwayProfile,
  Features.MktFeature_Health_Exponea,
  Features.MktFeature_Health_OrderLookupAPI,
  Features.MktFeature_Health_ProductInfoAPI,
  Features.MktFeature_Health_VolumeAPI,
  Features.MktFeature_Health_Uplines,
  Features.MktFeature_Health_Docgen,
  Features.MktFeature_Health_PublisherAPI,
)((props, ...hasFeatures) => {
  const [
    hasAmwayProfile,
    hasExponea,
    hasOrderLookupAPI,
    hasProductInfoAPI,
    hasVolumeAPI,
    hasUplines,
    hasDocgen,
    hasPublisherAPI,
  ] = hasFeatures;
  return (
    <SystemsHealthProvider
      {...props}
      hasAmwayProfile={hasAmwayProfile}
      hasExponea={hasExponea}
      hasOrderLookupAPI={hasOrderLookupAPI}
      hasProductInfoAPI={hasProductInfoAPI}
      hasVolumeAPI={hasVolumeAPI}
      hasUplines={hasUplines}
      hasDocgen={hasDocgen}
      hasPublisherAPI={hasPublisherAPI}
    />
  );
});
