import { useCallback, useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataInitialKeepData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import useExternalApiErrorHandler from '../../hooks/use-external-api-error-handler';
import MessagesContext, { MessagesContextType } from './messages-context';
import { MessageLog, MessageLogDetails, MessagesExceptionsResponse, MessagesResponse } from './messages-types';
import messagesService from './messages.service';

const MessageProvider: React.FC = ({ children }) => {
  const errorHandler = useExternalApiErrorHandler();
  const [messageLogDetails, setMessageLogDetails] = useState<MessageLogDetails>();
  const [messageLogs, setMessageLogs] = useState<ExternalData<MessagesResponse>>(makeExternalDataInitialData());
  const [messageExceptions, setMessageExceptions] = useState<ExternalData<MessagesExceptionsResponse>>(
    makeExternalDataInitialData(),
  );

  const resetExecution = () => {
    setMessageLogDetails(undefined);
  };

  const convertMessageLogToDetails = (messageLog: MessageLog): MessageLogDetails => {
    return {
      executionId: messageLog?.executionId,
      externalId: messageLog?.externalId,
      campaignName: messageLog?.campaign_name,
      startDate: messageLog?.createDt,
      endDate: messageLog?.updateDt,
      payload: messageLog?.payload,
      msg: messageLog?.msg,
      errorMsg: messageLog?.errorMsg,
      errorCode: messageLog?.errorCode,
      recipient: messageLog?.recipient_id,
      sendDate: messageLog?.send_date,
      updateDate: messageLog?.updateDt,
      registered: messageLog?.registered,
      channel: messageLog?.channel,
      status: messageLog?.status,
    };
  };

  const fetchMessages: MessagesContextType['fetchMessages'] = useCallback(
    req => {
      const { abort, promise } = messagesService.getMessageHistory(req);

      setMessageLogs(makeExternalDataInitialData());

      promise
        .then(data => {
          setMessageLogs(makeExternalDataSuccessData(data));
        })
        .catch(err => {
          setMessageLogs(makeExternalCallErrorData(err));
          errorHandler(err, {
            cancel: () => {
              console.log('axios request cancelled', err.message);
              setMessageLogs(makeExternalDataInitialData());
            },
          });
        });

      return [abort];
    },
    [errorHandler],
  );

  const fetchMessageExceptions: MessagesContextType['fetchMessageExceptions'] = useCallback(
    req => {
      const { abort, promise } = messagesService.getMessageExceptions(req);

      setMessageExceptions(prev => makeExternalDataInitialKeepData(prev, abort));

      promise
        .then(data => {
          setMessageExceptions(makeExternalDataSuccessData(data));
        })
        .catch(err => {
          setMessageExceptions(makeExternalCallErrorData(err));
          errorHandler(err, {
            cancel: () => {
              console.log('axios request cancelled', err.message);
              setMessageExceptions(makeExternalDataInitialData());
            },
          });
        });

      return abort;
    },
    [errorHandler],
  );

  const fetchMessagesDetails: MessagesContextType['fetchMessagesDetails'] = useCallback(
    req => {
      let messageLog: MessageLog | undefined = messageLogs.data?.messages[req.id!];
      if (!messageLog) {
        const { promise } = messagesService.getMessageDetails({
          id: req.id,
          startDt: req.startDt,
          endDt: req.endDt,
        });
        promise.then(data => {
          setMessageLogDetails(convertMessageLogToDetails(data));
        });
      } else {
        setMessageLogDetails(convertMessageLogToDetails(messageLog));
      }
    },
    [messageLogs?.data?.messages],
  );

  return (
    <MessagesContext.Provider
      value={{
        messageLogDetails,
        messageLogs,
        messageExceptions,
        fetchMessages,
        fetchMessageExceptions,
        fetchMessagesDetails,
        resetExecution,
      }}>
      {children}
    </MessagesContext.Provider>
  );
};

export default MessageProvider;
