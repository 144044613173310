import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { timezoneName } from '../../../../../config/env';
import { Features } from '../../../../../config/features';
import { WithFeaturesProxy } from '../../../../../helpers/with-features-proxy';
import { CustomChannelMessageStatus } from '../../../../../resources/custom-channel/custom-channel-history-list-types';
import { MessageLog, MessageLogDetails } from '../../../../../resources/messages/messages-types';
import {
  customChannelTimestampToCorrectTimezone,
  getCustomChannelStatusColor,
} from '../../../../../utils/execution-utils';
import CustomChannelMessageDetailsInfoComponent from '../details-info';
import './index.scss';

interface ExposedProps {
  message: MessageLog;
  statusColor: string;
  details?: MessageLogDetails | null;
}

interface Props extends ExposedProps {
  canReprocess: boolean;
  mktHasResendEmail: boolean;
  mktHasReprocessExecution: boolean;
}

const CustomChannelMessageHistoryDetailsComponent: React.FC<Props> = props => {
  const { statusColor, details } = props;

  return (
    <>
      <CustomChannelMessageDetailsInfoComponent details={details} statusColor={statusColor} />
      <TableContainer>
        <Table sx={{ maxWidth: 'calc(100vw - 104px - 2*24px - 32px - 38px - 19px)' }}>
          <TableHead className="headers">
            <TableRow>
              <TableCell>Recipient #</TableCell>
              <TableCell>Send Date {timezoneName === '' ? '(GMT)' : '(' + timezoneName + ')'}</TableCell>
              <TableCell>Registered</TableCell>
              <TableCell>Channel</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={details?.recipient}>
              <TableCell>{details?.recipient}</TableCell>
              <TableCell>{customChannelTimestampToCorrectTimezone(details?.sendDate!)}</TableCell>
              <TableCell>{details?.registered}</TableCell>
              <TableCell>{details?.channel}</TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  color: getCustomChannelStatusColor(details?.status as CustomChannelMessageStatus),
                }}>
                {details?.status}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default WithFeaturesProxy<ExposedProps>(
  Features.ReprocessEmail,
  Features.MktFeature_ReprocessExecution,
  Features.MktFeature_ResendEmail,
)((props, canReprocess, mktHasReprocessExecution, mktHasResendEmail) => {
  return (
    <CustomChannelMessageHistoryDetailsComponent
      {...props}
      canReprocess={canReprocess}
      mktHasReprocessExecution={mktHasReprocessExecution}
      mktHasResendEmail={mktHasResendEmail}
    />
  );
});
