import React, { useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import AuthenticatedLayout from '../components/core/authenticated-layout';
import { authenticatedRoutes, RouteData } from '../config/routes';
import { FeaturesCtxType } from '../resources/features/features-context';
import useFeatures from '../resources/features/features-hook';
import LoadingScreen from '../screens/loading';
import NotFoundComponent from '../screens/not-found';
import { resolveLocationFromState } from '../utils/location-utils';

const filterAllowedRoutes = (hasFeature: FeaturesCtxType['hasFeature']): RouteData[] => {
  return authenticatedRoutes.filter(route => hasFeature(route.feature));
};

const AuthenticatedRouter = () => {
  const location = useLocation();
  const { loading: permissionsAreLoading, hasFeature } = useFeatures();
  const allowedRoutes = useMemo(() => filterAllowedRoutes(hasFeature), [hasFeature]);

  if (permissionsAreLoading) {
    return <LoadingScreen />;
  }

  return (
    <Routes>
      <Route path="/" element={<AuthenticatedLayout />}>
        <Route path="" element={<Navigate to="/dashboard" replace />} />
        {allowedRoutes.map(route => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        <Route path="login" element={<Navigate to={resolveLocationFromState(location)} replace={true} />} />
        <Route path="/logout/callback" element={<Navigate to="/dashboard" replace />} />
        <Route path="login/callback" element={<Navigate to={resolveLocationFromState(location)} replace={true} />} />
        <Route path="*" element={<NotFoundComponent />} />
      </Route>
    </Routes>
  );
};

export default AuthenticatedRouter;
