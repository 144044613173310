export const downloadContent = (title: string, content: string) => {
  const url = URL.createObjectURL(new Blob([content], { type: 'text/plain' }));
  const link = document.createElement('a');
  link.download = title;
  link.href = url;
  link.click();
};

export const downloadCSV = (title: string, content: string): void => {
  const anchor = document.createElement('a');
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  anchor.href = url;
  anchor.setAttribute('download', title);
  anchor.click();
};
