import { apiWrapper } from './../../config/api-wrapper';
import { processorsMapper } from './processors-mapper';
import { Processor } from './processors-model';

class ProcessorsService {
  public list(): Promise<Processor[]> {
    return apiWrapper.adminApi.get('/processor').then(res => processorsMapper(res.data));
  }

  public getProcessorSteps(processorId: number): Promise<String[]> {
    return apiWrapper.adminApi.get(`/processor/steps/${processorId}`).then(res => res.data);
  }
}

export default new ProcessorsService();
