import { Image, Toast } from 'react-bootstrap';
import { NotificationProps } from '../../../resources/notification/notification-types';
import './index.scss';

export default function NotificationComponent({
  id,
  header,
  bg,
  show,
  onClose,
  children,
  bodyStyle,
}: NotificationProps) {
  return (
    <Toast id={id} show={show} onClose={onClose} bg={bg}>
      <Toast.Header>
        <Image src="/assets/amway-login-logo.svg" width="21" height="23" className="me-2" />
        <strong className="me-auto">{header}</strong>
      </Toast.Header>
      <Toast.Body style={bodyStyle}>{children}</Toast.Body>
    </Toast>
  );
}
