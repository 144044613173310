import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import environment from '../../config/env';
import { toISODateString } from '../../utils/date-utils';
import { apiWrapper } from './../../config/api-wrapper';
import { createExecutionResponseMapper, createProcessorStepLogListMapper } from './executions-mapper';
import {
  CreateProcessorExecutionRequest,
  CreateProcessorExecutionResponse,
  ExecutionDetails,
  ExecutionDetailsRequest,
  ExecutionRequest,
  ExecutionsResponse,
  ProcessorStepLog,
  PublisherApiCreateProcessorExecutionResponse,
} from './executions-types';

const makeExecutionDetailsCallInput = (request: ExecutionDetailsRequest) => {
  const { executionId, processorName, application, startDt, endDt } = request;
  const startDate = startDt ? toISODateString(startDt) + 'T00:00:00.001' : undefined;
  const endDate = endDt ? toISODateString(endDt) + 'T23:59:59.999' : undefined;
  // const commsHubProcessorName = 'Outbound Comms processor';

  // if (processorName === commsHubProcessorName || sharedId !== undefined) {
  //   return { path: `/execution-history/shared/${sharedId}`, params: { application, startDate, endDate } };
  // }

  return { path: `/execution-history/${executionId}`, params: { processorName, application, startDate, endDate } };
};

class ExecutionsService {
  public create(req: CreateProcessorExecutionRequest): Promise<CreateProcessorExecutionResponse> {
    // I cant refactor this strange code because we might be relying on this strange logic
    // This isNAOnly flag is used to active the commshub integration, right now is only used on NA
    // const config = (isPROD ? isNA : undefined) !== undefined ? { params: { isNAOnly: true } } : undefined;

    return apiWrapper.api
      .post(`api/${environment.MARKET.toLowerCase()}/test/message/process`, req)
      .then(res => createExecutionResponseMapper(res.data));
  }

  public async createOnPublisherApi(req: CreateProcessorExecutionRequest): Promise<CreateProcessorExecutionResponse> {
    const messageId = await apiWrapper.api
      .post(`/publisher/test/message/receive`, req, {
        // TEMPORARY: the Publisher API is using a temporary hard coded token
        headers: { Authorization: 'Bearer 1c3dc474-f66e-11ed-b67e-0242ac120002' },
      })
      .then(res => (res.data as PublisherApiCreateProcessorExecutionResponse).messageId);

    let executionId = '';
    let processor = '';
    let attempts = 0;
    // since we just get the messageId from the publisherAPI, we need to search for the execution by it and get the executionId
    // we wait 10 seconds for the execution to be created before do the search
    while (attempts++ < 3 && executionId.length === 0) {
      // eslint-disable-next-line no-loop-func
      await new Promise<void>(resolve => {
        setTimeout(() => {
          const { promise } = this.getExecutionHistory({ searchText: messageId });
          promise.then(({ executions }) => {
            if (executions.length > 0) {
              executionId = executions[0].executionId ?? '';
              processor = executions[0].processorName ?? '';
            }
            resolve();
          });
        }, 10000);
      });
    }
    return { executionId, processor };
  }

  public getExecutionHistory(
    request: ExecutionRequest
  ): WithAbortFn<Promise<ExecutionsResponse>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi
        .post<ExecutionRequest>(
          'execution-history',
          { ...request },
          {
            params: { application: environment.MARKET },
            cancelToken: source.token,
          },
        )
        .then(res => res.data as ExecutionsResponse)
        .then(response => {
          response.executions.forEach(execution => {
            execution.request = request;
          });

          return response;
        }),
      abort: source.cancel,
    };
  }

  public getExecutionDetails(filters: ExecutionDetailsRequest): Promise<ExecutionDetails> {
    const { path, params } = makeExecutionDetailsCallInput(filters);

    return apiWrapper.adminApi.get(path, { params }).then(res => res.data);
  }

  public getExecutionCompletedSteps(executionId: string): Promise<ProcessorStepLog[]> {
    return apiWrapper.adminApi
      .get(`execution-history/steps/${executionId}`)
      .then(res => createProcessorStepLogListMapper(res.data));
  }
}

export default new ExecutionsService();
