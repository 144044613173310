import { AxiosError } from 'axios';
import { ReactNode, useCallback, useState } from 'react';
import ExecutionEmailPreviewModalComponent from '../../components/shared/execution/email-preview-modal';
import ResendEmailModalComponent, { EmailResendFormFields } from '../../components/shared/execution/resend-email-modal';
import { Features } from '../../config/features';
import { WithFeaturesProxy } from '../../helpers/with-features-proxy';
import adminService from '../../service/admin.service';
import executorService from '../../service/executor.service';
import useNotification from '../notification/notification-hook';
import { getNotificationProps } from '../notification/notification-utils';
import HistoryListContext, { HistoryListContextType } from './history-list-context';
import { ExecutionDetails, ProcessorLog, RetryProcess, TransactionalEmail } from './history-list-types';

interface ExposedProps {
  children: ReactNode;
}

interface Props extends ExposedProps {
  mktHasEmailPreview: boolean;
  mktHasResendEmail: boolean;
  mktHasReprocessExecution: boolean;
}

function HistoryListProvider({ children, mktHasEmailPreview, mktHasResendEmail, mktHasReprocessExecution }: Props) {
  const { pushNotification } = useNotification();
  const [selectedExecution, setSelectedExecution] = useState<ProcessorLog | null>();
  const [details, setDetails] = useState<ExecutionDetails | null>();
  const [showModalEmailPreview, setShowModalEmailPreview] = useState<boolean>(false);
  const [showModalResendEmail, setShowModalResendEmail] = useState<boolean>(false);
  const [transactionalEmail, setTransactionalEmail] = useState<TransactionalEmail | null>();
  const [resendingExecution, setResendingExecution] = useState<ProcessorLog | null>();

  const getTransactionalEmail = useCallback((execution?: ProcessorLog) => {
    if (execution) {
      return adminService.getTransactionalEmail(execution);
    }
  }, []);

  const handleDetailsClick = useCallback((execution: ProcessorLog) => {
    setSelectedExecution(execution);
    adminService.setExecutionDetails(execution, setDetails);
  }, []);

  const handleBackClick = useCallback(() => {
    setSelectedExecution(null);
    setDetails(null);
  }, []);

  const togglePreviewEmailModal = useCallback(
    (execution?: ProcessorLog) => {
      if (
        !showModalEmailPreview &&
        execution &&
        (!transactionalEmail || execution.executionId !== transactionalEmail.executionId)
      ) {
        setTransactionalEmail(null);
        getTransactionalEmail(execution)?.then(setTransactionalEmail);
      }

      setShowModalEmailPreview(!showModalEmailPreview);
    },
    [getTransactionalEmail, showModalEmailPreview, transactionalEmail],
  );

  const updateNotification = useCallback(
    (status: RetryProcess, executions: ProcessorLog[]) => {
      const notification = getNotificationProps(status, executions);

      executions.forEach(execution => {
        execution.resending = false;
      });

      pushNotification(notification);
    },
    [pushNotification],
  );

  const retryExecution = useCallback(
    (execution?: ProcessorLog | null, overwriteEmail?: string, overwritePhone?: string, overwriteLanguage?: string) => {
      if (execution?.executionId) {
        executorService
          .retryExecution(
            execution.executionId,
            execution.startDt,
            execution.endDt,
            overwriteEmail,
            overwritePhone,
            overwriteLanguage,
          )
          .then(status => updateNotification(status, [execution]))
          .catch((err: AxiosError) => {
            const retryProcess: RetryProcess = err.response?.data?.error ?? RetryProcess.FAILED;
            updateNotification(retryProcess, [execution]);
          });
      } else if (execution) {
        execution.resending = false;
      }
    },
    [updateNotification],
  );

  const batchRetryExecutions = useCallback(
    (executions: ProcessorLog[] = []) => {
      if (executions.length > 0) {
        const { startDate, endDate } = executions[0].request!;

        executorService
          .batchRetryExecutions(
            executions.map(({ executionId }) => executionId as string),
            startDate,
            endDate,
          )
          .then(status => updateNotification(status, executions))
          .catch((err: AxiosError) => {
            const retryProcess: RetryProcess = err.response?.data?.error ?? RetryProcess.FAILED;
            updateNotification(retryProcess, executions);
          });
      }
    },
    [updateNotification],
  );

  const handleResend = useCallback(
    (overwriteEmail?: string, overwritePhone?: string, overwriteLanguage?: string) => {
      const execution = resendingExecution;

      retryExecution(execution, overwriteEmail, overwritePhone, overwriteLanguage);
    },
    [resendingExecution, retryExecution],
  );

  const handleRetry = useCallback(
    (execution: ProcessorLog) => {
      execution.resending = true;
      setResendingExecution(execution);

      if (mktHasResendEmail) {
        setShowModalResendEmail(true);
      } else if (mktHasReprocessExecution) {
        retryExecution(execution);
      }
    },
    [mktHasReprocessExecution, mktHasResendEmail, retryExecution],
  );

  const handleBatchRetry = useCallback(
    (executions: ProcessorLog[] = []) => {
      executions.forEach(execution => {
        execution.resending = true;
      });
      setResendingExecution(executions[0]);

      batchRetryExecutions(executions);
    },
    [batchRetryExecutions],
  );

  const handleModalConfirmation = useCallback(
    ({ overwriteEmail, overwritePhone, overwriteLanguage }: EmailResendFormFields) => {
      handleResend(overwriteEmail, overwritePhone, overwriteLanguage);
    },
    [handleResend],
  );

  const closeModal = useCallback(() => {
    setShowModalResendEmail(false);
  }, []);

  const handleModalCancel = useCallback(() => {
    if (resendingExecution) {
      resendingExecution.resending = false;
    }

    closeModal();
  }, [resendingExecution, closeModal]);

  const value: HistoryListContextType = {
    selectedExecution,
    details,
    handleBackClick,
    handleDetailsClick,
    handleRetry,
    handleBatchRetry,
    togglePreviewEmailModal,
  };

  return (
    <HistoryListContext.Provider value={value}>
      {children}
      {mktHasEmailPreview && (
        <ExecutionEmailPreviewModalComponent
          show={showModalEmailPreview}
          onHide={togglePreviewEmailModal}
          transactionalEmail={transactionalEmail}
        />
      )}
      {mktHasResendEmail && (
        <ResendEmailModalComponent
          execution={resendingExecution}
          show={showModalResendEmail}
          close={closeModal}
          onHide={handleModalCancel}
          onCancel={handleModalCancel}
          onConfirm={handleModalConfirmation}
        />
      )}
    </HistoryListContext.Provider>
  );
}

export default WithFeaturesProxy<ExposedProps>(
  Features.MktFeature_EmailPreview,
  Features.MktFeature_ReprocessExecution,
  Features.MktFeature_ResendEmail,
)((props, mktHasEmailPreview, mktHasReprocessExecution, mktHasResendEmail) => {
  return (
    <HistoryListProvider
      {...props}
      mktHasEmailPreview={mktHasEmailPreview}
      mktHasReprocessExecution={mktHasReprocessExecution}
      mktHasResendEmail={mktHasResendEmail}
    />
  );
});
