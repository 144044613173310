import { AuthState, OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiWrapper } from '../../config/api-wrapper';
import { acdpGroupsPrefix } from '../../config/env';
import oktaAuth from '../../config/okta-auth';
import AuthContext from './auth-context';
import { SingInOptions } from './auth-types';
import authService from './auth.service';
import { User } from './user-model';

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | undefined>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  const signIn = useCallback(async (options: SingInOptions): Promise<void> => {
    void oktaAuth.signInWithRedirect(options);
  }, []);

  const signOut = useCallback((): void => {
    void oktaAuth.signOut();
  }, []);

  const onOktaAuthChanges = useCallback(async (authState: AuthState) => {
    try {
      if (!authState.isAuthenticated) throw new Error('Not authenticated');

      apiWrapper.setOktaThing(
        authState.accessToken!.accessToken,
        authState.idToken!.idToken,
        authState.idToken!.claims.nonce,
      );
      setIsAuthenticated(true);
      const user = (await oktaAuth.token.getUserInfo()) as any;
      let userGroup;
      try {
        userGroup = (user[acdpGroupsPrefix][0] as string) ?? '';
      } catch {
        userGroup = '';
      }
      const { nativeid, fullName, email } = user;
      setUser({ id: nativeid, name: fullName as string, email: email as string, userGroup });
    } catch {
      setIsAuthenticated(false);
      setUser(undefined);
      apiWrapper.clearOktaThing();
    }
  }, []);

  const handleRestoreOriginalUri = useCallback(
    (_oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
    },
    [navigate],
  );
  useEffect(() => {
    // TODO: delete these logs
    oktaAuth.authStateManager.subscribe((authState: AuthState) => {
      onOktaAuthChanges(authState);
      console.log('authClient authStateManager subscribe authState', authState);
      authService.oktaAuthState = authState;
    });

    // TODO: refactor this code below to look better
    console.log('updating auth state');
    oktaAuth.authStateManager.updateAuthState().then(() => {
      oktaAuth.tokenManager.getTokens().then(({ accessToken, idToken }) => {
        // handle accessToken and idToken
        console.log('updated auth state accessToken', accessToken);
        console.log('updated auth state idToken', idToken);
      });
    });

    return () => {
      console.log('authClient authStateManager unsubscribed');
      oktaAuth.authStateManager.unsubscribe();
    };
  }, [onOktaAuthChanges]);

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, signIn, signOut }}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={handleRestoreOriginalUri}>
        {children}
      </Security>
    </AuthContext.Provider>
  );
}
