import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { SystemHealthStatus } from './systems-health-model';

export interface SystemsHealthCtxType {
  systemsHealth: {
    self: ExternalData<SystemHealthStatus>;
    amwayProfile: ExternalData<SystemHealthStatus>;
    uplines: ExternalData<SystemHealthStatus>;
    docgen: ExternalData<SystemHealthStatus>;
    orderApi: ExternalData<SystemHealthStatus>;
    itemApi: ExternalData<SystemHealthStatus>;
    volumeApi: ExternalData<SystemHealthStatus>;
    exponea: ExternalData<SystemHealthStatus>;
    publisherApi: ExternalData<SystemHealthStatus>;
  };
  fetchSystemsHealth: VoidFunction;
}

const SystemsHealthContext = createContext<SystemsHealthCtxType>(null as any);

export default SystemsHealthContext;
