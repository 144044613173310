export default function NoExceptionsFoundIcon() {
  return (
    <svg width="120" height="121" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60.0002 2.85547C44.7504 2.85547 30.1253 8.9134 19.3421 19.6966C8.55891 30.4798 2.50098 45.1049 2.50098 60.3547C2.50098 75.6044 8.55891 90.2296 19.3421 101.013C30.1253 111.796 44.7504 117.854 60.0002 117.854C75.2499 117.854 89.8751 111.796 100.658 101.013C111.441 90.2296 117.499 75.6044 117.499 60.3547C117.499 45.1049 111.441 30.4798 100.658 19.6966C89.8751 8.9134 75.2499 2.85547 60.0002 2.85547Z"
        fill="var(--secondary)"
      />
      <path
        d="M60.0002 13.5547C53.8543 13.5547 47.7687 14.7652 42.0906 17.1171C36.4126 19.469 31.2534 22.9163 26.9076 27.2621C22.5618 31.6079 19.1146 36.7671 16.7626 42.4451C14.4107 48.1231 13.2002 54.2088 13.2002 60.3547C13.2002 66.5005 14.4107 72.5862 16.7626 78.2643C19.1146 83.9423 22.5618 89.1015 26.9076 93.4473C31.2534 97.7931 36.4126 101.24 42.0906 103.592C47.7687 105.944 53.8543 107.155 60.0002 107.155C66.1461 107.155 72.2317 105.944 77.9098 103.592C83.5878 101.24 88.747 97.7931 93.0928 93.4473C97.4386 89.1015 100.886 83.9423 103.238 78.2643C105.59 72.5862 106.8 66.5005 106.8 60.3547C106.8 54.2088 105.59 48.1231 103.238 42.4451C100.886 36.7671 97.4386 31.6079 93.0928 27.2621C88.747 22.9163 83.5878 19.469 77.9098 17.1171C72.2317 14.7652 66.1461 13.5547 60.0002 13.5547Z"
        fill="#f0f8f4"
      />
      <path
        d="M37.6666 55.0869C36.625 55.0869 35.6458 55.4925 34.909 56.2293C34.1722 56.9661 33.7666 57.9453 33.7666 58.9869C33.7666 60.0285 34.1722 61.0077 34.909 61.7445L51.709 78.5445C52.4458 79.2813 53.425 79.6869 54.4666 79.6869V80.5869L54.541 79.6869C55.609 79.6653 56.6266 79.2045 57.3418 78.4245L83.7418 49.6245C84.445 48.8565 84.8074 47.8605 84.7642 46.8189C84.7186 45.7773 84.2698 44.8197 83.5042 44.1141C82.7338 43.4109 81.757 43.0341 80.6986 43.0917C79.6594 43.1373 78.6994 43.5837 77.9962 44.3517L54.3466 70.1493L40.4266 56.2293C39.6874 55.4925 38.7082 55.0869 37.6666 55.0869Z"
        fill="var(--secondary)"
      />
    </svg>
  );
}
