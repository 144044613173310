import { Permission } from './features-types';

export const displayPermissionsGracefully = (permissions: Permission[]) => {
  const collator = new Intl.Collator('en');
  return permissions.slice().sort(collator.compare).join(', ').concat('.');
};

export const displayFeaturesGracefully = (features: string[]) => {
  const collator = new Intl.Collator('en');
  return features.slice().sort(collator.compare).join(', ').concat('.');
};
