import { createContext } from 'react';
import { SingInOptions } from './auth-types';
import { User } from './user-model';

export interface AuthContextType {
  user?: User;
  isAuthenticated: boolean | null;
  signIn: (option: SingInOptions) => void;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextType>(null!);

export default AuthContext;
