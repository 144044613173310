import { useEffect, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { daysDiff } from '../../../utils/date-utils';
import DatePickerComponent from '../datepicker';

type Props = {
  styleType?: 'default' | 'filter';
  startDate?: string;
  setStartDate: (value: string) => void;
  endDate?: string;
  setEndDate: (value: string) => void;
  minDate?: string;
  maxDate?: string;
  daysRange?: number;
  exceedsDaysRange?: boolean;
  setExceedsDaysRange?: (exceeds: boolean) => void;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
};

export default function DateRangeFieldsComponent({
  styleType = 'default',
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  minDate,
  maxDate,
  daysRange,
  exceedsDaysRange,
  setExceedsDaysRange,
  sm = 6,
  md = 3,
  lg = 3,
  xl = 3,
  xxl = 2,
}: Props) {
  const feedbackMessage = useMemo(
    () => (exceedsDaysRange ? `The days range shouldn't exceed ${daysRange} days` : null),
    [daysRange, exceedsDaysRange],
  );

  useEffect(() => {
    if (daysRange && startDate && endDate) {
      const startDateTime = new Date(startDate + 'T00:00:00.000');
      const endDateTime = new Date(endDate + 'T00:00:00.000');
      const exceedsDaysRange = daysDiff(startDateTime, endDateTime) > daysRange;

      if (setExceedsDaysRange) {
        setExceedsDaysRange(exceedsDaysRange);
      }
    }
  }, [daysRange, startDate, endDate, setExceedsDaysRange]);

  return (
    <>
      <Col sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} className="dates" style={{ marginBottom: '-10px' }}>
        <DatePickerComponent
          id="startDate"
          label="Start date"
          styleType={styleType}
          onChange={setStartDate}
          value={startDate}
          minDate={minDate}
          maxDate={endDate || maxDate}
          isInvalid={exceedsDaysRange}
          feedbackMessage={feedbackMessage}
        />
      </Col>
      <Col sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} className="dates" style={{ marginBottom: '-10px' }}>
        <DatePickerComponent
          id="endDate"
          label="End date"
          styleType={styleType}
          onChange={setEndDate}
          value={endDate}
          minDate={startDate}
          maxDate={maxDate}
          isInvalid={exceedsDaysRange}
        />
      </Col>
    </>
  );
}
