export enum CustomChannelMessageStatus {
  NOT_PROCESSED = 'NOT_PROCESSED',
  FAILED_GET_MESSAGE_TEMPLATE = 'FAILED_GET_MESSAGE_TEMPLATE',
  FAILED_REPLACE_VARIABLES_TEMPLATE = 'FAILED_REPLACE_VARIABLES_TEMPLATE',
  MESSAGE_SENT = 'MESSAGE_SENT',
  SENDING_SMS = 'SENDING_SMS',
  SMS_NOT_SENT = 'SMS_NOT_SENT',
  SMS_SENT = 'SMS_SENT',
  UNEXPECTED_RESULT = 'UNEXPECTED_RESULT',
  FAILED_LAST_RETRY = 'FAILED_LAST_RETRY',
  WARN = 'WARN',
}

export enum CustomChannelChannel {
  KAKAOTALK = 'KakaoTalk',
}

export enum CustomChannelMessageType {
  KAKAOTALK = 'Kakaotalk',
  SMS = 'SMS',
}
